export {};

export class HeroDeckUtil {

    private deckId: number;
    private title: string;
    private version_id: string;
  
    constructor(
      deckId: number = 2653390031, 
      title: string = "Spotify Global 200 - Today", 
      version_id: string = "v1"
    ) 
    {
      this.deckId = deckId;
      this.title = title;
      this.version_id = version_id;
    }
  
    public getDeckId(): number {
      return this.deckId;
    }
  
    public setDeckId(deckId: number): void {
      this.deckId = deckId;
    }
  
    public getTitle(): string {
      return this.title;
    }
  
    public setTitle(title: string): void {
      this.title = title;
    }
  
    public getVersionId(): string {
      return this.version_id;
    }
  
    public setVersionId(version_id: string): void {
      this.version_id = version_id;
    }
  }
  