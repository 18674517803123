import { Amplify, Analytics, AWSKinesisProvider } from "aws-amplify";
import awsconfig from "./aws-exports";
import { setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { AuthProvider } from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import Router from "./navigation/Router";
import { SpotifyProvider } from "./context/SpotifyContext";

import './App.css'

Amplify.configure(awsconfig);

//track which page/url in your webapp is the most frequently viewed 
Analytics.autoTrack('pageView', {
    enable: true,
    type: 'SPA'
});

//enable event tracking
Analytics.autoTrack('event', {
    enable: true
});

//Configure automated session tracking
Analytics.autoTrack('session', {
    // REQUIRED, turn on/off the auto tracking
    enable: true
});

Analytics.addPluggable(new AWSKinesisProvider(awsconfig));

// Configure the plugin after adding it to the Analytics module
Analytics.configure({
    AWSKinesisFirehose: {
        // OPTIONAL -  Amazon Kinesis Firehose service region
        region: 'us-east-1',

        // OPTIONAL - The buffer size for events in number of items.
        bufferSize: 1000,

        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        flushSize: 100,

        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
        flushInterval: 5000, // 5s

        // OPTIONAL - The limit for failed recording retries.
        resendLimit: 5
    }
});

setupIonicReact();

Analytics.addPluggable(new AWSKinesisProvider(awsconfig));

const queryClient = new QueryClient();

const App: React.FC = () => (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <SpotifyProvider>
                    <div className="app-root scroll-content">
                        <Router />
                    </div>
                </SpotifyProvider>
            </AuthProvider>
        </QueryClientProvider>
);


export default App;
