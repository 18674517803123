import {
  IonButton,
  IonContent,
  IonIcon,
  IonModal,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Share } from "@capacitor/share";
import {ENV} from '../../../env/env';
import { addFavoriteDeckApi } from "../../../services/deck_loader";
import "./styles.css";

interface ModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const CurrentPlayingDeck: React.FC<ModalProps> = ({ isOpen, onDismiss }) => {
  interface ListItemProps {
    title: string;
    subTitle: string;
  }

  const context = useContext(AuthContext);

  const ListItem = ({ title, subTitle }: ListItemProps) => (
    <div
      onClick={async () => {
        if (title === "Add to Favorites") {
          try {
            let userId = context?.userId ? parseInt(context?.userId) : 0
            const savedDeck = await addFavoriteDeckApi(
              userId,
              context?.idToSearch!
            );
            console.log({ savedDeck });
          } catch (err) {
            console.log(err);
          } finally {
            onDismiss();
          }
        } else {
          try {
            await Share.share({
              url: `${ENV.REACT_APP_URL}/dashboard/home/?deckId=${context?.idToSearch}`,
            });
          } catch (err) {
            throw err;
          } finally {
            onDismiss();
          }
        }
      }}
      className="list-item-container"
    >
      <div className="empty-box" />
      <div className="text-container">
        <IonText color={"light"}>
          <h4 style={{ margin: "0px", padding: "0px", fontSize: "18px" }}>
            {title}
          </h4>
        </IonText>
        <IonText color={"light"}>
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: "12px",
              color: "#94A1B2",
            }}
          >
            {subTitle}
          </p>
        </IonText>
      </div>
    </div>
  );

  return (
    <IonModal isOpen={isOpen}>
      <IonContent>
        <div className="modal-container">
          <IonText
            style={{ marginBottom: "50px" }}
            color={"light"}
            className="ion-font-small"
          >
            Current Playing Deck
          </IonText>
          <ListItem title="Share Deck" subTitle="sub heading" />
          <ListItem title="Add to Favorites" subTitle="sub heading" />
        </div>
        <IonButton
          color={"#fff"}
          className="ion-activatable ion-activatable icon"
          fill="clear"
          id="current-playing-modal"
          expand="block"
          onClick={onDismiss}
        >
          <IonIcon
            slot="start"
            icon={"assets/icon/modalCross.svg"}
            style={{ width: "100%", height: "100%" }}
          ></IonIcon>
          <IonRippleEffect></IonRippleEffect>
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default CurrentPlayingDeck;
