import { IonApp, IonRouterOutlet, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import { AuthContext } from "../context/AuthContext";
import DeckLook from "../pages/OnBoarding/DeckLook";
import HappyListening from "../pages/OnBoarding/HappyListening";
import SwipeLeft from "../pages/OnBoarding/SwipeLeft";
import SwipeRight from "../pages/OnBoarding/SwipeRight";
import SwipeUp from "../pages/OnBoarding/SwipeUp";
import TabRoutes from "./TabRoutes";
import AppUrlListener from '../pages/AppUrlListener';
import SpotifyLogin from "../pages/spotify-login";
import Settings from "../pages/Settings/Settings"
import SpotifyFC from "../pages/Settings/SpotifyFC";
import AboutFC from "../pages/Settings/About";
import SettingsPage from "../pages/Settings/Settings";
import HeroDeck from "../pages/HeroDeck/HeroDeck";
import SpotifyPlaylistSelectionPage from "../pages/LikesShares/SpotifyPlaylistSelectionPage";
import OnboardingPage from "../components/FirstTimeExp/OnboardingPage";

const Router: React.FC = () => {
  const context = useContext(AuthContext);

  if (!context?.isMounted) {
    return <IonLoading isOpen={true} />;
  }

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        <IonRouterOutlet>
          <Route path="/onboarding" component={OnboardingPage} />
          <Route path="/swipe-left" component={SwipeLeft} />
          <Route path="/swipe-up" component={SwipeUp} />
          <Route path="/swipe-right" component={SwipeRight} />
          <Route path="/deck-look" component={DeckLook} />
          <Route path="/happy-listening" component={HappyListening} />
          <Route path="/dashboard" component={TabRoutes} />
          <Route path="/spotify-login" component={SpotifyLogin} />
          <Route path="/settings" component={Settings} />
          <Route path="/spotify-settings" component={SpotifyFC} />
          <Route path="/about" component={AboutFC} />

          <Route path="/add-to-spotify/" component={SpotifyPlaylistSelectionPage} />



          <Route path="/song/" component={AboutFC} />
          <Route path="/dashboard/deck/:id" component={TabRoutes} />

          

          <Route
            path="/"
            render={() => (
              <Redirect
                to={context?.isOnboardingCompleted ? "/dashboard/home" : "/onboarding"}
              />
            )}
            exact
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default Router;
