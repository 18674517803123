import { Preferences } from "@capacitor/preferences";
import AWS from 'aws-sdk';

export interface LikedDeck {
  deckId: number;
  title: string;
  version_id: string;
}

export interface LikedCard {
  id: number;
  title: string;
  spotifyid: string;
  snippeturl: string;
  coverart_a: string;
  albumid: string;
  albumname: string;
  sartists: string;
  jsonartists: string;
}

interface S3Deck {
  deck_id: number;
  version_id: string;
  title: string;
  cards: {
      items: {
          card: LikedCard;
      }[];
  };
}

/** INTERFACES TO FETCH S3 CONTENT FOR LIKED DECKS */

export interface Card {
  id: number;
  title: string;
  sartists: string;
  coverart_a: string;
  snippeturl: string;
  albumname: string;
  albumid: string;
}

export interface Deck {
  deck_id: number;
  version_id: string;
  title: string;
  cards: Card[];
}

interface CardData {
  id: number;
  title: string;
  spotifyid: string;
  trackurl: string;
  snippeturl: string;
  coverart_a: string;
  albumname: string;
  albumid: string;
  jsonartists: string;
  sartists: string;
}

interface CardItem {
  card: Card;
}

interface Cards {
  items: CardItem[];
}

interface Playlist {
  deck_id: number;
  version_id: string;
  title: string;
  cards: Cards;
}

/** END LIKED DECK INTERFACES */

// configure AWS SDK with access keys and secrets
AWS.config.update({
  accessKeyId: 'AKIA3F4YOIDOH532NDY3',
  secretAccessKey: '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',
  region: 'us-east-1'
});


class LikedStorage {
  private readonly INFO_BLOCK_KEY = 'info_block';
  private readonly CARDS_KEY = 'cards';

  private s3Bucket = 'audion-glimpz';
  private s3Folder = 'user-decks'

  private static instance: LikedStorage;

  private likedDeck: LikedDeck;
  private cards: LikedCard[];

  constructor() {
    this.likedDeck = { deckId: 0, title: '', version_id: "1.0" };
    this.cards = [];
  }

  public static getInstance(): LikedStorage {
    if (!LikedStorage.instance) {
      LikedStorage.instance = new LikedStorage();
    }
    return LikedStorage.instance;
  }

  private async load(): Promise<void> {
    //const infoBlock = await Storage.get({ key: this.INFO_BLOCK_KEY });
    const likdDeck = await Preferences.get({key: this.INFO_BLOCK_KEY});
    if (likdDeck.value) {
      this.likedDeck = JSON.parse(likdDeck.value);
    }

    const cards = await Preferences.get({key: this.CARDS_KEY});
    if (cards.value) {
      this.cards = JSON.parse(cards.value);
    }
  }

  public async updatelkDeck(lkDeck: LikedDeck): Promise<void> {
    //console.log("sk: liked_storage:updatelkdeck: ", lkDeck.deckId, lkDeck.title);
    this.likedDeck = lkDeck;
    this.cards = []; //empty cards array
    await Preferences.set({ key: this.INFO_BLOCK_KEY, value: JSON.stringify(lkDeck) });
    await Preferences.set({ key: this.CARDS_KEY, value: JSON.stringify(this.cards) });
  }

  public async addCard(card: LikedCard): Promise<void> {

    if (!this.cards.some(c => c.id === card.id)) {
      this.cards.push(card);
    }

    //console.log("sk: liked_storage:addCard: ", card.title, this.cards.length);
    await Preferences.set({ key: this.CARDS_KEY, value: JSON.stringify(this.cards) });
  }

  public getCards(): LikedCard[] {
    return this.cards;
  }

  public getLikedDeck(): LikedDeck {
    return this.likedDeck;
  }

  public resetCards(): void {
    this.cards = [];
  }

  public getDeckJsonFromMemory(): Deck | undefined {

    if( this.cards.length == 0 )
      return undefined;

    const myCards = [];
    for (const likedCard of this.cards) {
      const card = {
        id: likedCard.id,
        title: likedCard.title,
        sartists: likedCard.sartists,
        coverart_a: likedCard.coverart_a,
        snippeturl: likedCard.snippeturl,
        albumname: likedCard.albumname,
        albumid: likedCard.albumid
      };
      myCards.push(card);
    }

    const inMemorydeck:Deck = {
      deck_id: this.likedDeck.deckId,
      version_id: this.likedDeck.version_id,
      title: this.likedDeck.title,
      cards: myCards
    };

    return inMemorydeck;
  }

  public async getDeckFromPlaylist(user_id:string): Promise<Deck[]> {

    const s3Folder = `user-decks/${user_id}/`;
    try {
      const s3 = new AWS.S3();
      const list = await s3.listObjects({ Bucket: 'audion-glimpz', Prefix: s3Folder }).promise();
      const deckPromises = list.Contents?.map(async (item: any) => {
        try {
          if (item.Key?.endsWith(".json")) {
            const response = await s3.getObject({ Bucket: 'audion-glimpz', Key: item.Key }).promise();
            const strResp = response.Body ? response.Body.toString() : "";
            const playlist: Playlist = JSON.parse(strResp);
            const cards = playlist.cards.items.map((item) => {
              const card = item.card;
              return {
                id: card.id,
                title: card.title,
                sartists: card.sartists,
                coverart_a: card.coverart_a,
                snippeturl: card.snippeturl,
                albumname: card.albumname,
                albumid: card.albumid,
              };
            });
            return {
              deck_id: playlist.deck_id,
              version_id: playlist.version_id,
              title: playlist.title,
              cards: cards,
            };
          }
        } catch (error) {
          console.error(error);
        }
      });
      const decks = await Promise.all(deckPromises || []);
      return decks.filter(Boolean) as Deck[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }


  async saveToS3(user_id:string) {
    const s3 = new AWS.S3();
    const filename = this.likedDeck.deckId.toString();
    
    //console.log("saving deck to s3", this.likedDeck.deckId)
    // Create the JSON structure
    const s3deck = {
      deck_id: this.likedDeck.deckId,
      version_id: this.likedDeck.version_id,
      title: this.likedDeck.title,
      cards: {
        items: this.cards.map(card => {
          return {
            card: {
              id: card.id,
              title: card.title,
              spotifyid: card.spotifyid,
              snippeturl: card.snippeturl,
              coverart_a: card.coverart_a,
              albumid: card.albumid,
              albumname: card.albumname,
              jsonartists: card.jsonartists,
              sartists: card.sartists
            }
          };
        })
      }
    };
    
    const jsonString = JSON.stringify(s3deck);
    console.log(jsonString);
    
    // Upload the JSON file to S3
    const params = {
      Bucket: this.s3Bucket,
      Key: `${this.s3Folder}/${user_id}/${filename}.json`,
      Body: jsonString,
    };
    const res = await s3.upload(params).promise();
    //console.log("s3 uploaded:", res)
    return true;
  }

  async deleteLikedDeckfromS3(user_id:string, deck_id:number, version_id:string) {
    const AWS = require('aws-sdk');
    const s3 = new AWS.S3();
    const filename = deck_id.toString();

    const params = {
      Bucket: this.s3Bucket,
      Key: `${this.s3Folder}/${user_id}/${filename}.json`,
    };

    s3.deleteObject(params, function(err:any, data:any) {
      if (err) {
        console.log("Error deleting object from S3:", err);
        return false;
      } else {
        return true;
      }
});
  }
 
}

export default LikedStorage;
