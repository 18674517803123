import React from 'react';
import './OverlayScreen.css';
import { IonIcon } from '@ionic/react';
import { volumeMuteOutline } from 'ionicons/icons';

interface OverlayScreenProps {
  onClick: () => void;
}

const OverlayScreen: React.FC<OverlayScreenProps> = ({ onClick }) => {
  return (
    <div className="overlay" onClick={onClick}>
      <div className="overlay-content">
        <IonIcon icon={volumeMuteOutline} className="mute-icon" />
        <span className="overlay-text">Tap Anywhere to Play</span>
      </div>
    </div>
  );
};

export default OverlayScreen;

