import { IonBackButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { personCircleOutline, chevronForwardOutline,arrowBackOutline } from 'ionicons/icons';
import "./Settings.css"

import { SpotifyContext } from "../../context/SpotifyContext";
import { GetResult } from "@capacitor/preferences";
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

const CLIENT_ID = "52d85ebbf0fa4515aade03d83e67ce56"
const REDIRECT_URI = "https://glimpz-it.com/settings"
// const REDIRECT_URI = "http://localhost:3000/settings"
const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
const RESPONSE_TYPE = "token"
const SCOPES = "playlist-read-private,playlist-read-collaborative,user-follow-read,user-library-modify,playlist-modify-private"

function useHash(param: string) {
    return useMemo(() => {
        const hash = window.location.hash;
        const fragments: Array<String> = hash.substring(1).split("&") ?? [];
        const paramFragment: String = fragments.find(elem => elem.startsWith(param)) ?? "";
        const paramValues = paramFragment.split("=");
        if (paramValues.length == 2) {
            return paramValues[1];
        }
        return null;
    }, [param]);
}

const SettingsPage: React.FC = () => {

    const spotifyContext = useContext(SpotifyContext);
    const history = useHistory();

    //check if access token is in the request - this happens when the user signs into Spotify
    //and is redirected to the same page
    let hashToken: any = useHash("access_token") ?? null;

    /* Add this to Spotify Context, which in turn adds it to preferences */
    if (hashToken != null) {
        spotifyContext?.setAccessToken(hashToken);
    }

    const [spotifyToken, setSpotifyToken] = useState(hashToken);
    const [userProfile, setUserProfile] = useState<{
        display_name?: string;
        email?: string;
        imageUrl?: string;
    }>({});

    useEffect(() => {

        const fetchUserProfile = async () => {

            if (spotifyToken == null) //if the access token is not in URL, find it in preferences
            {
                const respT = await spotifyContext?.getAccessToken();
                hashToken = respT?.value;
                setSpotifyToken(hashToken);
                spotifyContext?.setAccessToken(hashToken);
                console.log("access2 duvi- ", respT?.value);

            }
            if (hashToken != null) {
                try {
                    const response = await spotifyContext?.getUserProfile();
                    const imageUrl = response?.images?.[0]?.url;
                    setUserProfile({
                        display_name: response?.display_name,
                        email: response?.email,
                        imageUrl: imageUrl,
                    });
                    console.log("In user profile is good");
                } catch (error) {
                    console.log(`Error fetching user profile: ${error}`);
                    setUserProfile({
                        display_name: undefined,
                        email: undefined,
                        imageUrl: undefined,
                    });
                    console.log("user prof is null 1 ");
                    spotifyContext?.setAccessToken(null);
                    setSpotifyToken(null);
                }
            }
            else {
                setUserProfile({
                    display_name: undefined,
                    email: undefined,
                    imageUrl: undefined,
                });
                console.log("user prof is null 2 ");
                spotifyContext?.setAccessToken(null);
            }
        };

        fetchUserProfile();

    }, []);

    const handleSpotifyClick = () => {
        history.push('/spotify-settings');
    };

    const handleAboutClick = () => {
        history.push('/about');
    };

    return (
        <IonPage className='page-container'>
            <IonHeader>
                <IonToolbar>
                <div className='header-wrapper'>
                    <div className='back-button' onClick={() => history.goBack()}>
                    <IonIcon style={{color:"white"}} icon={arrowBackOutline} />
                    </div>
                    <div className='spotify-settings'>Settings</div>
                    <div className='back-button'>
                    <IonIcon style={{color:"black"}} icon={arrowBackOutline} />
                    </div>
                </div>
                </IonToolbar>
            </IonHeader>
            <IonContent  >
                <IonList lines="none" >


                    {/*<IonItem className="settings-item">
                        {userProfile?.imageUrl ? (
                            <div className="profile-image" slot="start">
                                <img src={userProfile.imageUrl} alt="User profile image" />
                            </div>
                        ) :
                            <IonIcon icon={personCircleOutline} slot="start" className="settings-item" />
                        }
                        <IonGrid>
                            <IonRow>
                                {userProfile?.display_name ? (
                                    <IonLabel style={{ color: "#fff" }} >{userProfile.display_name} </IonLabel>
                                ) :
                                    <IonLabel style={{ color: "#fff" }} >Guest (anonymous) </IonLabel>
                                }
                            </IonRow>
                            <IonRow>
                                <IonLabel style={{ color: "#fff" }} className="smaller-label">view profile</IonLabel>
                            </IonRow>
                        </IonGrid>
                        <IonIcon icon={chevronForwardOutline} slot="end" style={{ color: "#fff" }} />
                    </IonItem>*/}

                    {/*{spotifyToken ?
                        (
                            <IonItem className="settings-item" onClick={handleSpotifyClick}>

                                <IonGrid>
                                    <IonRow>
                                        <IonLabel style={{ color: '#fff' }}>Spotify</IonLabel>
                                    </IonRow>
                                    <IonRow>
                                        <IonLabel style={{ color: '#fff' }} className="smaller-label">
                                            manage playlist, sign out
                                        </IonLabel>
                                    </IonRow>
                                </IonGrid>
                                <IonIcon icon={chevronForwardOutline} slot="end" style={{ color: '#fff' }} />
                            </IonItem>
                        ) : (
                            <IonItem className="settings-item">
                                <a href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}`}>
                                    <IonGrid>
                                        <IonRow>
                                            <IonLabel style={{ color: "#fff" }} >Spotify Connect</IonLabel>
                                        </IonRow>
                                        <IonRow>
                                            <div className="div-small-label">
                                                Enrich your Spotify playlist with songs discovered at Glimpz
                                            </div>
                                        </IonRow>
                                    </IonGrid>
                                </a>
                                <IonIcon icon={chevronForwardOutline} slot="end" />
                            </IonItem>
                        )};*/}


                    {/* <IonItem className="settings-item">
            <IonLabel >Playback</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>

          <IonItem className="settings-item">
            <IonLabel>Notifications</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem> */}

                    <IonItem className="settings-item" onClick={handleAboutClick}>
                        <IonLabel style={{fontSize:"1.2em"}}>About</IonLabel>
                        <IonIcon style={{color:"white"}} icon={chevronForwardOutline} slot="end" />
                    </IonItem>


                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SettingsPage;
