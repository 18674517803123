import React from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import "./Settings.css";
import { arrowBackOutline } from 'ionicons/icons';

const AboutFC: React.FC = () => {
  const router = useIonRouter();
  return (
    <IonPage className='page-container'>
      <IonHeader>
        <IonToolbar>
        <div className='header-wrapper'>
            <div className='back-button' onClick={() => router.goBack()}>
            <IonIcon style={{color:"white"}} icon={arrowBackOutline} />
            </div>
            <div className='spotify-settings'>About Glimpz</div>
            <div className='back-button'>
            <IonIcon style={{color:"black"}} icon={arrowBackOutline} />
            </div>
        </div>
        </IonToolbar>
    </IonHeader>
      <IonContent>
        <IonGrid style={{ paddingTop: '2em' }}>
          <IonRow>
            <IonLabel style={{ color: "#fff", fontSize: '1.2rem', textAlign: 'left',padding:"0 1em" }} className="medium-label">
              We want to re-imagine the way music is discovered and shared. With millions of tracks accessible across digital catalogs, it is impossible to search and find music you like.
            </IonLabel>
          </IonRow>
          <IonRow>
            <IonLabel style={{ color: "#fff", fontSize: '0.9rem', textAlign:'center'}}className="medium-label">
              version 0.1.0
            </IonLabel>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AboutFC;
