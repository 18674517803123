import {
    IonPage,
    IonContent,
    IonButton,
    useIonRouter,
  } from "@ionic/react";
  import {
    useLocation
  } from "react-router-dom";
  import React, { useContext, useEffect, useMemo, useState } from "react";
  
import { SpotifyContext } from "../context/SpotifyContext";
import { GetResult } from "@capacitor/preferences";

const CLIENT_ID = "4fd208916bde42558e1bc26c962b8404"
const REDIRECT_URI = "http://glimpz-it.com/spotify-login"
const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
const RESPONSE_TYPE = "token"
const SCOPES = "playlist-read-private,playlist-read-collaborative,user-follow-read,user-library-modify,playlist-modify-private"

function useHash(param:string){
    return useMemo(() => {
        const hash = window.location.hash;
        const fragments:Array<String> = hash.substring(1).split("&") ?? [];
        const paramFragment: String = fragments.find(elem => elem.startsWith(param)) ?? "";
        const paramValues = paramFragment.split("=");
        if (paramValues.length == 2){
            return paramValues[1];
        }
        return null;
    }, [param]);
}

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const SpotifyLogin: React.FC = () => {
    const spotifyContext = useContext(SpotifyContext);

    const [userProfile, setUserProfile] = useState<{
        display_name?: string;
        email?: string;
        imageUrl?: string;
      }>({});

      
    const router = useIonRouter();
    let query = useQuery();
    const [profile, setProfile] = useState("First");
    const [ist, setIst] = useState("First");
        
    const accessToken = useHash("access_token") ?? null;
    const returnRoute = query.get("returnRoute") ? query.get("returnRoute") : null;
    let spProfile:any = null;
    
    useEffect( () => {
        
        const fetchUserProfile = async () => {
            try {
              const response = await spotifyContext?.getUserProfile();
              const imageUrl = response?.images?.[0]?.url;
              setUserProfile({
                display_name: response?.display_name,
                email: response?.email,
                imageUrl: imageUrl,
              });
            } catch (error) {
              console.error(`Error fetching user profile: ${error}`);
            }
          };
      
          fetchUserProfile();

        if (accessToken) {
                spotifyContext?.setAccessToken(accessToken);
                
                fetchUserProfile();
        }

    }, [accessToken]);

    const setReturnRoute = () => {
    }

    return (
        <IonPage>
            <IonContent style={{padding:3}}>
                <div className="App">
                    <header className="App-header">
                        <h1>Spotify React</h1>
                        <a href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}`}>
                            <IonButton onClick={setReturnRoute}>Login to Spotify</IonButton>
                        </a>
                        
                        <div style={{color:"#fff"}}> 
                        <h1>User Profile</h1>
                            {userProfile.display_name && (
                            <p>Display Name: {userProfile.display_name}</p>
                            )}
                            {userProfile.email && <p>Email: {userProfile.email}</p>}
                            {userProfile.imageUrl && (
                            <img src={userProfile.imageUrl} alt="Profile" width="200" />
                            )}
                        </div>
                    </header>
                </div>
            </IonContent>
        </IonPage>
    )
  }


export default SpotifyLogin;