import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonImg,
    IonRange,
    IonGrid,
    IonRow,
    IonCol
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Gesture,
    GestureConfig,
    createGesture,
    createAnimation,
    RangeCustomEvent,
} from "@ionic/core";
import anime from 'animejs';
import "./styles.css";
import Song from "../Song/Song";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import CardControls from "../CardControls/CardControls";
import { AuthContext } from "../../context/AuthContext";
import { SpotifyContext } from "../../context/SpotifyContext";
import { AppAnalytics } from "../../utility/app-analytics";
import spotifyLogo from "../../assets/spotify-icons-logos/icons/02_PNG/Spotify_Icon_RGB_White.png";
// Schema of the provided data
export interface ICard {
    imageUrl: string;
    songUrl?: string;
    songName: string;
    songArtist: string;
    albumname: string;
    album_id: string;
    id: number;
    sp_id: string;
    index: number;
    deckSongs?: any;
    inView?: boolean;
    handleDeleteLikedSongs?: (songName: string, gestureType: string) => void;
    isLikedSongs?: boolean;
    enableAutoPlay?: boolean;
    deckId: number;
    animationVariance?: number;
    deleteCardFromDeck?: (
        songName: string,
        cardId: number,
        callApi: boolean,
        gestureType: string,
        deckId: number
    ) => void;
    currentCard?: number;
}

const Card: React.FC<ICard> = ({
    id,
    sp_id,
    albumname,
    album_id,
    index,
    inView,
    songUrl,
    songName,
    imageUrl,
    deckSongs,
    songArtist,
    isLikedSongs,
    deckId,
    enableAutoPlay,
    handleDeleteLikedSongs,
    animationVariance,
    deleteCardFromDeck,
    currentCard,
}) => {
    const ionRangeRef = useRef<HTMLIonRangeElement>(null);

    const cardElement = useRef<HTMLIonCardElement>(null);
    const context = useContext(AuthContext);
    const spContext = useContext(SpotifyContext)
    const [isAnimEnd, setIsAnimEnd] = useState<any>(null);
    const [stackCardsCount, setStackCardsCount] = useState(0);
    const [callApi, setCallApi] = useState(true);

    const [knobMovementAllowed, setKnobMovementAllowed] = useState<boolean>(true);
    const [gestureStore, setGestureStore] = useState<Gesture>();
    const location = useLocation();

    const { curTime, duration, playing, setPlaying, setClickedTime } =
        useAudioPlayer();

    const stackHeight = 310;
    let ratio = (animationVariance ?? stackHeight) / stackHeight;
    ratio = ratio > .5 ? ratio : .5;

    const deleteAnimation = createAnimation()
        .addElement(cardElement.current!)
        .duration(1000)
        .fromTo(
            "transform",
            "translateX(0px) rotate(0)",
            `translateX(-${window.screen.width}px) rotate(-30deg)`
        )
        .fromTo("opacity", "1", "0.6")

    const initGesture = async () => {
        const screenWidth = window.screen.width;
        if (cardElement.current === null) {
            return;
        }

        //cardElement.current!.style.top = `${index * 20}px`;

        const options: GestureConfig = {
            el: cardElement.current,
            gesturePriority: 2,
            gestureName: "music-swipe",
            onStart: () => {
                cardElement.current!.style.transition = "";
            },
            onMove: (detail) => {
                cardElement.current!.style.transform = `translate(${detail.deltaX
                    }px, 0) rotate(${detail.deltaX / 20}deg)`;
            },
            onEnd: (detail) => {
                cardElement.current!.style.transition = "0.3s ease-out";

                if (detail.deltaX > screenWidth / 8) {
                    cardElement.current!.style.transform = `translateX(${screenWidth * 4
                        }px)`;
                    setIsAnimEnd({ [songName]: true, rightSwipe: true });
                } else if (detail.deltaX < -screenWidth / 8) {
                    cardElement.current!.style.transform = `translateX(-${screenWidth * 4
                        }px)`;
                    setIsAnimEnd({ [songName]: true, leftSwipe: true });
                } else {
                    cardElement.current!.style.transform = "";
                }

            },
        };

        const gesture: Gesture = createGesture(options);
        gesture.enable();
        setGestureStore(gesture);
    };

    useEffect(() => {
        initGesture();
    }, [cardElement]);

    useEffect(() => {
        if (isAnimEnd?.[songName] && !isLikedSongs) {
            reloadAnimation();

            if (isAnimEnd?.rightSwipe) {
                spContext?.addToSavedTracks([sp_id])
            }
        }

        if (isAnimEnd?.[songName] && isLikedSongs) {
            handleDeleteLikedSongs?.(
                songName,
                isAnimEnd?.rightSwipe ? "right" : "left"
            );
        }
    }, [isAnimEnd]);

    useEffect(() => {
        setCallApi(true);
        if (currentCard && currentCard > 0) {
            setPlaying(true);
        }

        if (
            (location.pathname.includes("search") ||
                location.pathname.includes("likedSongs") ||
                location.pathname.includes("favorited-decks")) &&
            context?.isAutoPlayEnabled
        ) {
            setPlaying(true);
        }
    }, [location.pathname, currentCard, context?.isAutoPlayEnabled]);

    useEffect(() => {
        if (knobMovementAllowed) {
            const range = ionRangeRef.current;
            if (range) {
                range.value = (curTime / duration * 100);
            }
        }
    }, [curTime]);

    const rangeKnobMoveStart = () => {
        setKnobMovementAllowed(false);
        gestureStore?.enable(false);
    }

    const rangeKnobMoveEnd = ({ detail }: RangeCustomEvent) => {
        setKnobMovementAllowed(true);
        gestureStore?.enable(true);
        const positionPercentage = duration * (detail.value as number) / 100;
        setClickedTime(positionPercentage);
    }

    const reloadAnimation = () => {
        anime({
            targets: document.getElementsByClassName("card"),
            width: function (el: HTMLElement, i: number) {
                let cardWidth = getComputedStyle(el).width;
                return [cardWidth, `${parseInt(cardWidth) + 20}px`];
            },
            top: function (el: HTMLElement, i: number) {
                return (i) * 12;
            },
            left: function (el: HTMLElement, i: number) {
                return `${((2 - i) * 10) - 10}px`
            },
            delay: anime.stagger(100, { start: 100 }), // delay starts at 500ms then increase by 100ms for each elements.
            complete: function (anim) {
                if (deleteCardFromDeck) deleteCardFromDeck(deckSongs?.songName, id, false, "left", deckId)
            }
        });
    };

    const handleSongPlayEnded = () => {
        deleteAnimation.play();
        reloadAnimation();
    }

    const handleDeleteCard = () => {
        deleteAnimation.play();
        setIsAnimEnd({ [songName]: true });
        setStackCardsCount(stackCardsCount + 1);
        let userId = context?.userId ? parseInt(context?.userId) : 0
        let appAnalytics = new AppAnalytics();
        appAnalytics.card_swipe_left_event(deckId !== undefined ? deckId : 0,
            id, userId.toString(), stackCardsCount.toString());
    }

    return (
        <IonCard
            ref={cardElement}
            style={{
                boxShadow: index <= 0 ? "0px -5px 20px black, 0px -5px 50px black" : null,
                width: `calc(100% - ${(3 - index) * 20}px)`,
                left: `${((3 - index) * 10) - 10}px`,
                top: index == 0 ? 0 : `${(index - 1) * 12}px`,
                position: "absolute",
                paddingBottom: "5em"
            }}
            className="card"
        >
            {deckSongs && <audio
                onEnded={handleSongPlayEnded}
                id={"audio" + id}
                src={deckSongs?.url}
                autoPlay
            >
                Your device/browser does not support the <code>audio</code> element.
            </audio>}
            {!inView && (
                <IonCardHeader>
                    <div
                        style={{
                            height: 90 * (ratio),
                            transition: "height 0.25s linear; left 0.25s linear",
                        }}
                    >
                        {/* Grid for spotify logo and track image */}
                        <IonImg
                            src={imageUrl}
                            alt={songName}
                            draggable={false}
                            style={{ width: "unset !important", height: "100%", justifyContent: ratio < .99 ? "left" : "center", display: "flex", transition: "height 1s linear", }}
                        />
                        <IonImg
                            src={spotifyLogo}
                            alt="SpotifyLogo"
                            draggable={false}
                            style={{ width: "21px", height: "21px", position: "absolute", top: 16, backgroundColor: "black", borderRadius: 10 }}
                        />
                    </div>
                </IonCardHeader>
            )}
            <IonCardContent className={inView ? "" : "ion-text-center"} style={{ padding: 20, paddingTop: inView ? 20 : 0 }}>
                <Song
                
                    inView={inView}
                    songName={songName}
                    song_id={sp_id}
                    album_id={album_id}
                    songArtist={songArtist}
                    albumname={albumname}
                    handlePlaying={(playing: any) => setPlaying(playing)}
                    isPlaying={playing}
                    imageUrl={imageUrl}
                />
                {inView && (
                    <IonRange
                        onIonKnobMoveStart={rangeKnobMoveStart}
                        onIonKnobMoveEnd={rangeKnobMoveEnd}
                        className="bar__progress mini_bar"
                        ref={ionRangeRef}
                    />
                )}
                {!inView && (
                    <>
                        <IonRange
                            onIonKnobMoveStart={rangeKnobMoveStart}
                            onIonKnobMoveEnd={rangeKnobMoveEnd}
                            className="bar__progress"
                            ref={ionRangeRef}
                        />

                        <CardControls
                            data={{ songName, imageUrl, id, deckId, sp_id }}
                            isPlaying={playing}
                            setPlaying={(play) => setPlaying(play)}
                            handleDeleteCard={handleDeleteCard}
                            handleMinusSeconds={() => setClickedTime(curTime - 10)}
                            audioId={"audio" + id}
                        />
                    </>
                )}
            </IonCardContent>
        </IonCard>
    );
};

export default Card;
