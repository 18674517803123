import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonModal,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import React, { useContext } from "react";
import { Share } from "@capacitor/share";
import { addLikedSongApi } from "../../../services/deck_loader";
import "../CurrentPlayingDeck/styles.css";
import { AuthContext } from "../../../context/AuthContext";

interface ModalProps {
  data: {
    songName: string;
    imageUrl: string;
    id: number;
  };
  isOpen: boolean;
  onDismiss: () => void;
  handleSetLiked: () => void;
}

const AddSongToDeck: React.FC<ModalProps> = ({
  isOpen,
  onDismiss,
  data,
  handleSetLiked,
}) => {
  interface ListItemProps {
    title: string;
    subTitle: string;
  }

  const context = useContext(AuthContext);

  const ListItem = ({ title, subTitle }: ListItemProps) => (
    <div
      onClick={async () => {
        if (title !== "Share Deck") {
          try {
            let userId = context?.userId ? parseInt(context?.userId) : 0;
            const savedDeck = await addLikedSongApi(userId, data.id);
            console.log({ savedDeck });
            handleSetLiked?.();
          } catch (err) {
            console.log(err);
          } finally {
            onDismiss();
          }
        } else {
          try {
            await Share.share({
              url: `http://localhost:8100/dashboard/home/?songId=${context?.idToSearch}`,
            });
          } catch (err) {
            throw err;
          } finally {
            onDismiss();
          }
        }
      }}
      className="list-item-container"
    >
      <div className="empty-box" />
      <div className="text-container">
        <IonText color={"light"}>
          <h4 style={{ margin: "0px", padding: "0px", fontSize: "18px" }}>
            {title}
          </h4>
        </IonText>
        <IonText color={"light"}>
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: "12px",
              color: "#94A1B2",
            }}
          >
            {subTitle}
          </p>
        </IonText>
      </div>
    </div>
  );

  return (
    <IonModal isOpen={isOpen}>
      <IonContent>
        <div className="modal-container">
          <IonText
            color={"light"}
            className="ion-font-small"
            style={{ marginBottom: "5px" }}
          >
            Add Song to Deck
          </IonText>
          <IonImg
            src={data?.imageUrl}
            className="image"
            style={{ width: "100px", height: "100px", marginBottom: "15px" }}
          />
          <IonText
            color={"light"}
            style={{ marginBottom: "30px", fontSize: "16px" }}
          >
            {data?.songName}
          </IonText>
          <ListItem title="Share Deck" subTitle="sub heading" />
          <ListItem title="Add to Favorites" subTitle="sub heading" />
        </div>
        <IonButton
          color={"#fff"}
          className="ion-activatable icon"
          fill="clear"
          onClick={onDismiss}
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IonIcon
            slot="start"
            icon={"assets/icon/modalCross.svg"}
            style={{ width: "30px", height: "30px" }}
          ></IonIcon>
          <IonRippleEffect></IonRippleEffect>
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default AddSongToDeck;
