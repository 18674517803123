import { IonIcon,IonButton, IonContent, IonItemDivider, IonPage, IonText, useIonRouter, IonTabBar } from "@ionic/react";
import React, { useState, useEffect,useContext } from "react";
import "./styles.css";
import GlimpzHeader from "../../../components/Header/GlimpzHeader";
import { useHistory } from 'react-router-dom';
import { timeOutline, starOutline, arrowRedoOutline, arrowDownOutline, heartOutline,arrowUpOutline } from "ionicons/icons";
import { AuthContext } from "../../../context/AuthContext";
import defaultProfile from "./profile-default.svg"; 
import { SpotifyContext } from "../../../context/SpotifyContext";




// List Item component
const ListItem: React.FC<{data: { title: string, subtitle: string, count: number, total: number }}> = ({ data }) => {
  // calculate progress percentage, but not exceeding 100%
  const progressPercentage = Math.min((data.count / data.total) * 100, 100);
  return (
    <div className="profile-list-item-container">
      <div className="list-item-top">
        <div className="list-item-info">
          <div className="title-list">{data.title}</div>
          <div className="subtitle">{data.subtitle}</div>
        </div>
        <div className="counter">{data.count}/{data.total}</div>
      </div>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{width: `${progressPercentage}%`}}></div>
      </div>
    </div>
  );
}

const AnalyticsItem: React.FC<{data: { iconImage: string, title: string, count: number,}}> = ({ data }) => {
  return (
    <div className="analytics-item">
        <div className="title-analytics-container">
        <IonIcon icon={data.iconImage} style={{color:'#7F5AEF',fontSize:'1.5em'}}/><span className="title-analytics">{data.title}</span></div>
        <div className="data">{data.count}</div>
    </div>
  );
}

const Profile: React.FC = () => {
  const router = useIonRouter();
  const [itemsToShow, setItemsToShow] = useState(4);
  const spotifyContext = useContext(SpotifyContext);

  // Access user profile values from context
  const context = useContext(AuthContext);
  const userName = context?.userName;
  const userGender = context?.userGender;
  const userPhone = context?.userPhone;
  const userEmail = context?.userEmail;
  const userProfilePic = context?.userProfilePic;

  const [selectedFile, setSelectedFile] = useState<any>(userProfilePic);
  const [uName, setName] = useState(userName);
  const [gender, setGender] = useState(userGender);
  const [location, setLocation] = useState('');
  const [mobileNo, setMobileNo] = useState(userPhone);
  const [email, setEmail] = useState(userEmail);

  // List data
  /*const listData = [
    { title: "Like 5 playlists", subtitle: "Earn 2 Fan Points", count: context?.numOfLikedDecks||0, total: 5 },
    { title: "Like 20 tunes", subtitle: "Earn a Fan Point", count: context?.numOfLikedSongs||0, total: 20 },
    { title: "Add 30 songs to Spotify", subtitle: "Earn 8 Fan Points", count: context?.numOfSongsAddedToSpotify||0, total: 30 },
    { title: "Share 10 playlists", subtitle: "Earn 3 Fan Points", count: context?.numOfSharedPlaylists||0, total: 10 },
    //{ title: "Like 5 albums", subtitle: "Earn a Fan Point", count: 7, total: 10 },
    //{ title: "Share 20 albums", subtitle: "Earn 10 Fan Points", count: 4, total: 20 },
    //{ title: "Share 5 decks", subtitle: "Earn 5 Fan Points", count: 2, total: 5 },
    //{ title: "Receive 5 suggestions", subtitle: "Earn a Fan Point", count: 0, total: 5 },
    { title: "Create a playlist on Spotify", subtitle: "Earn 2 Fan Point", count: context?.numOfCreatedPlaylists||0, total: 3 },
    //{ title: "Share via WhatsApp", subtitle: "Earn a Fan Point", count: 0, total: 1 },
  ];*/
  const listData = [
    { title: "Connect to Spotify", subtitle: "Earn 5 Fan Points", count: !spotifyContext?.isLoggedOut ? 1 : 0, total: 1, earnedPoints: 5 },
    { title: "Like 5 playlists", subtitle: "Earn 2 Fan Points", count: context?.numOfLikedDecks||0, total: 5, earnedPoints: 2 },
    { title: "Like 20 tunes", subtitle: "Earn a Fan Point", count: context?.numOfLikedSongs||0, total: 20, earnedPoints: 1 },
    { title: "Add 30 songs to Spotify", subtitle: "Earn 8 Fan Points", count: context?.numOfSongsAddedToSpotify||0, total: 30, earnedPoints: 8 },
    { title: "Share 10 playlists", subtitle: "Earn 3 Fan Points", count: context?.numOfSharedPlaylists||0, total: 10, earnedPoints: 3 },
    { title: "Create a playlist on Spotify", subtitle: "Earn 2 Fan Point", count: context?.numOfCreatedPlaylists||0, total: 3, earnedPoints: 2 },
  ];
  const totalFanPoints = listData.reduce((total, item) => {
    const pointsEarnedForItem = item.count === item.total ? item.earnedPoints : 0;
    return total + pointsEarnedForItem;
  }, 0);
  
  const analyticsData = [
    { iconImage: starOutline, title: "Total Fan Points", count: totalFanPoints },
    //{ iconImage: timeOutline, title: "Total Glimpz Hours", count: 2 },
    { iconImage: heartOutline, title: "Total Glimpz Liked", count: context?.numOfLikedSongs||0 },
    { iconImage: arrowRedoOutline, title: "Total Shared", count: context?.numOfSharedPlaylists||0 },
  ];
  
  
  /*const analyticsData = [
    { iconImage: starOutline, title: "Total Fan Points", count: 3},
    { iconImage: timeOutline, title: "Total Glimpz Hours", count: 2},
    { iconImage: heartOutline, title: "Total Glimpz Liked", count: context?.numOfLikedSongs||0},
    { iconImage: arrowRedoOutline, title: "Total Shared", count: context?.numOfSharedPlaylists||0},
  ];*/

  useEffect(() => {
    setName(context?.userName || 'Guest');
    setGender(context?.userGender || 'Other');
    setMobileNo(context?.userPhone || '');
    setEmail(context?.userEmail || '');
    setSelectedFile(context?.userProfilePic || './profile-default.svg');
  }, [context]);

  const handleEditProfileClick = () => {
    router.push("/dashboard/editProfile");
  };

  return (
    <IonPage className="app-root">
      <GlimpzHeader />
      <IonContent className="custom-ion-content" 
                scrollEvents={true}
                scrollY={true}
                forceOverscroll={false}>
        <div className="container">

          {/* display profile image */}
          <div className="avatar-container">
            <img src={userProfilePic || defaultProfile}  alt="Profile"/>
            <div className="title-name">
              <IonText color="light"> {uName} </IonText>
            </div>
            <IonButton className="edit-button" onClick={handleEditProfileClick} >Edit Profile</IonButton>
          </div>
          <div className="genres-container"><div className="genre-selection">
            {context?.selectedGenres.length === 0 ? 
              <span className="placeholder">no favorite genres</span> :
              context?.selectedGenres.map((genre, index) => (
              <span key={index} className="genre-pill">
            {genre}
            </span>
            ))
            }
          </div>
          </div>
          
          <div className="analytics">
            {/* Display analytics items */}
            {analyticsData.map((data, index) => (
              <AnalyticsItem key={index} data={data} />
            ))}
          </div>


          {/* Section with grey background */}
          <div className="section-grey">
            {/* Display list items */}
            {listData.slice(0, itemsToShow).map((data, index) => (
              <ListItem key={index} data={data} />
            ))}
            {itemsToShow < listData.length && (
              <div className="arrowButton">
              <IonButton fill="clear" onClick={() => setItemsToShow(itemsToShow + 4)}>
                <IonIcon icon={arrowDownOutline} />
              </IonButton>
              </div>
            )}
            {itemsToShow >= listData.length && (
              <div className="arrowButton">
                <IonButton fill="clear" onClick={() => setItemsToShow(4)}>
                <IonIcon icon={arrowUpOutline} />
                </IonButton>
              </div>
              
            )}
          </div>
          <div className="spacer">
            FOOTER
          </div>

          {/* display profile information */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
