import { useState, useEffect, useMemo } from "react";

type CallbackFn = () => void;

function useAudioPlayer(audioRef?:React.RefObject<HTMLAudioElement>, 
  onPauseCallback?: CallbackFn
) {
  const [duration, setDuration] = useState<number>(0);
  const [curTime, setCurTime] = useState<number>(0);
  const [playing, setPlayingMe] = useState(false);
  const [clickedTime, setClickedTime] = useState<number | null>(null);
  const [slider, setSlider] = useState<string | number>(1);
  const [drag, setDrag] = useState(0);
  const audio = useMemo(() => audioRef?.current,[audioRef?.current]);
  var isActuallyPlaying = () => !audio?.paused && !audio?.ended;

  const playIfPossible = () => {
    try {
      setPlayingMe(true);
      !isActuallyPlaying() && audio?.play();
    } catch (e) {
      console.log("errr", e);
    }
  };

  const setPlaying = (bool:any) => {
    if (bool){
      playIfPossible()
    } else {
      audio?.pause();
      setPlayingMe(bool);
    }
  }

  const setAudioData = () => {
    if (audio){
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    }
  };

  const handlePaused = () => {
    //console.log("sk  paused");
    if (audio) {
      if (audio.ended || audio.paused ) {
        if (onPauseCallback) {
            onPauseCallback();
        }
      } 
    }
  };
    

  const setAudioTime = () => {
    if (audio){
      const curTime = audio.currentTime;
      setCurTime(curTime);
      setSlider(curTime ? ((curTime * 100) / audio.duration).toFixed(1) : 0);
    }
  };

  useEffect(() => {
    audio?.addEventListener("loadeddata", setAudioData);
    audio?.addEventListener("timeupdate", setAudioTime);
    audio?.addEventListener("pause", handlePaused);
    audio?.addEventListener("canplaythrough", handlePaused);

    // effect cleanup
    return () => {
      audio?.removeEventListener("loadeddata", setAudioData);
      audio?.removeEventListener("timeupdate", setAudioTime);
      audio?.removeEventListener("pause", handlePaused);
      audio?.removeEventListener("canplaythrough", handlePaused);

    };
  }, [audio]);

  useEffect(() => {
    playing == true ? playIfPossible() : audio?.pause();
  }, [playing]);

  useEffect(() => {
    if (clickedTime != null && clickedTime !== curTime && audio) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }
  }, [clickedTime]);

  return {
    curTime,
    duration,
    playing,
    slider,
    drag,
    setDrag,
    setSlider,
    setCurTime,
    setPlaying,
    setClickedTime,
  };
}

export default useAudioPlayer;
