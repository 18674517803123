// Category.tsx

import { IonTitle } from "@ionic/react";
import React , { UIEvent, useContext, useEffect, useState, useRef, useCallback } from "react";
import Deck from "../../components/Deck/Deck";
import { AuthContext } from "../../context/AuthContext";
import Deck2 from "../../components/Deck/Deck2";

const Category: React.FC<{data: any,selectedCategoryDecks: any[], selectedCategoryName: string,selectedCategoryColor: string}> = ({
    data,selectedCategoryDecks, selectedCategoryName,selectedCategoryColor}) => {

    return (
        data.length > 0 ? (
            <div className="category-container">
                <div className="category-title" style={{ color: 'white', textAlign: 'center' ,fontSize: '1.4em'}}> {selectedCategoryName} </div>
                <Deck data={selectedCategoryDecks} selectedColor={selectedCategoryColor} /> 
            </div>
        ) : null
    );
};
export default Category;
