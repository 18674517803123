    import { IonGrid,IonRow,IonIcon,IonSelect,IonSelectOption,IonButton, IonContent, IonInput, IonItem, IonText, IonLabel, IonPage, useIonRouter } from "@ionic/react";
    import React, { useState, useContext, useEffect,useMemo,useRef } from "react";
    import { useHistory } from 'react-router';
    import { AuthContext } from "../../../context/AuthContext";
    import { radioButtonOn,radioButtonOff, chevronForwardOutline } from "ionicons/icons";
    import defaultProfile from "./profile-default.svg"; 
    import { SpotifyContext } from "../../../context/SpotifyContext";
    const genders = ["Male", "Female", "Other"];
    const countries = ["United States", "Canada", "Mexico", "United Kingdom", "Germany"];
    

    // spotify stuff
    const CLIENT_ID = "52d85ebbf0fa4515aade03d83e67ce56"
    const REDIRECT_URI = "https://glimpz-it.com/dashboard/editProfile"
    // const REDIRECT_URI = "http://localhost:3000/settings"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
    const RESPONSE_TYPE = "token"
    const SCOPES = "playlist-read-private,playlist-read-collaborative,user-follow-read,user-library-modify,playlist-modify-private,playlist-modify-public,user-read-email"

    function useHash(param: string) {
        return useMemo(() => {
            const hash = window.location.hash;
            const fragments: Array<String> = hash.substring(1).split("&") ?? [];
            const paramFragment: String = fragments.find(elem => elem.startsWith(param)) ?? "";
            const paramValues = paramFragment.split("=");
            if (paramValues.length == 2) {
                return paramValues[1];
            }
            return null;
        }, [param]);
    }

    // spotify stuff ends 


    const EditProfile: React.FC = () => {
    const router = useIonRouter();

    const context = useContext(AuthContext);
    const spotifyContext = useContext(SpotifyContext);
    const userName = context?.userName ||'Guest';
    const userGender = context?.userGender ||'';
    const userPhone = context?.userPhone||''; 
    const userEmail = context?.userEmail ||'';
    const userLocation = context?.userLocation || '';
    const userProfilePic = context?.userProfilePic ||'';
    const userBDay = context?.userBDay ||'';
    const [searchTermLanguage, setSearchTermLanguage] = useState('');
    const [filteredLanguages, setFilteredLanguages] = useState(context?.languages || []);
    const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
    const [isGenreDropdownOpen, setIsGenreDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedGenres, setSelectedGenres] = useState(context?.selectedGenres || []);
    const [selectedLanguages, setSelectedLanguages] = useState(context?.selectedLanguages || []);
    const [filteredGenres, setFilteredGenres] = useState(context?.genres || []);
    const [selectedFile, setSelectedFile] = useState<any>(userProfilePic);
    const [name, setName] = useState(userName);
    const [gender, setGender] = useState(userGender);
    const [location, setLocation] = useState(userLocation);
    const [mobileNo, setMobileNo] = useState(userPhone);
    const [email, setEmail] = useState(userEmail);
    const [bDay,setBDay] = useState(userBDay);
    const history = useHistory();
    
    const inputRef = useRef<HTMLIonInputElement>(null);
    const inputRef2 = useRef<HTMLIonInputElement>(null);


    const formatPhoneNumber = (phoneNumber: string) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        // Return the cleaned number as it is if it doesn't match the format
        return cleaned;
    };
    


    let hashToken: any = useHash("access_token") ?? null;

    /*Add this to Spotify Context, which in turn adds it to preferences */
    if (hashToken != null) {
        spotifyContext?.setAccessToken(hashToken);
        // clear token from URL
        window.location.hash = '';
    }

    const [spotifyToken, setSpotifyToken] = useState(hashToken);

    useEffect(() => {

        const fetchUserProfile = async () => {

            if (spotifyToken == null) //if the access token is not in URL, find it in preferences
            {
                const respT = await spotifyContext?.getAccessToken();
                hashToken = respT?.value;
                setSpotifyToken(hashToken);
                spotifyContext?.setAccessToken(hashToken);
                console.log("access2 duvi- ", respT?.value);
            }
            if (hashToken != null) {
                try {
                    const response = await spotifyContext?.getUserProfile();
                    const imageUrl = response?.images?.[0]?.url;
                    setName(response?.display_name||'Guest');
                    context?.updateUserProfile({
                        userName: response?.display_name||'Guest',
                        profilePic: imageUrl,
                        userEmail: response?.email||'',
                    });

                    setEmail(response?.email||'');
                    setSelectedFile(imageUrl);
                    console.log("In user profile is good");
                } catch (error) {
                    console.log(`Error fetching user profile: ${error}`);
                    console.log("user prof is null 1 ");
                    spotifyContext?.setAccessToken(null);
                    setSpotifyToken(null);
                }
            }
            else {
                    setName('Guest');
                    setEmail('');
                    setSelectedFile('');
                console.log("user prof is null 2 ");
                spotifyContext?.setAccessToken(null);
                
            }
        };

        fetchUserProfile();

    }, []);
    const handleSpotifyClick = () => {
        history.push('/spotify-settings');
    };

    const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);
    const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
    


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(URL.createObjectURL(e.target.files![0]));
    };

    const handleSaveProfile = () => {
        context?.updateUserProfile({
            userBDay: bDay,
            userName: name,
            phone: mobileNo,
            gender: gender,
            profilePic: selectedFile,
            userEmail: userEmail,
            genres: selectedGenres,
            languages: selectedLanguages,
            location: location
        });
        router.goBack();
        //history.replace('/dashboard/profile');
    };
    
    useEffect(() => {
        setName(context?.userName || 'Guest');
        setGender(context?.userGender || 'Other');
        setMobileNo(context?.userPhone || '');
        setEmail(context?.userEmail || '');
        setLocation(context?.userLocation ||'');
        setSelectedFile(context?.userProfilePic || '');
    }, [context]);
    useEffect(() => {
        setFilteredGenres(
            (context?.genres || []).filter((genre) =>
                genre.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, context?.genres]);

        const handleGenreSelect = (genre: string) => {
            if (!selectedGenres.includes(genre)) {
                setSelectedGenres(prevGenres => [...prevGenres, genre]);
            }
        };

        const handleGenreDeselect = (genre: string) => {
            setSelectedGenres(prevGenres => prevGenres.filter(g => g !== genre));
        };
        const handleLanguageSelect = (language: string) => {
            if (!selectedLanguages.includes(language)) {
                setSelectedLanguages(prevLanguages => [...prevLanguages, language]);
            }
        };
        
        const handleLanguageDeselect = (language: string) => {
            setSelectedLanguages(prevLanguages => prevLanguages.filter(l => l !== language));
        };

    const handleCancelAddingGenre = () => {

        setIsGenreDropdownOpen(false);
    };


    const handleAddingGenres = () => {
        setIsGenreDropdownOpen(true);
    };

    const handleAddingLanguages = () => {
        setIsLanguageDropdownOpen(true);
    };

    const handleCancelAddingLanguage = () => {
        setIsLanguageDropdownOpen(false);
    };

    useEffect(() => {
        setFilteredLanguages(
            (context?.languages || []).filter((language) =>
                language.toLowerCase().includes(searchTermLanguage.toLowerCase())
            )
        );
    }, [searchTermLanguage, context?.languages]);


    return (
        <IonPage className="app-root">
        <IonContent className="custom-ion-content" 
                    scrollEvents={true}
                    scrollY={true}
                    forceOverscroll={false}>
            <div className="container-profile">
            <div className="avatar-container">
                <img src={selectedFile || defaultProfile}  alt="Profile"/>
                <input type="file" accept="image/*" onChange={handleFileChange} hidden id="imageUpload" />
                <IonButton className="edit-button" onClick={() => document.getElementById('imageUpload')?.click()}>Edit Picture</IonButton>
            </div>
            <div className="profile-info">
            <IonLabel>Name</IonLabel>
            <IonInput 
                ref={inputRef}
                onClick={() => {
                    if (inputRef.current) {
                        inputRef.current.setFocus();
                        inputRef.current.getInputElement().then(input => input.select());
                    }
                }}
                className="input-text" 
                type="text"
                value={name} 
                onIonChange={e => setName(e.detail.value!)} 
            />
            </div>
            {/* Gender selection */}
            <div className="profile-info">
            <IonLabel>Gender</IonLabel>
            <div className="dropdown-select">
                <div className="selected-value" onClick={() => setIsGenderDropdownOpen(!isGenderDropdownOpen)}>
                    {gender || "Select Gender"}
                </div>
                {isGenderDropdownOpen && (
                    <ul>
                    {genders.map((genders, index) => (
                    <li key={index} onClick={() => {setGender(genders); setIsGenderDropdownOpen(false);}}>
                        {genders}
                    </li>
                    ))}
                    </ul>
                )}
                </div>
            </div>

            <div className="profile-info">
            <IonLabel>Country</IonLabel>
            <div className="dropdown-select">
                <div className="selected-value" onClick={() => setIsLocationDropdownOpen(!isLocationDropdownOpen)}>
                    {location || "Select location"}
                </div>
                {isLocationDropdownOpen && (
                    <ul>
                    {countries.map((country, index) => (
                    <li key={index} onClick={() => {setLocation(country); setIsLocationDropdownOpen(false);}}>
                        {country}
                    </li>
                    ))}
                    </ul>
                )}
                </div>
            </div>
            <div className="profile-info">
            <IonLabel>Mobile No</IonLabel>
            <IonInput className="input-text"
                ref={inputRef2}
                onClick={() => {
                if (inputRef2.current) {
                    inputRef2.current.setFocus();
                    inputRef2.current.getInputElement().then(input => input.select());
                }
                }}
                type="tel"
                inputMode="numeric"
                pattern="\(\d{3}\)\s\d{3}-\d{4}"
                value={formatPhoneNumber(mobileNo)}
                placeholder="(XXX) XXX-XXXX"
                onIonChange={e => setMobileNo(e.detail.value!)}
            />
            </div>

            <div className="profile-info">
            <IonLabel>Birthday</IonLabel>
            <IonInput className="input-text" 
                type="date" 
                value={bDay} 
                onIonChange={e => setBDay(e.detail.value!)} />
            </div>
            <div className="profile-info">
                <div className="title-plus" style={{marginTop:'1.5em'}}>
                    <IonLabel>Genres</IonLabel>
                    <div className="genre-button-container">
                    {!isGenreDropdownOpen && <button className="genre-button" onClick={handleAddingGenres}>+</button>}
                    {isGenreDropdownOpen && <button className="genre-button" onClick={handleCancelAddingGenre}>x</button>}
                    </div>
                </div>
                <div className="genre-container">
                    <div className="genre-selection">
                    {selectedGenres.length === 0 ? 
                    <span className="placeholder">press + to add genres</span> :
                        selectedGenres.map((genre, index) => (
                        <span key={index} className="genre-pill">
                        {genre}
                        </span>
                        ))
                    }
                {isGenreDropdownOpen && (
                    <div className="dropdown-select-genres">
                    <div className="selected-value">
                        <IonInput className="input-text" value={searchTerm} placeholder="Search genres" onIonChange={e => setSearchTerm(e.detail.value!)} />
                        <ul>
                        {filteredGenres.map((genre, index) => (
                            <li style={{display: 'flex', alignItems: 'center'}} key={index} onClick={() => {
                            if (selectedGenres.includes(genre)) {
                            handleGenreDeselect(genre);
                            } else {
                            handleGenreSelect(genre);
                            }
                            }}>
                                {selectedGenres.includes(genre) ? 
                                <IonIcon icon={radioButtonOn} style={{color: '#7F5AEF',fontSize: '18px', marginRight: '8px'}} /> : 
                                <IonIcon icon={radioButtonOff} style={{color: 'grey',fontSize: '18px', marginRight: '8px'}} />
                                }
                                {genre}
                            </li>
                            ))}
                        </ul>
                    </div>
                    </div>
                )}
                </div>
            </div>
            </div>
            {/* Language selection */}
            <div className="profile-info">
                <div className="title-plus">
                <IonLabel>Languages</IonLabel>
                <div className="genre-button-container">
                    {!isLanguageDropdownOpen && <button className="genre-button" onClick={handleAddingLanguages}>+</button>}
                    {isLanguageDropdownOpen && <button className="genre-button" onClick={handleCancelAddingLanguage}>x</button>}
                </div>
                </div>
                <div className="genre-container">
                    <div className="genre-selection">
                    {selectedLanguages.length === 0 && !isLanguageDropdownOpen ? 
                    <span className="placeholder">press + to add languages</span> :
                    selectedLanguages.map((language, index) => (
                        <span key={index} className="genre-pill">
                        {language}
                        </span>
                    ))
                    }
                    {isLanguageDropdownOpen && (
                    <div className="dropdown-select-genres">
                        <div className="selected-value">
                        <IonInput className="input-text" value={searchTermLanguage} placeholder="Search languages" onIonChange={e => setSearchTermLanguage(e.detail.value!)} />
                        <ul>
                        {filteredLanguages.map((language, index) => (
                            <li style={{display: 'flex', alignItems: 'center'}} key={index} onClick={() => {
                            if (selectedLanguages.includes(language)) {
                            handleLanguageDeselect(language);
                            } else {
                            handleLanguageSelect(language);
                            }
                            }}>
                                {selectedLanguages.includes(language) ? 
                                <IonIcon icon={radioButtonOn} style={{color: '#7F5AEF',fontSize: '18px', marginRight: '8px'}} /> : 
                                <IonIcon icon={radioButtonOff} style={{color: 'grey',fontSize: '18px', marginRight: '8px'}} />
                                }
                                {language}
                            </li>
                            ))}
                        </ul>
                        </div>
                    </div>
                    )}
                </div>              
                </div>
            </div>
            <div className="profile-info">
            {spotifyToken && !spotifyContext?.isLoggedOut ?
                    (
                        <div className="spotify-button" onClick={handleSpotifyClick}>
                            <div>
                            <IonLabel  > Manage Spotify</IonLabel>
                            </div>
                            
                        </div>
                    ) : (
                        <div className="spotify-button">
                            <a href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}`}>
                                <IonLabel  >Connect Spotify</IonLabel>
                            </a>
                        </div>
                    )};
            </div>

            <div className="save-profile"  onClick={handleSaveProfile}>Save</div>
            <div className="save-profile"  onClick={() => router.goBack()}>Cancel</div>
            </div>
            <div className="spacer">
            FOOTER
            </div>
        </IonContent>
        </IonPage>
    );
    };

    export default EditProfile;
