/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRecos = /* GraphQL */ `
  query GetRecos($userID: Int) {
    getRecos(userID: $userID) {
      id
      
      title
      dtitle
      genre
      src
      creatorID
    }
  }
`;
export const getRecos2 = /* GraphQL */ `
  query GetRecos2($userID: Int, $userPreferences: [String]) {
    getRecos2(userID: $userID, userPreferences: $userPreferences) {
      name
      decks {
        id
        
        title
        dtitle
        genre
        src
        creatorID
      }
    }
  }
`;
export const searchX = /* GraphQL */ `
  query SearchX($valToSearch: String) {
    searchX(valToSearch: $valToSearch) {
      decksList {
        id
        
        title
        dtitle
        genre
        src
        creatorID
      }
      otherDecks {
        id
        
        title
        dtitle
        genre
        src
        creatorID
      }
    }
  }
`;
export const getDeckSource = /* GraphQL */ `
  query GetDeckSource($deckID: Float) {
    getDeckSource(deckID: $deckID)
  }
`;
export const getLikedSongsP = /* GraphQL */ `
  query GetLikedSongsP($userID: Int) {
    getLikedSongsP(userID: $userID) {
      id
      albumid
      albumname
      coverart_a
      explicit
      jsonartists
      min_date
      sartists
      title
      spotifyid
      # trackurl

      snippeturl
      uri
      decks {
        nextToken
      }
    }
  }
`;
export const getTrendingDecks = /* GraphQL */ `
  query GetTrendingDecks($userID: Int) {
    getTrendingDecks(userID: $userID) {
        id
        
        title
        dtitle 
        genre
        src
        src
        creatorID
    }
  }
`;
export const getFavoriteDecks = /* GraphQL */ `
  query GetFavoriteDecks($userID: Int) {
    getFavoriteDecks(userID: $userID) {
        id
        
        title
        dtitle
        genre
        src
        creatorID
        updatedAt
    }
  }
`;
export const getHeroDeck = /* GraphQL */ `
  query GetHeroDeck($userID: Int) {
    getHeroDeck(userID: $userID) {
      id
      
      title
      dtitle
      genre
      src
      creatorID
      cards {
          card {
            id
            albumgenres
            albumhref
            albumid
            albumlabel
            albumname
            albumpopularity
            albumtype
            coverart_a
            coverart_b
            coverart_c
            explicit
            external_urls
            genres
            jsonartists
            min_date
            popularity
            sartists
            totaltracks
            title
            spotifyid

            # trackurl

            snippeturl
            uri
          }
        }
        nextToken
      }
    }
  }
`;
export const getCard = /* GraphQL */ `
  query GetCard($id: Float!) {
    getCard(id: $id) {
        id
        albumid
        albumname
        coverart_a
        explicit
        jsonartists
        min_date
        sartists
        title
        spotifyid

        # trackurl

        snippeturl
        uri
        decks {
        nextToken
        }
    }
  }
`;
export const listCards = /* GraphQL */ `
  query ListCards(
    $id: Float
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCards(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        albumid
        albumname
        coverart_a
        explicit
        genres
        jsonartists
        min_date
        sartists
        title
        spotifyid

        # trackurl
        snippeturl
        uri
      }
      nextToken
    }
  }
`;
export const bySpotifyid = /* GraphQL */ `
  query BySpotifyid(
    $spotifyid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySpotifyid(
      spotifyid: $spotifyid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        albumid
        albumname
        coverart_a
        explicit
        genres
        jsonartists
        min_date
        sartists
        title
        spotifyid

        # trackurl
        snippeturl
        uri
      }
      nextToken
    }
  }
`;
export const getDeck = /* GraphQL */ `
  query GetDeck($id: Float!) {
    getDeck(id: $id) {
      id
      
      title
      dtitle
      genre
      src
      creatorID
      cards {
        items {
          card {
            id
            albumgenres
            albumhref
            albumid
            albumlabel
            albumname
            albumpopularity
            albumtype
            coverart_a
            coverart_b
            coverart_c
            explicit
            external_urls
            genres
            jsonartists
            min_date
            popularity
            sartists
            totaltracks
            title
            spotifyid

            #trackurl
            snippeturl
            uri
          }
        }
        nextToken
      }
    }
  }
`;
export const listDecks = /* GraphQL */ `
  query ListDecks(
    $id: Float
    $filter: ModelDeckFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDecks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        
        title
        dtitle
        dkType
        genre
        src
        creatorID
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTitle = /* GraphQL */ `
  query ByTitle(
    $title: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDeckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTitle(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        
        title
        dtitle
        genre
        src
        creatorID
      }
      nextToken
    }
  }
`;
export const getDeckByCreatorID = /* GraphQL */ `
  query GetDeckByCreatorID(
    $creatorID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelDeckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeckByCreatorID(
      creatorID: $creatorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        
        title
        dtitle
        genre
        src
        creatorID
      }
      nextToken
    }
  }
`;
export const searchDecks = /* GraphQL */ `
  query SearchDecks(
    $filter: SearchableDeckFilterInput
    $sort: [SearchableDeckSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDeckAggregationInput]
  ) {
    searchDecks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        
        title
        dtitle
        genre
        src
        creatorID
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdjacencyGroup = /* GraphQL */ `
  query GetAdjacencyGroup($id: Int!) {
    getAdjacencyGroup(id: $id) {
      id
      title
      source
      decks {
        id
        
        title
        dtitle
        genre
        src
        creatorID
      }
    }
  }
`;
export const listAdjacencyGroups = /* GraphQL */ `
  query ListAdjacencyGroups(
    $id: Int
    $filter: ModelAdjacencyGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdjacencyGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        source
        decks {
          id
          
          title
          dtitle
          genre
          src
          creatorID
        }
      }
      nextToken
    }
  }
`;
export const byGrpTitle = /* GraphQL */ `
  query ByGrpTitle(
    $title: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdjacencyGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGrpTitle(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        source
        decks {
          id
          
          title
          dtitle
          genre
          src
          creatorID
        }
      }
      nextToken
    }
  }
`;
export const getUserTracks = /* GraphQL */ `
  query GetUserTracks($userID: Int!, $trackID: Int!) {
    getUserTracks(userID: $userID, trackID: $trackID) {
  query GetUserTracks($userID: Int!, $trackID: Int!) {
    getUserTracks(userID: $userID, trackID: $trackID) {
      userID
      trackID
      liked
      source
    }
  }
`;
export const listUserTracks = /* GraphQL */ `
  query ListUserTracks(
    $userID: Int
    $trackID: ModelIntKeyConditionInput
    $filter: ModelUserTracksFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserTracks(
      userID: $userID
      trackID: $trackID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        trackID
        liked
        source
      }
      nextToken
    }
  }
`;
export const getDeckHistory = /* GraphQL */ `
  query GetDeckHistory($userID: Int!, $deckID: Float!) {
    getDeckHistory(userID: $userID, deckID: $deckID) {
      userID
      deckID
      liked
      songsHeard
    }
  }
`;
export const listDeckHistories = /* GraphQL */ `
  query ListDeckHistories(
    $userID: Int
    $deckID: ModelFloatKeyConditionInput
    $filter: ModelDeckHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeckHistories(
      userID: $userID
      deckID: $deckID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        deckID
        liked
        songsHeard
      }
      nextToken
    }
  }
`;

export const getHero = /* GraphQL */ `
  query GetHero($uid: Int!) {
    getHero(uid: $uid) {
      uid
      deckID
    }
  }
`;

export const getDeckCard = /* GraphQL */ `
  query GetDeckCard($id: ID!) {
    getDeckCard(id: $id) {
      id
      cardID
      deckID
    }
  }
`;
export const listDeckCards = /* GraphQL */ `
  query ListDeckCards(
    $filter: ModelDeckCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeckCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cardID
        deckID
      }
      nextToken
    }
  }
`;
