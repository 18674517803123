import React from "react";
import { IonPage, IonToolbar, IonTitle, IonContent } from "@ionic/react";
import Playlist from "./LikesComponent";
import LikesComponent from "./LikesComponent";
import GlimpzHeader from "../../components/Header/GlimpzHeader";
import "./LikesComponent.css"
const songs = [
  {
    id: 1,
    title: "Song 1",
    artist: "Artist 1",
    coverArtUrl: "https://via.placeholder.com/64x64",
  },
  {
    id: 2,
    title: "Song 2",
    artist: "Artist 2",
    coverArtUrl: "https://via.placeholder.com/64x64",
  },
  {
    id: 3,
    title: "Song 3",
    artist: "Artist 3",
    coverArtUrl: "https://via.placeholder.com/64x64",
  },
];

const Likes: React.FC = () => {
  return (
    <IonPage className="app-root ">
      <GlimpzHeader />
      <IonContent scrollEvents={true}
                scrollY={false}
                forceOverscroll={false}
                className="custom-ion-content">
        <div className ="recently-liked-header">
          Recently Liked
        </div>
        <LikesComponent />
      </IonContent>
    </IonPage>
  );
};

export default Likes;

