import {
    IonPage,
    IonContent,
    IonText,
    IonButton,
    IonRippleEffect,
    IonIcon,
    IonBackdrop,
    IonHeader,
    IonImg,
    IonTitle,
    IonToolbar,
    IonCol,
    IonButtons,
    IonChip,
} from "@ionic/react";
import { API, graphqlOperation } from "aws-amplify";
import React, { UIEvent, useContext, useEffect, useState, useRef, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { Preferences } from "@capacitor/preferences";
//import OnboardingCarousel from "../../components/FirstTimeExp/OnboardingCarousel";
import CardStack from "../../components/CardStack/CardStack";
import SwiperCards  from "../../components/SwiperCards/SwiperCards";
import Deck from "../../components/Deck/Deck";
import Category from "../../components/Category/Category";
import CurrentPlayingDeck from "../../components/Modal/CurrentPlayingDeck/CurrentPlayingDeck";
import { AuthContext, AuthContextInterface } from "../../context/AuthContext";
import {
    getAllFavouritesDeck,
    getAllRecos,
    getAllRecos2,
} from "../../services/deck_loader";
import { favourites, recommendations } from "../../utility/constants";
import AudionBackdrop from "../../components/FirstTimeExp/AudionBackdrop";
import WhiteBackdrop from "../../components/FirstTimeExp/WhiteBackdrop";
import GlimpzHeader from "../../components/Header/GlimpzHeader";
import OverlayScreen from "../../components/OverlayScreen/OverlayScreen";
import { ENV } from '../../env/env';
import { useHistory } from 'react-router-dom';

import { heartOutline, shareSocialSharp, shareSharp, shareOutline } from 'ionicons/icons';

import '../styles.css'
import './dashboard.css'
import { Share, CanShareResult } from "@capacitor/share";
import { RouteComponentProps } from "react-router";
import ShareViaCopy, { ShareViaCopyProps } from "../../components/Modal/ShareViaCopy/ShareViaCopy";

interface DashboardProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const Dashboard: React.FC<DashboardProps> = ({match}) => {
    const context = useContext(AuthContext);

    const [favoriteDecks, setFavouriteDecks] = useState([...favourites]);
    const [recommendedItems, setRecommendedItems] = useState<any>([...recommendations,]);
    const [isMiniPlayer, setIsMiniPlayer] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShareViaCopyOpen, setIsShareViaCopyOpen] = useState(false);
    const [selectedCategoryDecks, setSelectedCategoryDecks] = useState<any[]>([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>("");
    const [selectedCategoryColor, setSelectedCategoryColor] = useState<string>("");
    const {isAutoPlayEnabled, setIsAutoPlayEnabled } = context as AuthContextInterface;
    const shouldRemoveCentralEventListenerRef = useRef(true);
    const [showOverlay, setShowOverlay] = useState<boolean | null>(false);
    const [forcePlay, setForcePlay] = useState(false); //Force play a track - state is listened to in SwiperCards
    

    const stackHeight = 400;
    const thresholdHeight = 260;
    /* For first user experience */
    const [showBackdrop, setshowBackdrop] = useState(true);
    const closeBackdrop = () => {
        setshowBackdrop(false);
    };
    const handleGetStarted = () => {
        setshowBackdrop(false);
        // You might want to also update other state/contexts here
      };

    const refElement4FTUE = React.useRef<HTMLDivElement>(null);
    const { ref: miniMusicRef, inView } = useInView();
    const [cardStackHeight, setCardStackHeight] = useState<number>(stackHeight)

    const [showToast, setShowToast] = useState({ show: false, message: "" });
    const [grid, setGrid] = useState(true);

    const scrollRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const urlDeckId = match.params.id;
        if( urlDeckId )
        {
            console.log("sk debug - handleSetDeck dashboard 2", urlDeckId);
            context?.handleSetDeck(urlDeckId, "2", "");
        }
    }, [match.params.id]);
    // useEffect() for getRecos API

    useEffect(() => {
        if (context) {
            // Set the recommendedItems from AuthContext
            var recommendedItemsArray = context?.recos2;
            setRecommendedItems(recommendedItemsArray);
        }
    }, [context?.recos2]); // Update recommendedItems when context.recos2 changes

    /**
     * This code listens to any UI event in the app, and sets autoplayenabled to true
     * This is to track the system browser blocking auto play until some UI event happens on the browser
     * When this event occurs, we will not need to synchronize the state of audio player and the Play/Pause control. 
     */
    useEffect(() => {
        const handleEvent = (event: Event) => {
          // Perform your tracking or logging here
            
            setIsAutoPlayEnabled(true);

          //once autplay is enabled, we can remove the central event listener
            shouldRemoveCentralEventListenerRef.current = false;
            document.removeEventListener('click', handleEvent);
            document.removeEventListener('tap', handleEvent);
            document.removeEventListener('swipe', handleEvent);
        };
    
        document.addEventListener('click', handleEvent);
        document.addEventListener('tap', handleEvent);
        document.addEventListener('swipe', handleEvent);
    
        return () => {
            console.log("sK: should remove", shouldRemoveCentralEventListenerRef.current)
            if (shouldRemoveCentralEventListenerRef.current) {
                document.removeEventListener('click', handleEvent);
                document.removeEventListener('tap', handleEvent);
                document.removeEventListener('swipe', handleEvent);
            }
        };
      }, []);

    const getRandomPastelColor = () => {
        const primaryColor = [127, 90, 239]; // This is the RGB equivalent of your selection color #7F5AEF

        const variationRange = 75; // Adjust this value to control the variation range

        const r = primaryColor[0] + Math.floor(Math.random() * variationRange - variationRange / 2);
        const g = primaryColor[1] + Math.floor(Math.random() * variationRange - variationRange / 2);
        const b = primaryColor[2] + Math.floor(Math.random() * variationRange - variationRange / 2);

        const color = `rgb(${r}, ${g}, ${b})`;

        return color;
    };

    useEffect(() => {
        if (recommendedItems && recommendedItems[0] && recommendedItems[0]?.decks) {
            const firstCategory = recommendedItems[0]?.decks;
            setSelectedCategoryDecks(firstCategory);
            setSelectedCategoryName(recommendedItems[0]?.name);
            setSelectedCategoryColor("#7F5AEF");
        }
    }, [recommendedItems])

    const handleCategoryClick = (decks: any, name: string) => {
        if (selectedCategoryName === name) {
            return;
        }
        setSelectedCategoryDecks(decks);
        setSelectedCategoryName(name);
        setSelectedCategoryColor(getRandomPastelColor());

        context?.handleSetDeck(decks[0].id, '0', decks[0].dtitle);
    }

    // useEffect() for getFavorites API
    // useEffect(() => {
    //     console.log("dashboard - generic useeffect 2");
    //     (async () => {
    //         try {
    //             let userId = context?.userId ? parseInt(context?.userId) : 0
    //             const { data } = await getAllFavouritesDeck(userId);
    //             const favoriteDecksArray: any = [];
    //             if (data?.getFavoriteDecks) {
    //                 for (let index = 0; index < data?.getFavoriteDecks?.length; index += 2) {
    //                     favoriteDecksArray?.push(
    //                         data?.getFavoriteDecks?.slice(index, index + 2)
    //                     );
    //                 }
    //             }
    //             setFavouriteDecks(favoriteDecksArray);

    //         } catch (err: any) {
    //             console.log("Err for Get Favorite", err);
    //         }
    //     })();
    // }, []);

    const handleContentScroll = (e: UIEvent) => {
        const scrollTop = scrollRef?.current?.scrollTop;
        if (scrollTop && scrollTop >= 1 && !isMiniPlayer) {
            setCardStackHeight(stackHeight - (scrollTop * 1.4));
        }
    }

    const handleShareDeck = async () => {
        const deckId = context?.deckId;
        try {
            const canShare = await Share.canShare();
            if (canShare.value){
                await Share.share({
                    url: `${ENV.REACT_APP_URL}/dashboard/deck/${deckId}`,
                });
            } else {
                //copy url
                setIsShareViaCopyOpen(true);
            }
            
        } catch (err) {
            console.log(`could not share the deck ${deckId}`);
        }
    }

    useEffect(() => {
        if (cardStackHeight < thresholdHeight && !isMiniPlayer) {
            setTimeout(() => {
                setIsMiniPlayer(true);
                if (scrollRef?.current) {
                    scrollRef.current.scrollTop = 0
                    setCardStackHeight(185);
                }
            }, 600)
        }
    }, [cardStackHeight])

    const handleShareViaCopyDismissed= () => {
        setIsShareViaCopyOpen(false);
    }

    useEffect(() => {

        console.log('isAutoPlayEnabled updated:', isAutoPlayEnabled);
    }, [isAutoPlayEnabled]);

    const handleOverlayClick = () => {
        setShowOverlay(false);
        setIsAutoPlayEnabled(true);
        //Invoke auto play now
        console.log("in handleoverlay click - set card playing", isAutoPlayEnabled);
        setForcePlay(true);
    };

    /**
     * This pause callback will be called when the player and UI states are out of synch
     * (check) SwiperCards component. This is the time to show overlay screen asking
     * user to click anywhere on screen. 
     */
    const handlePauseCallback = () => {
        console.log("in handlePauseCallback", isAutoPlayEnabled);
        setShowOverlay(true);
    }

    /**
     * showOverlay and autoplayenabled control if the user is to be prompted to click on the screen first time.
     */
    return (
        <IonPage className="app-root ">
            {showOverlay && !isAutoPlayEnabled && <OverlayScreen onClick={handleOverlayClick} />}
            <GlimpzHeader />

            <IonContent
                scrollEvents={true}
                scrollY={false}
                forceOverscroll={false}
                className="custom-ion-content"
            >

                <div className="container">
                    <div className="content">

                        {/* Categories Chips */}
                        <div className='categories-container'>
                            <div className='category-chips-container'>
                                {recommendedItems?.map((items: any, index: number) => {
                                    const isSelected = selectedCategoryName ? selectedCategoryName === items.name : index === 0;
                                    const className = `category-chip${isSelected ? ' selected' : ''}`;
                                    const chipStyle = isSelected ? { backgroundColor: selectedCategoryColor } : {};
                                    return (
                                        <IonChip 
                                            key={index} 
                                            onClick={() => handleCategoryClick(items.decks, items.name)} 
                                            className={className}
                                            style={chipStyle}
                                        >
                                        <IonText className="category-chip-text">{items.name}</IonText>
                                        </IonChip>
                                    )
                                })}
                            </div>
                        </div>
                         
                        {/* hero deck title */}
                        <div className="header">
                            <div className="header-left">
                                <IonText color="light">{context?.title}</IonText>
                                <div className="share-button" onClick={handleShareDeck}>
                                    <IonIcon slot="icon-only" icon={shareOutline} className="thinIconBtn" size="small" />
                                </div>
                            </div>
                        </div>
                        {/* main-deck-container  */}
                        <div className="main_deck_container" ref={refElement4FTUE}>
                            <div className="card-scroll" style={{ minHeight: cardStackHeight }} >
                                <SwiperCards data="" 
                                             pauseCallback={handlePauseCallback} 
                                             forcePlay={forcePlay}
                                />
                            </div>
                        </div>

                        {/* Based on Recommendations */}
                        <div>
                            {/*<div style={{ paddingLeft: "10px" }}>
                                <IonText color={"light"}>
                                    <h3 style={{ margin: "1em 0", padding: 0, fontSize: "22px" }}>
                                        You may also like...
                                    </h3>
                                </IonText>
                            </div>*/}
                            <div ref={miniMusicRef}>
                            <Category 
                                data={recommendedItems} 
                                selectedCategoryName={selectedCategoryName} 
                                selectedCategoryColor={selectedCategoryColor}
                                selectedCategoryDecks={selectedCategoryDecks}
                            />
                            </div>
                        </div>
                        <div className="spacer">footer</div>

                    </div>
                    <CurrentPlayingDeck
                        isOpen={isModalOpen}
                        onDismiss={() => setIsModalOpen(!isModalOpen)}
                    />

                    <ShareViaCopy
                        isOpen={isShareViaCopyOpen}
                        onDismiss={handleShareViaCopyDismissed}
                        deckId={context?.deckId}
                    />
                </div>
            </IonContent>

            {/* Changes for First Time User Experience. 
                02/10 changes: display only after the page has loaded. 
                To do: do it only once for a user based on stored session cookies  
            */}
            
            {/*{(context?.isOnboardingCompleted === false && context?.isLoading === false) ?
                (
                    <>
                        {showBackdrop && refElement4FTUE.current && (
                            <IonBackdrop visible={true}></IonBackdrop>
                        )}

                        {showBackdrop && refElement4FTUE.current && (
                            <WhiteBackdrop onSkipClick={() => setshowBackdrop(false)} />
                        )}
                    </>
                ) : (<></>)
            }*/}

        </IonPage>
    );
};

export default Dashboard;
