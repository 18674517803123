
import React, { useEffect, useContext } from "react";
import { heartOutline, shareSocialSharp, shareSharp, shareOutline } from 'ionicons/icons';
import audion from "../../../src/assets/audion.svg";
import glimpz from "../../../src/components/Header/glmps.svg";
import { AuthContext } from "../../context/AuthContext";
import { RouteComponentProps } from "react-router";

import {
    getAllRecos2,
} from "../../services/deck_loader";

interface SplashScreenProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const SplashScreen: React.FC<SplashScreenProps> = ({match}) => {

    const authContext = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            const { data } = await getAllRecos2();
    
            var recommendedItemsArray: any = [];

            for (let data_indx = 0; data_indx < data?.getRecos2!.length; data_indx++) {
                var item = data?.getRecos2?.[data_indx];
                var decks = item?.['decks'];
                var category = item?.['name'];
                recommendedItemsArray?.push({ name: category, decks: item?.['decks'] });
            }
    
            if (authContext?.setRecos2Values) {
                authContext?.setRecos2Values(recommendedItemsArray);

                //the first element in the recommendedItemsArray is the Hero deck.
                //Set the authContext deck_id to this hero deck so that it is automatically
                //retrieved and displayed.
                const firstItem = recommendedItemsArray[0];
                if (firstItem) {
                    if (firstItem.decks && firstItem.decks.length > 0) {
                        const firstDeck = firstItem.decks[0];
                        const title = firstDeck?.dtitle || "For You";
                        !match.params.id && authContext?.handleSetDeck(firstItem.decks[0]?.id, '0', title);
                    }
                }
            }
        })();
    }, []); // Empty dependency array ensures the effect runs only once
    


    return (
        <div className="splash-screen">
            <img src={glimpz} alt="Logo" className="logo" />
            <img src={audion} alt="Logo" className="sub-logo" />
        </div>
    );
};

export default SplashScreen;
