import {
  IonPage,
  IonContent,
  IonItemDivider,
  IonSearchbar,
  IonText,
  IonLoading,
  IonInfiniteScroll,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import CardStack from "../../../components/CardStack/CardStack";

import Deck from "../../../components/Deck/Deck";
import SwiperCards from "../../../components/SwiperCards/SwiperCards";
import { AuthContext } from "../../../context/AuthContext";
import { getSearchedValues } from "../../../services/deck_loader";
import "./styles.css";

const Search: React.FC = () => {
  const context = useContext(AuthContext);

  const [showMiniPlayer, setShowMiniPlayer] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [searchedDecks, setSearchedDecks] = useState<any>(null);
  const [similarSearches, setSimilarSearches] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const searchedData = await getSearchedValues(searchText!);

        const searchDeckArray = [];
        for (
          let index = 0;
          index < searchedData.data.searchX?.decksList?.length!;
          index += 2
        ) {
          searchDeckArray?.push(
            searchedData.data.searchX?.decksList?.slice(index, index + 2)
          );
        }
        setSearchedDecks(searchDeckArray);
        setSimilarSearches(searchedData.data.searchX?.otherDecks);
      } catch (err: any) {
        console.log({ err });
        const searchDeckArray = [];
        for (
          let index = 0;
          index < err.data.searchX?.decksList?.length!;
          index += 2
        ) {
          searchDeckArray?.push(
            err.data.searchX?.decksList?.slice(index, index + 2)
          );
        }
        setSearchedDecks(searchDeckArray);
        setSimilarSearches(err.data.searchX?.otherDecks);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [searchText]);

  interface ListItemProps {
    title: string;
    subTitle: string;
    deckId: number;
  }

  const ListItem = ({ title, subTitle, deckId }: ListItemProps) => (
    <div
      onClick={() => context?.handleSetDeck(deckId, '0', title)}
      className="list-item-container"
    >
      <div className="empty-box" />
      <div className="text-container">
        <IonText color={"light"}>
          <h4 style={{ margin: "0px", padding: "0px", fontSize: "18px" }}>
            {title}
          </h4>
        </IonText>
        <IonText color={"light"}>
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: "12px",
              color: "#94A1B2",
            }}
          >
            {subTitle}
          </p>
        </IonText>
      </div>
    </div>
  );

  return (
    <IonPage className="app-root scroll-content">
      <IonContent>
        <div className="container">
          <IonItemDivider />
          <IonSearchbar
            placeholder="Search for Decks"
            className="search-input"
            debounce={500}
            onIonChange={({ detail: { value } }) => setSearchText(value!)}
          />
          <IonItemDivider />
          {isLoading ? (
            <IonLoading isOpen={true} />
          ) : (
            <div>
              <IonText color="light" className="ion-font-small">
                Top Results
              </IonText>
              <Deck
                data={searchedDecks}
                handleShowMiniPlayer={() => {
                  setShowMiniPlayer(true);
                }}
                selectedColor="white"
              />
              <IonItemDivider />
              <IonText color="light" className="ion-font-small">
                Similar to your search
              </IonText>
              <IonInfiniteScroll style={{ marginBottom: "200px" }}>
                {similarSearches?.map((item: any) => (
                  <ListItem
                    key={item?.id}
                    title={item?.title}
                    subTitle={item?.genre}
                    deckId={item?.id}
                  />
                ))}
              </IonInfiniteScroll>
            </div>
          )}
        </div>
        {showMiniPlayer && (
          <div
            className="cardScroll"
            style={{
              position: "fixed",
              bottom: 40,
              left: 10,
              right: 10,
            }}
          >
            {/* <CardStack inView={true} enableAutoPlay={true} /> */}
            <SwiperCards data = ""/>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Search;
