import { API, graphqlOperation } from "aws-amplify";
import {  Storage } from 'aws-amplify';
import { GetObjectCommandOutput } from '@aws-sdk/client-s3';

import {
    getDeck,
    getFavoriteDecks,
    getLikedSongsP,
    getRecos,
    getRecos2,
    listDecks,
    searchX,
    getHero
} from "../graphql/queries";
import {
    DeleteTrackMutation,
    GetDeckQuery,
    GetFavoriteDecksQuery,
    GetLikedSongsPQuery,
    GetRecos2Query,
    ListDecksQuery,
    SearchXQuery,
    SwipeLeftMutation,
    SwipeLeftMutationVariables,
    SwipeRightMutation,
    AddLikedSongMutation,
    AddFavoriteDeckMutation,
    GetHeroQuery,
} from "../API";
import {
    addFavoriteDeck,
    addLikedSong,
    swipeLeft,
    swipeRight,
} from "../graphql/mutations";

const getDeckById = async (deckId: number) => {
    const query = (await API.graphql(
        graphqlOperation(getDeck, { id: deckId })
    )) as { data: GetDeckQuery; errors: any[] };
    return query;
};

const getHeroID = async () => {
    const query = (await API.graphql(
        graphqlOperation(getHero, { uid: 1 }))) as { 
            data: GetHeroQuery; 
            errors: any[] 
        };
    return query;
}

const listAllDecks = async () => {
    const query = (await API.graphql(graphqlOperation(listDecks))) as {
        data: ListDecksQuery;
        errors: any[];
    };
    return query;
};

const getAllRecos = async (userID: number, userPreferences: Array<string>) => {
    userID = 1
    const query = (await API.graphql(graphqlOperation(getRecos2, { userID, userPreferences }))) as {
        data: GetRecos2Query;
        errors: any[];
    };
    return query;
};

export const asStream = (response: GetObjectCommandOutput) => {
    return response.Body as ReadableStream;
  };
  
  export const asBlob = async (response: GetObjectCommandOutput) => {
    return await new Response(asStream(response)).blob();
  };
  
  export const asString = async (response: GetObjectCommandOutput) => {
    return await new Response(asStream(response)).text();
  };

type GetRecos2QueryResult = {
    data: GetRecos2Query;
    errors: any[];
  };

//refer - https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/
const getAllRecos2_s3 = async (): Promise<GetRecos2QueryResult> => {
    try {
      const result:GetObjectCommandOutput = await Storage.get('public/getRecos2.json', { download: true});
      const text = await asString(result);
  
      const query: GetRecos2QueryResult = {
        data: JSON.parse(text) as GetRecos2Query,
        errors: [],
      };
  
      return query;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAllRecos2 = async (): Promise<GetRecos2QueryResult> => {
    try {
      const cloudFrontURL = 'https://d3qkzez01hind6.cloudfront.net';
      const s3Key = 'public/getRecos2.json';
      const url = `${cloudFrontURL}/${s3Key}`;
  
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
        },
      });
  
      const jsonData = await response.json();
  
      const query: GetRecos2QueryResult = {
        data: jsonData as GetRecos2Query,
        errors: [],
      };
  
      return query;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  

const getSearchedValues = async (search: string) => {
    const query = (await API.graphql(
        graphqlOperation(searchX, { valToSearch: search })
    )) as {
        data: SearchXQuery;
        errors: any[];
    };
    return query;
};

const getAllFavouritesDeck = async (userID: number) => {
    const query = (await API.graphql(graphqlOperation(getFavoriteDecks, { userID }))) as {
        data: GetFavoriteDecksQuery;
        errors: any[];
    };
    return query;
};

const getAllLikedSongs = async (userID: number) => {
    const query = (await API.graphql(
        graphqlOperation(getLikedSongsP, { userID })
    )) as {
        data: GetLikedSongsPQuery;
        errors: any[];
    };
    return query;
};


const swipeLeftApi = async (
    userID: number | null,
    trackID: number | null,
    deckId: number | null
  ) => {
  const query = (await API.graphql(
    graphqlOperation(swipeLeft, { userID, trackID, deckId })
  )) as {
    data: SwipeLeftMutation;
    errors: any[];
  };
  return query;
};

const swipeRightApi = async (
  userID: number | null,
  trackID: number | null,
  deckId: number | null
) => {
  const query = (await API.graphql(
    graphqlOperation(swipeRight, { userID, trackID, deckId })
  )) as {
    data: SwipeRightMutation;
    errors: any[];
  };
  return query;
};

const addFavoriteDeckApi = async (
    userID: number | null,
    deckID: number | null
) => {
    const query = (await API.graphql(
        graphqlOperation(addFavoriteDeck, { userID, deckID })
    )) as {
        data: AddFavoriteDeckMutation;
        errors: any[];
    };
    return query;
};

const addLikedSongApi = async (
    userID: number | null,
    trackId: number | null
) => {
    const query = (await API.graphql(
        graphqlOperation(addLikedSong, { userID, trackId })
    )) as {
        data: AddLikedSongMutation;
        errors: any[];
    };
    return query;
};

export {
    getAllRecos,
    getDeckById,
    listAllDecks,
    swipeLeftApi,
    swipeRightApi,
    addLikedSongApi,
    getAllLikedSongs,
    getSearchedValues,
    addFavoriteDeckApi,
    getAllFavouritesDeck,
    getHeroID,
    getAllRecos2,
};
