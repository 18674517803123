import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation,useHistory } from 'react-router-dom';
import { IonLoading, IonSpinner, IonText, IonButton} from '@ionic/react';
import AWS from 'aws-sdk';
import { AuthContext } from '../../context/AuthContext';
import {Card, Deck} from './liked_storage'
import { heart } from 'ionicons/icons';
import { gsap } from "gsap";
import { SpotifyContext } from "../../context/SpotifyContext";




import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowDown, arrowForward, shareOutline, trashOutline,ellipsisHorizontalOutline } from "ionicons/icons";
import "./LikesComponent.css";

import { Storage } from "aws-amplify";



interface CardData {
  id: number;
  title: string;
  spotifyid: string;
  trackurl: string;
  snippeturl: string;
  coverart_a: string;
  albumname: string;
  albumid: string;
  jsonartists: string;
  sartists: string;
}

interface CardItem {
  card: Card;
}

interface Cards {
  items: CardItem[];
}

interface Playlist {
  deck_id: number;
  version_id: string;
  title: string;
  cards: Cards;
}

// configure AWS SDK with access keys and secrets
AWS.config.update({
    accessKeyId: 'AKIA3F4YOIDOH532NDY3',
    secretAccessKey: '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',
    region: 'us-east-1'
});

interface LocationState {
  deck: Deck;
  previousPage: any; // Type this properly
}
  
interface ShowDeckProps {
    deck: Deck;
    onDeckDelete: (deckId: number, version_id: string) => void;
}

function ShowDeck({ deck, onDeckDelete }: ShowDeckProps) {
  const context = useContext(AuthContext)
  //const titleRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showList, setShowList] = useState<boolean>(false);
  const [titleWidth, setTitleWidth] = useState(0);
  const titleRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [exportDeckID,setExportDeckID] = useState(Number)
  
  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  }, [deck]);
  useEffect(() => {
    if (titleWidth > 0) {
      const scrollingAnimation = `@keyframes scroll {
        0% { transform: translateX(5px); }
        50% { transform: translateX(-${titleWidth -200}px); } 
        100% { transform: translateX(5px); }
      }`;
      const styleSheet = document.styleSheets[0];
      styleSheet.insertRule(scrollingAnimation, styleSheet.cssRules.length);
    }
  }, [titleWidth]);

  useEffect(() => {
    const element = titleRef.current;
    if (element) {
      const isElementOverflowing = element.clientWidth < element.scrollWidth;
      setIsOverflowing(isElementOverflowing);
    }
  }, [deck]);

    const toggleList = () => {
        setShowList(!showList);
    };

    const handleDeleteClick = () => {
        const user_id = context?.userId ?? "error";
        context?.likedStorage.deleteLikedDeckfromS3(user_id, deck.deck_id, "1.0");

        //update UI
        onDeckDelete(deck.deck_id, "1.0");
    };
    const handleAddToSpotifyClick = () => {
      const locationState: LocationState = {
        deck: deck,
        previousPage: history.location
      };
      context?.setExportDeckId(deck.deck_id);
      history.push(`/add-to-spotify`);
    
    };


    // Check if the text is overflowing its container

    return (
      <>
      <div className="page-container">
      <div key={deck.deck_id} className="likes-header" onClick={toggleList}>
        <div className="likes-image-container">
          <div className="image-wrapper">
            <img
              className="circle-thumbnail"
              src={deck.cards.length ? deck.cards[0].coverart_a : undefined}
              alt="Deck"/>
          </div>
      </div>
      <div className="likes-title-container">
          <div
            className={`likes-title ${isOverflowing ? "likes-title-scrolling" : ""}`}
            ref={titleRef}
            style={isOverflowing ? { animation: 'scroll 10s linear infinite' } : {}}
          >
            {deck.title}{" "}
          </div>

          {/* If the list is visible, display the subtitle. Else, display the delete button */}
          {showList ? (
            <div className="likes-deck-buttons">
              <div onClick={handleDeleteClick} className="delete-button"> delete </div>
              {/*<div onClick={handleDeleteClick} className="delete-button" style={{backgroundColor:'#7e7e7e'}}> share </div>*/}
              <div onClick={handleAddToSpotifyClick} className="delete-button" style={{backgroundColor:'#1DB954'}}> add to spotify</div>
            </div>
            
            
          ) : (
            <div className={`likes-subtitle-collapsed`}>
              {`${deck.cards.length} tunes`}
            </div>
          )}
      </div>
      <div className="likes-icons">
          {/*<IonIcon icon={share} />
          <IonIcon icon={showList ? arrowDown : arrowForward} />
          <IonIcon icon={trash}  onClick={handleDeleteClick} />*/}
          <IonIcon icon={ellipsisHorizontalOutline}/>
      </div>
      </div>
      <div>
      {showList && (
        <IonList className="MyIonList"  key={deck.deck_id}>
          {deck.cards.map((cardItem) => (
            <IonItem lines="none" key={cardItem.id}>
              <IonThumbnail slot="start" className="thumbnail-size">
                <img src={cardItem.coverart_a} alt="Cover art" />
              </IonThumbnail>
              <IonLabel className="song-info">
                <h2>{cardItem.title}</h2>
                <h3>{cardItem.sartists}</h3>
              </IonLabel>
              {/*<div className="liked-song-buttons">
                <IonIcon icon={shareOutline} className="liked-song-icon" style={{color:'white'}}/>
                <IonIcon icon={trashOutline} className="liked-song-icon" style={{color:'white'}}/>
          </div>*/}
            </IonItem>
          ))}
        </IonList>
      )}
    </div>
    </div>
    </>
  );
}


  interface DeckListProps {
    decks: Deck[];
    onDeckDelete: (deckId: number, version_id:string) => void;
  }


  function DeckList({ decks, onDeckDelete }: DeckListProps) {
    const [deckList, setDeckList] = useState<Deck[]>(decks);
    
    useEffect(() => {
      console.log("DeckList: useEffect DeckList ", deckList.length, decks.length);
      setDeckList(decks);
    }, [deckList, decks]);
    
    return (
      <div>
        {deckList.map((deck) => (
          <ShowDeck key={deck.deck_id} deck={deck} onDeckDelete={onDeckDelete} />
        ))}
      </div>
    );
  }

  interface SongCardProps {
    song: Card;
    key: number;
  }
  
  const SongCard: React.FC<SongCardProps> = ({ song }) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const albumRef = useRef<HTMLDivElement>(null);
    const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
    const [isAlbumOverflowing, setIsAlbumOverflowing] = useState(false);
  
    useEffect(() => {
      const checkOverflow = () => {
        if (titleRef.current) {
          setIsTitleOverflowing(titleRef.current.offsetWidth < titleRef.current.scrollWidth);
        }
        if (albumRef.current) {
          setIsAlbumOverflowing(albumRef.current.offsetWidth < albumRef.current.scrollWidth);
        }
      }
  
      const titleObserver = new ResizeObserver(checkOverflow);
      const albumObserver = new ResizeObserver(checkOverflow);
  
      if (titleRef.current) {
        titleObserver.observe(titleRef.current);
      }
      if (albumRef.current) {
        albumObserver.observe(albumRef.current);
      }
  
      checkOverflow();
  
      return () => {
        titleObserver.disconnect();
        albumObserver.disconnect();
      };
    }, [song]);
  
    return (
      <div key={song.id} className="song-card">
        <img src={song.coverart_a} alt={song.title} />
        <div className="songs-title-container" ref={titleRef}>
          <div className={`song-card-title  ${isTitleOverflowing ? "song-title-scrolling" : ""}`}>
            {song.title}
          </div>
        </div>
        <div className="songs-album-container" ref={albumRef}>
          <div className={`song-card-album  ${isAlbumOverflowing ? "album-title-scrolling" : ""}`}>
            {song.albumname}
          </div>
        </div>
      </div>
    );
  };
  
  
  interface RecentlyLikedSongsProps {
    songs: Card[];
  }
  
  const RecentlyLikedSongsComponent: React.FC<RecentlyLikedSongsProps> = ({ songs }) => {
  return (
    <div className="recently-played-container">
      {songs.map((song) => (
        <SongCard song={song} key={song.id} />
      ))}
    </div>
  );
};


const LikesComponent: React.FC = () => {
  const [decks, setDecks] = useState<Deck[]>([]);
  const [loading, setLoading] = useState(true);
  const [deckListUpdate, setDeckListUpdate] = useState(false);

  const context = useContext(AuthContext)
  const [likesUpdates, setLikesUpdates] = useState(-1);

  const handleDeckDelete = (deckId: number, version_id:string) => {
    console.log("LikedComp: handleDeckDelete ")
    const updatedDeks = decks.filter((deck) => deck.deck_id !== deckId);

    //Check if the deleted deck_id is in temp storage 
    const likedDeck = context?.likedStorage.getLikedDeck();
    if( likedDeck?.deckId == deckId )
    {
       context?.likedStorage.resetCards();
    }

    setDecks(updatedDeks);
  };

  const likedSongs = context?.likedStorage.getCards();

  async function fetchData() {
    setLoading(true);
    try {
      const fetchedDecks = await context?.likedStorage.getDeckFromPlaylist(context?.userId??"empty");
      const inMemDeck = context?.likedStorage.getDeckJsonFromMemory();
      if( fetchedDecks && inMemDeck == undefined )
      {
        console.log("setting deck after fetching data - size", fetchedDecks.length);
        setDecks(fetchedDecks);
      }
      else if( fetchedDecks && inMemDeck )
      {
        setDecks([inMemDeck, ...fetchedDecks]);      
      }
      else if( inMemDeck )
      {
        setDecks([inMemDeck, ...decks]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  /**
   * This useeffect block checks if the likes were updated/added. If so, it forces a refetch
   * of likes data from S3. The likes data is directly written to S3 and hence a write followed immediately
   * by a read is mssing the new content at times. By deferring and forcing the fetch only when the 
   * likes/profile tab is clicked, we will optimize network hits too. 
   * One pending issue - When user clicks out of landing page to profile page, the in-progress likes are
   * not updated to S3. In this case we may want to present the likes information directly from likes storage. 
   * 
   */
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/dashboard/library') {
    
      setDeckListUpdate(false);
      if( likesUpdates != context?.likesUpdated )
      {
         console.log("LikesComp: useEffect - force fetch all", likesUpdates, context?.likesUpdated)
         setLikesUpdates(context?.likesUpdated ??0);
         fetchData();
      }
      /** else if context.likedStorage has floating content - then show it in likes page by updating the content 
               in memory directly  */
      else {
        if( context?.likedStorage.getCards().length > 0 )
        {
           const inMemDeck = context?.likedStorage.getDeckJsonFromMemory();
    
           //Check if the inMemory deck is already added to the likedDecks. 
           const existingIndex = decks.findIndex(deck => deck.deck_id === inMemDeck?.deck_id);

          if (existingIndex !== -1 && inMemDeck) {
            const updatedDecks = [...decks];
            updatedDecks[existingIndex] = inMemDeck;
            setDecks(updatedDecks);
            console.log("LikesComp: replaced memory deck", inMemDeck.deck_id, decks)
            setDeckListUpdate(true);
          } 
          else if( inMemDeck )
          {
             setDecks([inMemDeck, ...decks]);
             console.log("LikesComp: added in memory deck", inMemDeck.deck_id, decks)
             setDeckListUpdate(true);
          }
           /** Todo - what if the user trashes the likedDeck rendered from inMemory - it will also need to be 
            * reset from likesStorage memory. In this case the Deck has to keep track of its source - as S3 or inMemory and
            * execute delete operation accordingly. 
            */
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log("LikesComp: in useEffect-decks ", decks.length, deckListUpdate);
  }, [decks, deckListUpdate]);
  
  return (
    <IonContent scrollEvents={true} className="custom-ion-content">
      <div className="recently-liked-section">
        <IonTitle className="recently-liked-title">Recently Liked</IonTitle>
        <RecentlyLikedSongsComponent songs={likedSongs ? likedSongs : []} />
      </div>
      <div className="title-container"><div className="saved-playlists">Saved Playlists</div></div>
      {/*<RecentlyLikedSongs songs={recentlyLikedSongs} />*/}
      {loading ? (
        <IonLoading
          cssClass="loading-class"
          isOpen={loading}
          message={'Loading. Please wait...'}
        />
      ) : (
        <>
          {decks.length > 0 ? (
            <div>
              <DeckList decks={decks} onDeckDelete={handleDeckDelete} />
              <div className="spacer">
                Footer
              </div>

            </div>
            
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <div style={{ textAlign: "center" }}>
              
                <h2>Tap or Click </h2>
                <IonIcon icon={heart} style={{ fontSize: "64px", color: "white" }} />
                <h2>to Like a Song</h2>
              </div>
            </div>
            
          )}
        </>
      )}
    </IonContent>
  );
  
};

export default LikesComponent;

