import { IonText } from "@ionic/react";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectCards, Virtual, Navigation } from 'swiper';
import { Swiper as SwiperType } from 'swiper'

import "./Deck.scss"

import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css/navigation';

const Deck: React.FC<{ data: any; handleShowMiniPlayer?: () => void; selectedColor: string }> = ({
    data,
    handleShowMiniPlayer,
    selectedColor
}) => {
    const context = useContext(AuthContext);
    const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
    const [selectedDeckId, setSelectedDeckId] = useState<string | null>(null); // ADDED THIS LINE  TO KEEP TRACK OF SELECTED DECK'S ID

    // Generate darker shades of the selected color
    const generateDarkerColors = (color: string, count: number) => {
        const baseColor = color.toLowerCase();
        const colors = [baseColor];
    
        const darkenFactor = 20; // Adjust this value to control the darkness increment
    
        for (let i = 1; i < count; i++) {
        const newColor = darkenRgb(baseColor, i * darkenFactor);
        colors.push(newColor);
        }
    
        return colors;
    };
    
    // Darken RGB color
    const darkenRgb = (rgb: string, factor: number) => {
        let [r, g, b] = rgb.replace(/rgb\(/, '').replace(/\)/, '').split(',').map(parseFloat);
        r = Math.max(r - factor, 0);
        g = Math.max(g - factor, 0);
        b = Math.max(b - factor, 0);
        return `rgb(${r}, ${g}, ${b})`;
    };
    
    const darkerColors = generateDarkerColors(selectedColor, data?.length);
    // Generate 3 darker shades

    return (
        <div className="deck-container">

            <Swiper
                onSwiper={setSwiperRef}
                grabCursor={true}
                slidesPerView={3}
                freeMode={true}
                spaceBetween={10}
                className="decks-swiper"
            >

                {data?.map((items: any, index: number) => (
                    <div key={index} className={'decks-list'}>
                        <SwiperSlide
                            key={index}
                            virtualIndex={index}
                            className={'decks-swiper-slide'}
                            onClick={() => {
                                if (selectedDeckId === items?.id) { // ADDED THIS CHECK TO DO NOTHING IF DECK IS ALREADY SELECTED
                                    return;
                                }
                                setSelectedDeckId(items?.id); // ADDED THIS LINE TO ADD TO SET SELECTED DECK ID
                                context?.handleSetDeck(items?.id, '0', items?.dtitle);
                                handleShowMiniPlayer?.();
                                context?.handleSetAutoPlay(true);
                                context?.handleSetTitle(items?.dtitle);
                                context?.handleSetDeck(items?.id, "1.0", items?.dtitle);
                            }}
                            style={{ backgroundColor: darkerColors[index] ,padding:'0 0.5em'}}
                        >
                            {items?.dtitle}
                        </SwiperSlide>
                    </div>
                ))}
            </Swiper>
        </div>

    );
};

export default Deck;