import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonItemDivider,
  IonLoading,
  IonPage,
  IonRippleEffect,
  IonText,
  useIonRouter,
  useIonViewWillLeave,
} from "@ionic/react";

import "./styles.css";
import {
  getAllLikedSongs,
  swipeLeftApi,
  swipeRightApi,
} from "../../../../services/deck_loader";
import Card from "../../../../components/Card/Card";
import CurrentPlayingDeck from "../../../../components/Modal/CurrentPlayingDeck/CurrentPlayingDeck";
import { AuthContext } from "../../../../context/AuthContext";

interface ListItemProps {
  title: string;
  subTitle: string;
  image: string;
  handleDeleteCard: () => void;
}

interface ButtonComponentProps {
  onClick: () => void;
}

const LikedSongs: React.FC = () => {
  const context = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  let [likedSongs, setLikedSongs] = useState<any>(null);
  const [miniMusic, setMiniMusic] = useState<any>(null);

  const [showMiniPlayer, setShowMiniPlayer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const router = useIonRouter();

  const [songUrl, setSongUrl] = useState<any>(null);

  useEffect(() => {

    (async () => {
      try {
        setIsLoading(true);
        let userId = context?.userId ? parseInt(context?.userId) : 0
        const data: any = await getAllLikedSongs(userId);
        setLikedSongs(data?.data.getLikedSongsP);
      } catch (err: any) {
        console.log("Unable to get liked songs");
        setLikedSongs(err?.data?.getLikedSongsP);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);




  // useIonViewWillLeave(() => {
  //   context?.handleSetDeckId("2653390031");
  //   context?.resetDeleteCount();
  //   context?.handleSetAutoPlay(false);
  // }, []);


  const ListItem = ({ title, subTitle, image,handleDeleteCard }: ListItemProps) => (
    <div className="liked-item-container"
    >
      <div style={{ display: "flex" }} 
      onClick={() => {

        

        const filtereClickedSongs = likedSongs.filter(
          (item: any) => item.title === title
        );


        const filterOtherSongs = likedSongs.filter(
          (items: any) => items.title !== title
        );
        
        setMiniMusic(filterOtherSongs.concat(filtereClickedSongs));

        const reversedArray = [...miniMusic];

        const reversedUrl = reversedArray?.map?.((item) => ({
          url:item?.snipptUrl
        }));
        
        setSongUrl(reversedUrl.reverse());

        setShowMiniPlayer(true);
        context?.handleSetAutoPlay(true);

        if (showMiniPlayer == true){
          setShowMiniPlayer(false);
          context?.handleSetAutoPlay(false);
          setIsLoading(true);
          setShowMiniPlayer(true);
          context?.handleSetAutoPlay(true);
          setTimeout(() => {
            setIsLoading(false);
          });

        }
      }} >
        <IonImg
          src={image}
          alt={image}
          style={{ width: "50px", height: "50px" }}
        />
        <div className="text-container">
          <IonText color={"light"}>
            <h4 style={{ margin: "0px", padding: "0px", fontSize: "18px" }}>
              {title}
            </h4>
          </IonText>
          <IonText color={"light"}>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: "12px",
                color: "#94A1B2",
              }}
            >
              {subTitle ?? "subHeading"}
            </p>
          </IonText>
        </div>
      </div>
      <div>

      <IonButton
        onClick={handleDeleteCard}
        expand="block"
        color={"white"}
        fill="clear"
      >
        <IonImg
          className="btn-icon"
          src="assets/icon/blueHeart.svg"
          alt="deleteIcon"
          style={{ width: 20, height: 20 }}
        />
      </IonButton>
      </div>
    </div>
  );

  return (
    <IonPage className="app-root">
      <IonContent>
        <IonItemDivider />
        <div className="container">
          <div className="header">
            <IonButton
              color={"white"}
              className="ion-activatable ripple-parent"
              fill="clear"
              onClick={() => router.goBack()}
              style={{ marginLeft: "-20px" }}
            >
              <IonIcon src="assets/icon/backArrow.svg" />
              <IonRippleEffect></IonRippleEffect>
            </IonButton>
            <IonButton
              color={"white"}
              className="ion-activatable ripple-parent"
              fill="clear"
              style={{ marginRight: "-20px" }}
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              <IonIcon src="assets/icon/more.svg" />
              <IonRippleEffect></IonRippleEffect>
            </IonButton>
          </div>
          <IonItemDivider />
          <IonText color={"light"} className="ion-font-large">
            Liked Songs
          </IonText>
          <IonText color={"light"}>
          </IonText>
          <IonItemDivider />
          {isLoading ? (
            <IonLoading isOpen={true} />
          ) : (
            <>
              {likedSongs?.map((item: any) => (
                <ListItem
                  key={item.id}
                  title={item?.title}
                  subTitle={item?.sartists}
                  image={item?.coverart_a} 
                  handleDeleteCard={async function (): Promise<void> {
                    // setIsLoading(true);
                    const deleteSong = item?.id;
                    // console.log(item?.id)
                    await swipeLeftApi(null,item?.id,null);


                    // console.log(item.id !== deleteSong)
                    const filteredSongs = likedSongs.filter(
                      (item: any) => item.id !== deleteSong
                    );
                    setLikedSongs(filteredSongs);

                    setTimeout(() => {
                      setIsLoading(false);
                    });
                  } }                  
                  />
              ))}


             

              {showMiniPlayer && (
                <div
                  className="cardScroll"
                  style={{
                    position: "absolute",
                    bottom: 40,
                    left: 10,
                    right: 10,
                  }}
                >
                  <div style={{ display: "grid" }}>
                    { miniMusic?.map((item: any, index: number) => (
                      <Card
                        key={index}
                        id={item?.id}
                        sp_id={item?.spotifyID}
                        deckId={item?.deckId}
                        imageUrl={item?.coverart_a}
                        songName={item?.title}
                        songArtist={item?.sartists}
                        albumname={item?.albumname ?? ""}
                        album_id={item?.albumid}
                        index={index}
                        // songUrl={item?.snipptUrl}
                        deckSongs={songUrl}
                        handleDeleteLikedSongs={async (title, gestureType) => {
                          setIsLoading(true);

                          console.log(gestureType);

                          if (gestureType === "left") {
                            await swipeLeftApi(null,item?.id,null);
                          } else {
                            await swipeRightApi(null, item?.id, null);
                          }

                          const filteredSongs = likedSongs.filter(
                            (item: any) => item.title !== title
                          );

                          setLikedSongs(filteredSongs);

                          setTimeout(() => {
                            setIsLoading(false);
                          });
                        }}
                        isLikedSongs={true}
                        inView={true}
                      />



                    ))}

                      

                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <CurrentPlayingDeck
          isOpen={isModalOpen}
          onDismiss={() => setIsModalOpen(!isModalOpen)}
        />
      </IonContent>
    </IonPage>
  );
};

export default LikedSongs;
