import { useState, useEffect, createContext } from "react";
import { Preferences } from "@capacitor/preferences";
import UUID from "uuid-int";
import {
    getDeckById,
    getHeroID,
} from "../services/deck_loader";
import { AppAnalytics } from "../utility/app-analytics"
import LikedStorage, { LikedDeck, LikedCard } from "../pages/LikesShares/liked_storage"
import { HeroDeckUtil } from "./HeroDeckUtil";


interface UserProfile {
    userBDay?: string;
    userName?: string;
    phone?: string;
    gender?: string;
    profilePic?: string;
    userEmail?: string;
    genres?: string[];
    languages?: string[];
    location?: string;
}

export interface AuthContextInterface {
    currentDeckCards: any;
    logout: any;
    deckId: number;
    title: string;
    deck_version: string;
    activeTab: string | null;
    isLoading: boolean;
    isMounted: boolean;
    idToSearch: number;
    deleteCount: number;
    userBDay:string | null;
    userPhone: string | null;
    userGender: string | null;
    userLocation:string|null;
    userProfilePic: string | null;
    userName: string | null;
    userEmail: string | null;
    exportDeckId: number | null;
    setExportDeckId: (id: number | null) => void;
    selectedGenres: string[];
    selectedLanguages:string[];
    genres: string[];
    languages: string[];
    updateUserProfile: (profile: UserProfile) => void;
    numOfLikedSongs: number;
    numOfSharedPlaylists: number;
    numOfLikedDecks: number;
    numOfCreatedPlaylists: number;
    numOfSongsAddedToSpotify: number;
    incrementLikedSongs: () => void;
    incrementLikedSpotifySongs: () => void;
    incrementSharedPlaylists: () => void;
    incrementLikedDecks: () => void;
    incrementCreatedPlaylists: () => void;
    handleSetTitle: (newTitle: string) => void;
    isAutoPlayEnabled: boolean;
    //handleSetDeckId: (id: any) => void;
    userId: string | null;
    handleActiveTab: (tab: string) => void;
    isOnboardingCompleted: boolean;
    handleOnboardingCompleted: () => void;
    login: (id: string, token: string) => Promise<void>;
    handleSetAutoPlay: (autoPlay: boolean) => void;
    resetDeleteCount: () => void;

    likedStorage: LikedStorage;
    likesUpdated: number;
    handleLikesUpdated: () => void;
    handleSetDeck: (id: any, version_id: string, title: string) => void;
    recos2: any; 
    setRecos2Values: (recos2i: any) => void;
    setIsAutoPlayEnabled: (val:boolean) => void;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

export const AuthProvider: React.FC = ({ children }) => {

    const heroDeckInfo = new HeroDeckUtil();
    
    const [userId, setUserId] = useState<string | null>(null);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
    const [deleteCount, setDeleteCount] = useState(0);
    const [currentDeckCards, setCurrentDeckCards] = useState<any[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deckId, setDeckId] = useState<number>(0);
    const [idToSearch, setIdToSearch] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string | null>(null);
    const [isAutoPlayEnabled, setIsAutoPlayEnabled] = useState<boolean>(false);
    const [title, setTitle] = useState("iTunes Global 200 - Today");
    const [decktitle, setDeckTitle] = useState("iTunes Global 200 - Today");
    const [likesUpdated, setLikesUpdated] = useState<number>(0);
    const likedStorage = LikedStorage.getInstance();
    const [userBDay, setUserBDay] = useState<string | null>(null);
    const [userName, setUserName] = useState<string | null>(null);
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [userPhone, setUserPhone] = useState<string | null>(null);
    const [userGender, setUserGender] = useState<string | null>(null);
    const [userLocation, setUserLocation] = useState<string | null>(null);
    const [userProfilePic, setUserProfilePic] = useState<string | null>(null);
    // dynamic analytics
    const [numOfLikedSongs, setNumOfLikedSongs] = useState<number>(0);
    const [numOfSongsAddedToSpotify, setNumOfSongsAddedToSpotify] = useState<number>(0);
    const [numOfSharedPlaylists, setNumOfSharedPlaylists] = useState<number>(0);
    const [numOfLikedDecks, setNumOfLikedDecks] = useState<number>(0);
    const [numOfCreatedPlaylists, setNumOfCreatedPlaylists] = useState<number>(0);

    const [exportDeckId, setExportDeckId] = useState<number | null>(null);

    const [recos2, setRecos2] = useState<any>([]);


    var deck_version: string = "";

    // methods to update analytics
    const incrementLikedSongs = () => {
        setNumOfLikedSongs(prevNum => prevNum + 1);
    };
    
    const incrementSharedPlaylists = () => {
        setNumOfSharedPlaylists(prevNum => prevNum + 1);
    };
    const incrementLikedSpotifySongs = () => {
        setNumOfSongsAddedToSpotify(prevNum => prevNum+1);
    };
    
    const incrementLikedDecks = () => {
        setNumOfLikedDecks(prevNum => prevNum + 1);
    };
    
    const incrementCreatedPlaylists = () => {
        setNumOfCreatedPlaylists(prevNum => prevNum + 1);
    };

    const handleLogin = async (id: string, token: string) => {
        await Preferences.set({ key: id, value: JSON.stringify(token) });
        setUserId(userId);
    };

    const handleLogOut = async (id: string) => {
        await Preferences.remove({ key: id });
        setUserId(null);
    };

    const handleOnboardingCompleted = async () => {
        await Preferences.set({
            key: "isOnboardingCompleted",
            value: JSON.stringify(true),
        });
        setIsOnboardingCompleted(!isOnboardingCompleted);
    };
    const updateUserProfile = (profile: UserProfile) => {
        if(profile.userBDay) setUserBDay(profile.userBDay);
        if(profile.userName) setUserName(profile.userName);
        if(profile.phone) setUserPhone(profile.phone);
        if(profile.gender) setUserGender(profile.gender);
        if(profile.profilePic) setUserProfilePic(profile.profilePic);
        if(profile.userEmail) setUserEmail(profile.userEmail);
        if(profile.location) setUserLocation(profile.location);
        if(profile.genres) updateSelectedGenres(profile.genres);
        if(profile.languages) updateSelectedLanguages(profile.languages);
    };
    

    useEffect(() => {
        (async () => {
            const _id = await Preferences.get({ key: "id" });
            if (_id.value) {
                setUserId(_id.value);
            } else {
                const randomId = Math.random() * 511;
                const generator = UUID(randomId);
                const uniqueNumber = generator.uuid()
                const deduct = Math.floor(uniqueNumber / 10000000) * 10000000;
                const uniqueId = (uniqueNumber - deduct).toString();
                await Preferences.set({
                    key: "id",
                    value: uniqueId,
                });
                setUserId(uniqueId);
            }
            const onBoardingStatus = await Preferences.get({
                key: "isOnboardingCompleted",
            });
            setIsOnboardingCompleted(
                onBoardingStatus.value === "true" ? true : false
            );

            setIsMounted(true);
        })();
    }, []);

    useEffect(() => {
        if (currentDeckCards?.length) {
            setIsLoading(false);
        }
    }, [currentDeckCards]);

    //   useEffect(() => {

    //     (async () => {
    //       try {
    //         const data = await getHeroID();
    //         const heroDeckID = data?.data?.getHero?.deckID.toString();
    //         await getDeckById(parseInt(heroDeckID!.toString()));
    //       } catch (err: any) {
    //         console.log('in catch')
    //         setIdToSearch(err?.data?.getDeck?.id);
    //         setCurrentDeckCards(err?.data?.getDeck?.cards?.items);
    //       }
    //     })();

    //   }, []);

    const loadDeckId = async (idToSearch:string | undefined) => {

        console.log("loadDeckId 0", idToSearch);
        var deck_id_val: any = 0;
        try {
            setIsLoading(true);

            if (idToSearch != null) {
                const dataOfSearch = await getDeckById(
                    parseInt(idToSearch)
                );
                const deck = dataOfSearch?.data?.getDeck;
                deck_id_val = deck?.id ?? 0;
                setDeckTitle(deck?.title ?? ""); //Commented out because it set the main deck title as category title
                console.log(deck?.title ?? "")
                setDeckId(deck_id_val);
                //setIdToSearch(deck?.id);
                setCurrentDeckCards(deck?.cards?.items);

                console.log("loadDeckId 1", deck_id_val, deck?.title)
            } else {
                const data = await getHeroID();
                deck_id_val = data?.data?.getHero?.deckID;
                const multigenresResponse = await getDeckById(deck_id_val);
                setDeckId(deck_id_val);
                //setIdToSearch(multigenresResponse?.data?.getDeck?.id);
                setCurrentDeckCards(multigenresResponse?.data?.getDeck?.cards?.items);
                console.log("loadDeckId 2", deck_id_val);
            }
        } catch (err: any) {
            console.log(err, "err");
            //setIdToSearch(err?.data?.getDeck?.id);
            setDeckId(err?.data?.getDeck?.id);
            setCurrentDeckCards(err?.data?.getDeck?.cards?.items);
            // throw err;
        } finally {
            setIsLoading(false);
            const likedDeck: LikedDeck = {
                deckId: deck_id_val,
                title: title,
                version_id: "1.0"
            }
            likedStorage?.updatelkDeck(likedDeck);
        }
    };

    const genres = ['Pop', 'Rock', 'Classical', 'Hip Hop', 'Jazz', 'Blues', 'Country', 'Reggae'];
    const languages = ['English', 'Spanish', 'Hindi', 'French', 'German', 'Arabic', 'Urdu', 'Punjabi'];
    const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
    
    const updateSelectedGenres = (genres: string[]) => {
        setSelectedGenres(genres);
    };
    
    const updateSelectedLanguages = (languages: string[]) => {
        setSelectedLanguages(languages);
    };


    // const handleSetDeckId = (id: any) => {
    //     setIdToSearch(id);
    //     setDeckId({ [id]: deckId?.[id] ? false : true });
    //     let uid = userId ? userId : "0";
    //     let appAnalytics = new AppAnalytics();
    //     appAnalytics.deck_click_event(id, uid.toString(), "");
    // };



    const setRecos2Values = (recos2i: any) => {
        setRecos2(recos2i);
    }


    //eventually deprecate handleSetDeckId method
    const handleSetDeck = (id: any, version_id: string, title: string) => {
        //setIdToSearch(id);
        if( id )
        {
            loadDeckId(id);
            deck_version = version_id;
            setTitle(title);

            let uid = userId ? userId : "0";
            let appAnalytics = new AppAnalytics();
            appAnalytics.deck_click_event(id, uid.toString(), "");
        }
        else {
            console.log ("attempt to set null deck id")
        }
    }

    /** Used for refreshing likes page when new values are written to likes */
    const handleLikesUpdated = () => {
        setLikesUpdated(likesUpdated + 1);
    }
    const handleActiveTab = (tab: string) => {
        setActiveTab(tab);
    };

    const handleSetAutoPlay = (enableAutoPlay: boolean) => {
        setIsAutoPlayEnabled(enableAutoPlay);
        console.log("setting auto play in authcontext", enableAutoPlay)
    };

    const resetDeleteCount = () => {
        setDeleteCount(0);
    };

    const handleSetTitle = (newTitle: string) => {
        setTitle(newTitle);
        setDeckTitle(newTitle);
    };

    return (
        <AuthContext.Provider
            value={{
                currentDeckCards,
                title,
                deckId,
                deck_version,
                userId,
                idToSearch,
                isLoading,
                activeTab,
                isMounted,
                deleteCount,
                handleSetTitle,
                //handleSetDeckId,
                handleActiveTab,
                handleSetAutoPlay,
                isAutoPlayEnabled,
                login: handleLogin,
                resetDeleteCount,
                logout: handleLogOut,
                isOnboardingCompleted,
                handleOnboardingCompleted,
                handleSetDeck,
                likedStorage,
                likesUpdated,
                handleLikesUpdated,
                exportDeckId,
                setExportDeckId,
                userEmail,
                userName,
                userPhone,
                userGender,
                userLocation,
                userProfilePic,
                userBDay,
                updateUserProfile,
                genres,
                languages,
                selectedGenres,
                selectedLanguages,
                setRecos2Values,
                recos2,
                setIsAutoPlayEnabled,
                numOfLikedSongs,
                numOfSongsAddedToSpotify,
                numOfSharedPlaylists,
                numOfLikedDecks,
                numOfCreatedPlaylists,
                incrementLikedSongs,
                incrementSharedPlaylists,
                incrementLikedDecks,
                incrementCreatedPlaylists,
                incrementLikedSpotifySongs,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
