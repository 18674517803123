import {
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router";
import { search, personCircleOutline, homeOutline, libraryOutline } from "ionicons/icons";
import Dashboard from "../pages/Dashboard/Dashboard";
import Search from "../pages/Dashboard/Search/Search";
import Profile from "../pages/Dashboard/Profile/Profile";
import EditProfile from "../pages/Dashboard/Profile/EditProfile";
import LikedSongs from "../pages/Dashboard/Profile/LikedSongs/LikedSongs";
import FavoriteDecks from "../pages/Dashboard/Profile/FavoriteDecks/FavoriteDecks";
import { AuthContext } from "../context/AuthContext";
import Likes from "../pages/LikesShares/Likes";
import home from "../../src/assets/icon/home.svg";
import profile from "../../src/assets/icon/profile.svg";
import library from "../../src/assets/icon/library.svg";
import SplashScreen from "../pages/Dashboard/SplashScreen";
import SpotifyPlaylistSelectionPage from "../pages/LikesShares/SpotifyPlaylistSelectionPage";

import "./styles.scss";

const TabRoutes: React.FC = () => {
  const context = useContext(AuthContext);
  const location = useLocation();
  const initialTab = location.pathname.split('/')[2] || 'home'; // workaround to ensure purple line under icon is displayed on correct button after page refresh
  const [activeTab, setActiveTab] = useState(initialTab);
  const [isSplashScreenActive, setIsSplashScreenActive] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (context && context.recos2.length > 0) {
      // If the recos2 is already available, hide the splash screen immediately
      setIsSplashScreenActive(false);
    }
    else {
      // If the recos2 is not available, keep the splash screen active
      // for 15 seconds - after which an error should be shown
      const timer = setTimeout(() => {
        setIsSplashScreenActive(false);
      }, 12000);
  
      return () => {
        // Clear the timer if the component is unmounted before the timeout
        clearTimeout(timer);
      };
    }
    // Redirect to the onboarding page if not yet completed
    if (!context?.isOnboardingCompleted && location.pathname !== '/onboarding') {
      history.push('/onboarding');
    }
    const currentTab = location.pathname.split('/')[2] || 'home';
    setActiveTab(currentTab);
  }, [context,location,history]); // Update when context.recos2 changes
  // Show the splash screen initially
  if (isSplashScreenActive) {
    return <Route component={SplashScreen} />;
  }

  // If the splash screen is not active and onboarding is not completed, redirect to the onboarding page
  if (!context?.isOnboardingCompleted) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <div className="app-root">
      {!isSplashScreenActive ? (
        <IonTabs className="custom-ion-content">
          <IonRouterOutlet>
            <Redirect exact path="/dashboard" to="/dashboard/home" />
            <Route exact path="/dashboard/home" component={Dashboard} />
            <Route exact path="/dashboard/search" component={Search} />
            <Route exact path="/dashboard/library" component={Likes} />
            <Route exact path="/dashboard/likedSongs" component={LikedSongs} />
            <Route exact path="/dashboard/profile" component={Profile} />
            <Route exact path="/dashboard/editProfile" component={EditProfile} />
            <Route exact path="/dashboard/deck/:id" component={Dashboard} />
            <Route
              exact
              path="/dashboard/favorited-decks"
              component={FavoriteDecks}
            />
          </IonRouterOutlet>
            <IonTabBar
              onIonTabsDidChange={({ detail: { tab } }) => {
                context?.handleActiveTab(tab);
                context?.resetDeleteCount();
                setActiveTab(tab);
              }}
              slot="bottom"
              className="tabs"
            >
              <IonTabButton tab="profile" href="/dashboard/profile">
                <IonIcon icon={profile} />
                {activeTab.toLowerCase().includes("profile") && <div className="active-tab-line"></div>}
              </IonTabButton>
              <IonTabButton tab="home" href="/dashboard/home">
                <IonIcon icon={home} />
                {activeTab === "home" && <div className="active-tab-line"></div>}
              </IonTabButton>
              <IonTabButton tab="library" href="/dashboard/library">
                <IonIcon icon={library} />
                {activeTab === "library" && <div className="active-tab-line"></div>}
              </IonTabButton>
            </IonTabBar>
        </IonTabs>
      ) : (
        <Route component={SplashScreen} />
      )}
    </div>
  );
};

export default TabRoutes;
