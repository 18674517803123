import { IonButton, IonIcon, IonImg } from "@ionic/react";
import { useContext, useState } from "react";
import { Share } from "@capacitor/share";
import { ENV } from '../../env/env';

import AddSongToDeck from "../Modal/AddSongToDeck/AddSongToDeck";
import { addLikedSongApi } from "../../services/deck_loader";
import { SpotifyContext } from "../../context/SpotifyContext";
import cancelIcon from "../../assets/icon/cancel.svg";
import rewindIcon from "../../assets/icon/settings_backup_restore.png";
import pauseIcon from "../../assets/icon/pause.svg";
import playIcon from "../../assets/icon/playIcon.svg";
import shareIcon from "../../assets/icon/ios_share.svg";
import heartIcon from "../../assets/icon/heart.svg";
import blueHeartIcon from "../../assets/icon/blueHeart.svg";

interface ComponentProps {
    data: { songName: string; imageUrl: string; id: number; deckId?: number; sp_id: string };
    isPlaying: boolean;
    setPlaying: (value: boolean) => void;
    handleDeleteCard: () => void;
    handleMinusSeconds: () => void;
    audioId: string;
}

interface ButtonComponentProps {
    onClick: () => void;
}

const CardControls = ({
    data,
    isPlaying,
    setPlaying,
    handleDeleteCard,
    handleMinusSeconds,
    audioId,
}: ComponentProps) => {
    const [isAddtoDeck, setIsAddToDeck] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const spContext = useContext(SpotifyContext)

    const handleShare = async () => {
        try {
            await Share.share({
                url: `${ENV.REACT_APP_URL}/dashboard/home/?songId=${data?.id}`,
            });
        } catch (err) {
            throw err;
        }
    };

    const handlePlay = (event:any) => {
        const audio: HTMLAudioElement = document?.getElementById(
            audioId
          ) as HTMLAudioElement;
        audio.play();
        setPlaying(true);
    }
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
            }}
        >
            <IonButton
                onClick={handleDeleteCard}
                expand="block"
                color={"white"}
                fill="clear"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="delete-card-clicked"
                data-amplify-analytics-attrs="cardid:${data?.id},deckid:todo,userid:todo"
            >
                <IonImg
                    className="btn-icon"
                    src={cancelIcon}
                    alt="deleteIcon"
                    style={{ width: 20, height: 20 }}
                />
            </IonButton>
            <IonButton
                onClick={handleMinusSeconds}
                expand="block"
                color={"white"}
                fill="clear"
            >
                <IonImg
                    className="btn-icon"
                    src={rewindIcon}
                    alt="subtract_10_seconds"
                    style={{ width: 20, height: 20 }}
                />
            </IonButton>
            {isPlaying ? (
                <IonButton
                    onClick={() => setPlaying(false)}
                    color={"white"}
                    fill={"clear"}
                >
                    <IonImg
                        className="btn-icon"
                        src={pauseIcon}
                        alt="pause"
                        style={{ width: "100%", height: "100%",  minWidth: 36 }}
                    />
                </IonButton>
            ) : (
                <IonButton
                    onClick={handlePlay}
                    color={"white"}
                    fill={"clear"}
                >
                    <IonImg
                        className="btn-icon"
                        src={playIcon}
                        alt="play"
                        style={{ width: "100%", height: "100%", minWidth: 36 }}
                    />
                </IonButton>
            )}
            <IonButton
                onClick={handleShare}
                expand="block"
                color={"white"}
                fill="clear"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="share-clicked"
                data-amplify-analytics-attrs="cardid:${data?.id},deckid:todo,userid:todo"
            >
                <IonImg
                    className="btn-icon"
                    src={shareIcon}
                    alt="share"
                    style={{ width: 20, height: 20 }}
                />
            </IonButton>
            <IonButton
                // onClick={() => setIsAddToDeck(!isAddtoDeck)}
                onClick={async () => {
                    setIsLiked(true);
                    spContext?.addToSavedTracks([data.sp_id])
                    const savedDeck = await addLikedSongApi(null, data.id);

                    console.log({ savedDeck });
                    console.log(isLiked)
                }}
                expand="block"
                color={"white"}
                fill="clear"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="add2deck-clicked"
                data-amplify-analytics-attrs="cardid:${data?.id},deckid:todo,userid:todo"
            >
                {isLiked ? (
                    <IonIcon
                        src={blueHeartIcon}
                        className="btn-icon"
                        style={{
                            width: 20,
                            height: 20,
                        }}
                    />
                ) : (
                    <IonImg
                        src={heartIcon}
                        alt="heart"
                        className="btn-icon"
                        style={{
                            width: 20,
                            height: 20,
                        }}
                    />
                )}
            </IonButton>
            <AddSongToDeck
                isOpen={isAddtoDeck}
                onDismiss={() => {
                    setIsAddToDeck(!isAddtoDeck);
                }}
                handleSetLiked={() => setIsLiked(true)}
                data={data}
            />
        </div>
    );
};

export default CardControls;
