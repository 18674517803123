import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonImg,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonRippleEffect,
} from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";

const SwipeLeft: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-align-items-center ion-margin-vertical ion-margin-top-medium">
            <IonCol size="12" className="ion-text-center">
              <IonText className="ion-sub-heading ion-font-small">
                Gestures
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <IonCol size="12" className="ion-text-center ion-margin-top-small">
              <IonText color={"light"} className="ion-heading ion-font-medium">
                Swipe Left
              </IonText>
              <IonImg src={"assets/swipeLeft.svg"} />
            </IonCol>
            <IonCol size={"12"} className="ion-text-center">
              <IonIcon
                size={"20"}
                src="assets/icon/delete.svg"
                className="icon-medium"
              />
            </IonCol>
            <IonCol
              size={"12"}
              className="ion-text-center ion-margin-top-small"
            >
              <IonText
                color={"light"}
                className="ion-heading ion-font-extra-large"
              >
                Discard
              </IonText>
            </IonCol>
            <IonCol
              size={"12"}
              className="ion-margin-top-medium ion-padding-horizontal-medium"
            >
              <IonText color={"light"} className="ion-para">
                User swipes left to remove the track from the deck and move on
                to the next track
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <Link to={"/swipe-up"} className="link">
          <IonButton color={"back"} className="ion-activatable" fill="clear">
            Next
            <IonRippleEffect type="unbounded"></IonRippleEffect>
          </IonButton>
        </Link>
      </IonContent>
    </IonPage>
  );
};

export default SwipeLeft;
