/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const putSwipeLeft = /* GraphQL */ `
  mutation PutSwipeLeft($userID: Int, $trackID: Int, $deckID: Int) {
    putSwipeLeft(userID: $userID, trackID: $trackID, deckID: $deckID)
  }
`;
export const putSwipeRight = /* GraphQL */ `
  mutation PutSwipeRight($userID: Int, $trackID: Int, $deckID: Int) {
    putSwipeRight(userID: $userID, trackID: $trackID, deckID: $deckID)
  }
`;

export const swipeLeft = /* GraphQL */ `
  mutation SwipeLeft($userID: Int, $trackID: Int, $deckID: Int) {
    swipeLeft(userID: $userID, trackID: $trackID, deckID: $deckID)
  }
`;
export const swipeRight = /* GraphQL */ `
  mutation SwipeRight($userID: Int, $trackID: Int, $deckID: Int) {
    swipeRight(userID: $userID, trackID: $trackID, deckID: $deckID)
  }
`;
export const addLikedSong = /* GraphQL */ `
  mutation AddLikedSong($userID: Int, $trackID: Int) {
    addLikedSong(userID: $userID, trackID: $trackID)
  }
`;
export const addFavoriteDeck = /* GraphQL */ `
  mutation AddFavoriteDeck($userID: Int, $deckID: Int) {
    addFavoriteDeck(userID: $userID, deckID: $deckID)
  }
`;
export const createCard = /* GraphQL */ `
  mutation CreateCard(
    $input: CreateCardInput!
    $condition: ModelCardConditionInput
  ) {
    createCard(input: $input, condition: $condition) {
      id
      albumgenres
      albumhref
      albumid
      albumlabel
      albumname
      albumpopularity
      albumtype
      coverart_a
      coverart_b
      coverart_c
      explicit
      external_urls
      genres
      jsonartists
      min_date
      popularity
      sartists
      totaltracks
      title
      spotifyid
      # trackurl
      snippeturl
      uri
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard(
    $input: UpdateCardInput!
    $condition: ModelCardConditionInput
  ) {
    updateCard(input: $input, condition: $condition) {
      id
      albumgenres
      albumhref
      albumid
      albumlabel
      albumname
      albumpopularity
      albumtype
      coverart_a
      coverart_b
      coverart_c
      explicit
      external_urls
      genres
      jsonartists
      min_date
      popularity
      sartists
      totaltracks
      title
      spotifyid
      # trackurl
      snippeturl
      uri
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard(
    $input: DeleteCardInput!
    $condition: ModelCardConditionInput
  ) {
    deleteCard(input: $input, condition: $condition) {
      id
      albumgenres
      albumhref
      albumid
      albumlabel
      albumname
      albumpopularity
      albumtype
      coverart_a
      coverart_b
      coverart_c
      explicit
      external_urls
      genres
      jsonartists
      min_date
      popularity
      sartists
      totaltracks
      title
      spotifyid
      # trackurl
      snippeturl
      uri
    }
  }
`;
export const createDeck = /* GraphQL */ `
  mutation CreateDeck(
    $input: CreateDeckInput!
    $condition: ModelDeckConditionInput
  ) {
    createDeck(input: $input, condition: $condition) {
      id
      title
      dtitle
      dkType
      genre
      src
      creatorID
    }
  }
`;
export const updateDeck = /* GraphQL */ `
  mutation UpdateDeck(
    $input: UpdateDeckInput!
    $condition: ModelDeckConditionInput
  ) {
    updateDeck(input: $input, condition: $condition) {
      id
      title
      dtitle
      dkType
      genre
      src
      creatorID
    }
  }
`;
export const deleteDeck = /* GraphQL */ `
  mutation DeleteDeck(
    $input: DeleteDeckInput!
    $condition: ModelDeckConditionInput
  ) {
    deleteDeck(input: $input, condition: $condition) {
      id
      title
      dtitle
      dkType
      genre
      src
      creatorID
    }
  }
`;
export const createAdjacencyGroup = /* GraphQL */ `
  mutation CreateAdjacencyGroup(
    $input: CreateAdjacencyGroupInput!
    $condition: ModelAdjacencyGroupConditionInput
  ) {
    createAdjacencyGroup(input: $input, condition: $condition) {
      id
      title
      source
      decks {
        id
        title
        dtitle
        genre
        src
        creatorID
        cards {
          items {
            id
            cardID
            deckID
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAdjacencyGroup = /* GraphQL */ `
  mutation UpdateAdjacencyGroup(
    $input: UpdateAdjacencyGroupInput!
    $condition: ModelAdjacencyGroupConditionInput
  ) {
    updateAdjacencyGroup(input: $input, condition: $condition) {
      id
      title
      source
      decks {
        id
        title
        dtitle
        genre
        src
        creatorID
        
        cards {
          items {
            id
            cardID
            deckID
            
            
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteAdjacencyGroup = /* GraphQL */ `
  mutation DeleteAdjacencyGroup(
    $input: DeleteAdjacencyGroupInput!
    $condition: ModelAdjacencyGroupConditionInput
  ) {
    deleteAdjacencyGroup(input: $input, condition: $condition) {
      id
      title
      source
      decks {
        id
        title
        dtitle
        genre
        src
        creatorID
        
        cards {
          items {
            id
            cardID
            deckID
            
            
          }
          nextToken
        }
      }
    }
  }
`;
export const createUserTracks = /* GraphQL */ `
  mutation CreateUserTracks(
    $input: CreateUserTracksInput!
    $condition: ModelUserTracksConditionInput
  ) {
    createUserTracks(input: $input, condition: $condition) {
      userID
      trackID
      liked
      source
    }
  }
`;
export const updateUserTracks = /* GraphQL */ `
  mutation UpdateUserTracks(
    $input: UpdateUserTracksInput!
    $condition: ModelUserTracksConditionInput
  ) {
    updateUserTracks(input: $input, condition: $condition) {
      userID
      trackID
      liked
      source
    }
  }
`;
export const deleteUserTracks = /* GraphQL */ `
  mutation DeleteUserTracks(
    $input: DeleteUserTracksInput!
    $condition: ModelUserTracksConditionInput
  ) {
    deleteUserTracks(input: $input, condition: $condition) {
      userID
      trackID
      liked
      source
    }
  }
`;
export const createDeckHistory = /* GraphQL */ `
  mutation CreateDeckHistory(
    $input: CreateDeckHistoryInput!
    $condition: ModelDeckHistoryConditionInput
  ) {
    createDeckHistory(input: $input, condition: $condition) {
      userID
      deckID
      liked
      songsHeard
    }
  }
`;
export const updateDeckHistory = /* GraphQL */ `
  mutation UpdateDeckHistory(
    $input: UpdateDeckHistoryInput!
    $condition: ModelDeckHistoryConditionInput
  ) {
    updateDeckHistory(input: $input, condition: $condition) {
      userID
      deckID
      liked
      songsHeard
    }
  }
`;
export const deleteDeckHistory = /* GraphQL */ `
  mutation DeleteDeckHistory(
    $input: DeleteDeckHistoryInput!
    $condition: ModelDeckHistoryConditionInput
  ) {
    deleteDeckHistory(input: $input, condition: $condition) {
      userID
      deckID
      liked
      songsHeard
    }
  }
`;
export const createDeckCard = /* GraphQL */ `
  mutation CreateDeckCard(
    $input: CreateDeckCardInput!
    $condition: ModelDeckCardConditionInput
  ) {
    createDeckCard(input: $input, condition: $condition) {
      id
      cardID
      deckID
    }
  }
`;
export const updateDeckCard = /* GraphQL */ `
  mutation UpdateDeckCard(
    $input: UpdateDeckCardInput!
    $condition: ModelDeckCardConditionInput
  ) {
    updateDeckCard(input: $input, condition: $condition) {
      id
      cardID
      deckID
    }
  }
`;
export const deleteDeckCard = /* GraphQL */ `
  mutation DeleteDeckCard(
    $input: DeleteDeckCardInput!
    $condition: ModelDeckCardConditionInput
  ) {
    deleteDeckCard(input: $input, condition: $condition) {
      id
      cardID
      deckID
    }
  }
`;
