import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const HappyListening: React.FC = () => {
  const context = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      context?.handleOnboardingCompleted();
      history.push("/dashboard");
    }, 3000);
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-text-center">
            <IonCol size="12">
              <div
                style={{
                  marginTop: 40,
                }}
              >
                <IonText
                  color={"light"}
                  className="ion-font-small ion-margin-top-medium"
                >
                  Happy Listening
                </IonText>
              </div>
            </IonCol>
            <IonCol size="8">
              <div
                style={{
                  marginTop: 200,
                }}
              >
                <IonImg src={"assets/logo.png"} />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default HappyListening;
