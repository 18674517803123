import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonLoading,
  IonItemDivider,
  IonPage,
  IonRippleEffect,
  IonText,
  useIonRouter,
  useIonViewWillLeave,
} from "@ionic/react";

import "./styles.css";
import Deck from "../../../../components/Deck/Deck";
import { getAllFavouritesDeck } from "../../../../services/deck_loader";
import CardStack from "../../../../components/CardStack/CardStack";
import HeroDeck from "../../../../components/CardStack/CardStack";
import { AuthContext } from "../../../../context/AuthContext";
import SwiperCards from "../../../../components/SwiperCards/SwiperCards";

const FavoriteDecks: React.FC = () => {
  const router = useIonRouter();

  const context = useContext(AuthContext);

  const [showMiniPlayer, setShowMiniPlayer] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(null);
  const [favoriteDecks, setFavoriteDecks] = useState<any>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        let userId = context?.userId ? parseInt(context?.userId) : 0
        await getAllFavouritesDeck(userId);
      } catch (err: any) {
        const favoritedDecksArray = [];

        for (
          let index = 0;
          index < err?.data?.getFavoriteDecks?.length;
          index += 2
        ) {
          favoritedDecksArray?.push(
            err?.data?.getFavoriteDecks.slice(index, index + 2)
          );
        }

        setFavoriteDecks(favoritedDecksArray);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // useIonViewWillLeave(() => {
  //   context?.handleSetDeckId("2653390031");
  //   context?.resetDeleteCount();
  //   context?.handleSetAutoPlay(false);
  // }, []);

  return (
    <IonPage className="app-root">
      <IonContent>
        <IonItemDivider />
        {isLoading ? (
          <IonLoading isOpen={true} />
        ) : (
          <div className="container">
            <div className="header">
              <IonButton
                color={"white"}
                className="ion-activatable ripple-parent"
                fill="clear"
                onClick={() => {
                  router.goBack();
                }}
              >
                <IonIcon src="assets/icon/backArrow.svg" />
                <IonRippleEffect></IonRippleEffect>
              </IonButton>
              <IonButton
                color={"white"}
                className="ion-activatable ripple-parent"
                fill="clear"
              >
                <IonIcon src="assets/icon/more.svg" />
                <IonRippleEffect></IonRippleEffect>
              </IonButton>
            </div>
            <IonItemDivider />
            <IonText color={"light"} className="ion-font-large">
              Favorited Decks
            </IonText>
            <IonText color={"light"}>
              <p
                style={{
                  margin: "5px 0 0 0",
                  padding: 0,
                  fontSize: "12px",
                  color: "#94A1B2",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                eros dictumst in libero netus tellus quis placerat. In massa
                faucibus nibh dui ac.
              </p>
            </IonText>
            <IonItemDivider />
            <Deck
              data={favoriteDecks}
              handleShowMiniPlayer={() => {
                setShowMiniPlayer(true);
              }}
              selectedColor="white"
            />
            {showMiniPlayer && (
              <div
                className="cardScroll"
                style={{
                  position: "absolute",
                  bottom: 40,
                  left: 10,
                  right: 10,
                }}
              >
                {/* <CardStack inView={true} enableAutoPlay={true} /> */}
                <SwiperCards data = "" />
              </div>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default FavoriteDecks;
