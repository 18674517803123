export const decks = [
  {
    id: "one",
    gridArea: "one",
    backgroundColor: "",
  },
  {
    id: "two",
    gridArea: "two",
    backgroundColor: "#7F5AF0",
  },
  {
    id: "three",
    gridArea: "three",
    backgroundColor: "",
  },
  {
    id: "four",
    gridArea: "four",
    backgroundColor: "",
  },
  {
    id: "five",
    gridArea: "five",
    backgroundColor: "#F34721",
  },
  {
    id: "six",
    gridArea: "six",
    backgroundColor: "",
  },
  {
    id: "seven",
    gridArea: "seven",
    backgroundColor: "",
  },
  {
    id: "eight",
    gridArea: "eight",
    backgroundColor: "#2CB67D",
  },
];

export const favourites = [
  {
    id: 1,
    name: "Rock 1",
  },
  {
    id: 2,
    name: "Rock 2",
  },
  {
    id: 3,
    name: "Rock 3",
  },
  {
    id: 4,
    name: "Rock 4",
  },
  {
    id: 3,
    name: "Rock 3",
  },
  {
    id: 4,
    name: "Rock 4",
  },
];

export const recommendations = [
  {
    id: 1,
    name: "Top Hits",
  },
  {
    id: 2,
    name: "Rap",
  },
  {
    id: 3,
    name: "Indian Rock",
  },
  {
    id: 4,
    name: "Top Spanish Hits",
  },
  {
    id: 5,
    name: "Top Indian Hits",
  },
  {
    id: 2,
    name: "Rap",
  },
  {
    id: 3,
    name: "Indian Rock",
  },
  {
    id: 4,
    name: "Top Spanish Hits",
  },
  {
    id: 5,
    name: "Top Indian Hits",
  },
];
