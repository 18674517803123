import { useState, useMemo, createContext } from "react";
import { GetResult, Preferences } from "@capacitor/preferences";
import SpotifyWebApi from "spotify-web-api-js";

interface SpotifyContextInterface {
  setAccessToken: (token: string | null) => void;
  getAccessToken: () => Promise<GetResult>;
  getUserProfile: () => Promise<SpotifyApi.CurrentUsersProfileResponse>;
  addToSavedTracks: (trackIds: Array<string> | null) => Promise<SpotifyApi.SaveTracksForUserResponse | null>;
  getUserPlaylists: () => Promise<SpotifyApi.ListOfCurrentUsersPlaylistsResponse>;
  getPlaylistTracks: (playlistId: string) => Promise<SpotifyApi.PlaylistTrackResponse>;
  addTracksToPlaylist: (playlistId: string, trackUris: Array<string>) => Promise<SpotifyApi.AddTracksToPlaylistResponse>;
  createPlaylist: (name: string) => Promise<SpotifyApi.PlaylistObjectFull>;
  searchTracks: (title: string, artist: string) => Promise<string | null>;
  logout: () => Promise<void>;
  isLoggedOut: boolean; // Added isLoggedOut to the interface
}

export const SpotifyContext = createContext<SpotifyContextInterface | null>(null);

export const SpotifyProvider: React.FC = ({ children }) => {
  const spotifyApi = useMemo(() => new SpotifyWebApi(), []);
  const [isLoggedOut, setIsLoggedOut] = useState(true);

  const getUserProfile = async () => {
    const response = await spotifyApi.getMe();
    console.log("profile: ", response);
    return response;
  }
  const createPlaylist = async (name: string, publicPlaylist = false) => {
    const userIdResponse = await spotifyApi.getMe();
    const userId = userIdResponse.id;
    const response = await spotifyApi.createPlaylist(userId, { name, public: publicPlaylist });
    return response;
  }
  const logout = async () => {
    await setAccessToken(null);
    spotifyApi.setAccessToken(''); // Clear the token in the API client as well
    setIsLoggedOut(true); // Set isLoggedOut to true when user logs out
    
  }
  

  const addToSavedTracks = async (trackIds: Array<string> | null) => {
    try {
      if (trackIds?.length && trackIds.length > 0) {
        const response = await spotifyApi.addToMySavedTracks(trackIds);
        return response;
      } else {
        return null;
      }
    } catch (error) {
      // Handle the exception here
      console.error('sk: Error adding tracks to spotify tracks:', error);
      return null;
    }
  }
  const getPlaylistTracks = async (playlistId: string) => {
    const response = await spotifyApi.getPlaylistTracks(playlistId);
    return response;
  }
  const searchTracks = async (title: string, artist: string): Promise<string | null> => {
    try {
      const response = await spotifyApi.searchTracks(`track:${title} artist:${artist}`);
      const trackItem = response.tracks.items[0];
      return trackItem ? trackItem.uri : null;
    } catch (error) {
      console.error(`Error searching for track: ${error}`);
      return null;
    }
  }
  

  const setAccessToken = async (token: string | null) => {
    if (token) {
      spotifyApi.setAccessToken(token);
      await Preferences.set({ key: "spotifyToken", value: token });
      setIsLoggedOut(false); // User has logged back in
    } else {
      Preferences.remove({ key: "spotifyToken"});
    }
  }


  const getAccessToken = async () => {
    const token: GetResult = await Preferences.get({ key: "spotifyToken" });
    console.log("token: ", token);
    return token;
  }

  const getUserPlaylists = async () => {
    const response = await spotifyApi.getUserPlaylists();
    return response;
  }

  const addTracksToPlaylist = async (playlistId: string, trackUris: Array<string>) => {
    const response = await spotifyApi.addTracksToPlaylist(playlistId, trackUris);
    return response;
  }

  return (
    <SpotifyContext.Provider
      value={{
        setAccessToken,
        getAccessToken,
        getUserProfile,
        addToSavedTracks,
        getUserPlaylists,
        addTracksToPlaylist,
        createPlaylist,
        getPlaylistTracks,
        searchTracks,
        logout,
        isLoggedOut,
      }}
    >
      {children}
    </SpotifyContext.Provider>
  )
}
