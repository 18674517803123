import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import React, { useEffect } from "react";
import { decks } from "../../utility/constants";

const DeckLook: React.FC = () => {
  const router = useIonRouter();

  useEffect(() => {
    setTimeout(() => {
      router.push("/happy-listening");
    }, 3000);
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol
              size={"12"}
              className="ion-text-center ion-margin-top-medium ion-margin-bottom-medium"
            >
              <div className="deck-look-header">
                <IonText color={"light"}>Let's make your first deck</IonText>
                <IonText color={"light"}>Pick moods</IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="deck-grid">
          {decks.map(({ id, gridArea, backgroundColor }) => (
            <div
              key={id}
              className="deck-grid-item"
              style={{ gridArea, backgroundColor }}
            />
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DeckLook;
