import React, { useState, useContext, useEffect } from "react";
import { IonContent, IonButton, IonLabel, IonPage, IonIcon, useIonRouter } from "@ionic/react";
import { radioButtonOn, radioButtonOff, chevronForwardOutline } from "ionicons/icons";
import { AuthContext } from "../../context/AuthContext";
import swipeDemo from "../../assets/swipe_demo.svg";
import connectSpotify from "../../assets/connect_spotify.svg";
import addToSpotify from "../../assets/add_to_spotify.svg";
import "./onboarding.css"
import profileLogo from "../../assets/icon/profile.svg"

interface CarouselProps {
  onGetStarted: () => void;
}

const OnboardingPage: React.FC<CarouselProps> = () => {
  const context = useContext(AuthContext);
  const router = useIonRouter();

  const handleGetStarted = () => {
    context?.handleOnboardingCompleted();
    router.push('/dashboard/home', "forward", "replace");
  };
  
  const videos = [swipeDemo, connectSpotify,addToSpotify];
  const titles = ["Swipe left or right on the top card to navigate the playlist", 
                  "Go to profile > edit profile > connect spotify", 
                  "Add liked songs directly to Spotify"];
  const subTitles = ["press on the heart button to like the song", 
                  "Its an easy way to earn fan points and helps us recommend songs for you", 
                  "Add songs you discover on Glimpz to your preffered Spotify playlist or create a new playlist on Spotify from our App"];

  const [slideIndex, setSlideIndex] = useState(0);

  const handleNext = () => {
    if (slideIndex < videos.length - 1) {
      setSlideIndex(prevIndex => prevIndex + 1);
    }
  };

  return (
    <IonPage className="app-root">
      <IonContent scrollEvents={true}
                scrollY={true}
                className="custom-ion-content"
                >
        <div className="onboarding-page-container">
          <div className="img-container">
            <img src={videos[slideIndex]} alt="place holder"/>
          </div>
          <div className="onboarding-info-container">
              <div className="radio-button-container">
                  {videos.map((video, index) => (
                      <IonIcon
                          key={index}
                          icon={index <= slideIndex ? radioButtonOn : radioButtonOff}
                          style={{
                              color: index <= slideIndex ? '#7F5AEF' : '#7E7E7E',
                              margin: '0 0.5em 2em 0.5em'
                          }}
                      />
                  ))}
              </div>
              {slideIndex === 1 ? (
                  <div className="connect-spotify-container">
                      <IonLabel style={{color:"#fff", margin:"0 1em", fontSize:"1.2em"}}>Connect your Spotify Account</IonLabel>
                      <div style={{ padding: "0 0 1em 0", color:"white", display: "flex", alignItems: "center",fontSize:"1em" }}>
                          <IonIcon icon={profileLogo} />
                          <IonIcon style={{ margin: "0 0.5em" }} icon={chevronForwardOutline} />
                          <span style={{color:"#F46E6E"}}>Edit Profile</span>
                          <IonIcon style={{ margin: "0 0.5em" }} icon={chevronForwardOutline} />
                          <span style={{color:"#1DB954"}}>Connect Spotify</span>
                      </div>
                  </div>
              ) : (
                  <IonLabel style={{textAlign:"center", fontSize:"1.2em"}}>{titles[slideIndex]}</IonLabel>
              )}
              {/*<IonLabel className="subtitles" style={{textAlign:"center", color:"111"}}>{subTitles[slideIndex]}</IonLabel>*/}
          </div>
          
          <div className="next-button">
            {slideIndex === videos.length - 1 ? (
              <IonButton style={{fontSize:"1.2em",width:"100%"}} onClick={handleGetStarted}>Get Started</IonButton>
            ) : (
              <IonButton style={{fontSize:"1.2em",width:"100%"}} onClick={handleNext}>Next</IonButton>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OnboardingPage;
