/**
 * Utility class to record app events for batch processing. The events are sent over kinesis data stream
 * to kinesis firehose to S3.
 * 
 * How to use this utility. 
 * 
 * import {AppAnalytics} from "../../utility/app-analytics"
 * 
 * 
 * let appAnalytics = new AppAnalytics();
   appAnalytics.card_swipe_left_event(1,2,3,4);

   To add new events, 
        * use typ attribute to capture the object of the event. Some typs are defined as constants already
        * define a function prefixed with object name (card_swipe_left_event).
 * 
 */

   
import { Analytics } from "aws-amplify";

const TYP_CARD  = "Card"
const TYP_USER  = "User"
const TYP_DECK  = "Deck"

const TYP_CLICK = 0
const TYP_TAP   = 1
const TYP_DOUBLETAP = 2

export class AppAnalytics {

    post_kinesis_event(eventdata:any, pkey:any)
    {
        const KinesisProvider = Analytics.getPluggable('AWSKinesis'); 
        KinesisProvider.record({
            event:{
            data: eventdata,
            partitionKey: pkey, 
            streamName: 'glimpzKin-dev' // Please refer "kinesisStreamId" output of the amplify-meta json for the correct stream name created by Amplify. 'amplify/backend/amplify-meta.json'
            },
            provider:"AWSKinesis"
        });
    }

    create_event_stub()
    {
        const jsonstr = '{"typ":"", "deckid":"", "cardid":"", "userid":"", "ev":"", "info":"", "client_ts":""}';
        var jsonObj = JSON.parse(jsonstr);
        jsonObj.client_ts = Date.now();

        return jsonObj;
    }

    /**
     * {'typ':'music', 'cardid':cardid, 'deckid':deckid, userid: uid, 'ev':'skip', 'info':'', client_ts:ts  }
     * info: How long did the song play before it was skipped. Pass empty string otherwise
     */
    card_swipe_left_event(deckid:number, cardid:number, uid:string, info:string)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_CARD; eventJson.ev = 'skip'; eventJson.info = info;
        eventJson.cardid = cardid; eventJson.deckid = deckid; eventJson.userid = uid;

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }

    card_skipx_event(deckid:number, cardid:number, uid:string, info:string)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_CARD; eventJson.ev = 'skipx'; eventJson.info = info;
        eventJson.cardid = cardid; eventJson.deckid = deckid; eventJson.userid = uid;

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }
    /**
     * {'typ':'music', 'cardid':cardid, 'deckid':deckid, userid: uid, 'ev':'like', 'info':'', client_ts:ts  }
     */
    card_swipe_right_event(deckid:Number, cardid:Number, uid:string, info:string)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_CARD; eventJson.ev = 'like'; eventJson.info = info;
        eventJson.cardid = cardid; eventJson.deckid = deckid; eventJson.userid = uid;

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }

    /**
     * call this to record card share event. Use any to log contextual information such as where was it shared (email, whatsapp, insta, twitter etc.)
     *  */
    card_shared_event(deckid:Number, cardid:Number, uid:Number, info:any)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_CARD; eventJson.ev = 'shared'; eventJson.info = info;
        eventJson.cardid = cardid; eventJson.deckid = deckid; eventJson.userid = uid;

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }

     /**
      * Record event when a song is played full
     * {'typ':'music', 'cardid':cardid, 'deckid':deckid, userid: uid, 'ev':'playall', 'info':'', client_ts:ts  }
     */
     card_playfullsong_event(deckid:Number, cardid:Number, uid:Number)
     {
         var eventJson = this.create_event_stub();
         eventJson.typ = TYP_CARD; eventJson.ev = 'playall';
         eventJson.cardid = cardid; eventJson.deckid = deckid; eventJson.userid = uid;
 
         const eventstr = JSON.stringify(eventJson);
 
         this.post_kinesis_event(eventstr, eventJson.typ);
     }

     /**
     * call when a deck is clicked to play. Deck can be clicked inside the app, or through 
     * a push notification link, email link etc. use src to capture the source. 
     * Be consistent in marking the source.. for example, use src='app' for links inside app, src='pn' for push notification etc.
     * src
     */
    deck_click_event(deckid:Number, uid:string, src:string)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_DECK; eventJson.ev = 'click'; eventJson.info = src;
        eventJson.deckid = deckid; eventJson.userid = uid; 

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }

    /**
     * call when a deck starts playing. Is redundant to click, but retain it to track time lag between click and play begins.
     * src
     */
    deck_play_event(deckid:Number, uid:Number)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_DECK; eventJson.ev = 'play'; 
        eventJson.deckid = deckid; eventJson.userid = uid; 

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }

    /**
     * call when a card is liked, or favorited.
     * src
     */
    card_like_event(deckid:Number, cardid:Number, uid:Number, info:any)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_CARD; eventJson.ev = 'like'; 
        eventJson.deckid = deckid; eventJson.userid = uid; 
        eventJson.cardid = cardid;
        eventJson.info = info;

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }

    /**
     * call when a deck is liked, or favorited.
     * src
     */
    deck_like_event(deckid:Number, uid:Number)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_DECK; eventJson.ev = 'like'; 
        eventJson.deckid = deckid; eventJson.userid = uid; 

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }

    /**
     * call this to record card share event. Use any to log contextual information such as where was it shared (email, whatsapp, insta, twitter etc.)
     *  */
    deck_shared_event(deckid:Number, uid:Number, info:any)
    {
        var eventJson = this.create_event_stub();
        eventJson.typ = TYP_DECK; eventJson.ev = 'shared'; eventJson.info = info;
        eventJson.deckid = deckid; eventJson.userid = uid;

        const eventstr = JSON.stringify(eventJson);

        this.post_kinesis_event(eventstr, eventJson.typ);
    }
}
