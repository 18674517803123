import React, { useContext } from "react";
import { IonIcon, IonImg, IonText } from "@ionic/react";
import { Share } from "@capacitor/share";
import { AuthContext } from "../../context/AuthContext";
import spotifyLogo from "../../assets/spotify-icons-logos/icons/02_PNG/Spotify_Icon_RGB_White.png";
import playIcon from "../../assets/icon/playMini.svg";
import pauseIcon from "../../assets/icon/pauseMini.svg";
import shareIcon from "../../assets/icon/ios_share.svg";

export interface Song {
    song_id: string;
    songName: string;
    songArtist: string;
    albumname: string;
    album_id: string;
    inView?: boolean;
    handlePlaying: (playing: boolean) => void;
    isPlaying: boolean;
    imageUrl: string;
}

const Song: React.FC<Song> = ({
    song_id,
    albumname,
    album_id,
    inView,
    imageUrl,
    songName,
    songArtist,
    isPlaying,
    handlePlaying,
}) => {
    let showAlbumName = true
    const context = useContext(AuthContext);
    if(albumname == songName) {
        showAlbumName = false
    }
    const handleShare = async () => {
        try {
            await Share.share({
                url: `http://localhost:8100/dashboard/home/?songId=${context?.idToSearch}`,
            });
        } catch (err) {
            throw err;
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: inView ? "row" : "column",
                    justifyContent: inView ? "space-between" : "initial",
                    alignItems: inView ? "center" : "initial",
                }}
            >
                <div
                    style={{
                        display: inView ? "flex" : "initial",
                        width: inView ? "calc(100% - 65px)" : "100%",
                    }}
                >
                    {inView && (
                        <div
                            style={{
                                width: "70px",
                                maxHeight: "50px",
                            }}
                        >
                            <IonImg
                                src={imageUrl}
                                alt={songName}
                                style={{ width: "100%", height: "100%" }}
                            />
                            <IonImg
                                src={spotifyLogo}
                                alt="SpotifyLogo"
                                draggable={false}
                                style={{ width: "21px", height: "21px", position: "absolute", top: 16, backgroundColor: "black", borderRadius: 10 }}
                            />
                        </div>
                    )}
                    <div style={{ width: inView ? "calc(100% - 80px)" : "100%" }}>
                        <IonText color={"light"}>
                            <h2
                                style={{
                                    margin: inView ? "0" : "5px 0",
                                    fontWeight: "bold",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}
                            >
                                <span key={song_id}>
                                    <a href={`https://open.spotify.com/track/${song_id}`} 
                                        style={{ color: "inherit", textDecoration: "inherit" }}
                                        target="_blank" rel="noopener noreferrer">
                                        {songName}
                                    </a>
                                </span>
                            </h2>
                        </IonText>
                        <IonText color={"light"}>
                            <h4 style={{ margin: "5px 0", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                {JSON.parse(songArtist).map((artist: any, index: number) => (
                                    <span key={artist.artistID}>
                                        <a href={`https://open.spotify.com/artist/${artist.artistID}`} 
                                           style={{ color: "inherit", textDecoration: "inherit" }}
                                           target="_blank" rel="noopener noreferrer">
                                            {artist.artistName}
                                        </a>
                                        {index < JSON.parse(songArtist).length - 1 ? ", " : ""}
                                    </span>
                                ))}
                            </h4>


                        </IonText>
                    </div>
                </div>
                {inView && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {isPlaying ? (
                            <IonIcon
                                src={pauseIcon}
                                style={{ width: "30px", height: "30px", marginRight: "5px" }}
                                onClick={() => handlePlaying(false)}
                            />
                        ) : (
                            <IonIcon
                                src={playIcon}
                                style={{ width: "23px", height: "23px", marginRight: "5px" }}
                                onClick={() => handlePlaying(true)}
                            />
                        )}
                        <IonIcon
                            onClick={handleShare}
                            src={shareIcon}
                            style={{ width: "25px", height: "20px", marginRight: "5px" }}
                        />
                    </div>
                )}
            </div>

            {!inView && (
                <IonText color={"light"}>
                    <h6 style={{ margin: "5px 0" }}>
                        <span key={song_id}>
                            <a href={`https://open.spotify.com/album/${album_id}`}
                                style={{ color: "inherit", textDecoration: "inherit" }}
                                target="_blank" rel="noopener noreferrer">
                                {albumname}
                            </a>
                        </span>
                    </h6>
                </IonText>
            )}
        </div>
    );
};

export default Song;
