import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRippleEffect,
    IonText,
  } from "@ionic/react";
  import React, { useContext } from "react";
  import { Share } from "@capacitor/share";
  import { addLikedSongApi } from "../../../services/deck_loader";
  import "../CurrentPlayingDeck/styles.css";
  import { AuthContext } from "../../../context/AuthContext";
  import { ENV } from '../../../env/env';
  import "./styles.css";

  import cross from "../../../assets/icon/cancel.svg"
  import { Clipboard } from '@capacitor/clipboard';

  export interface ShareViaCopyProps {
    deckId?: number;
    isOpen: boolean;
    onDismiss: () => void;
  }
  
  const ShareViaCopy: React.FC<ShareViaCopyProps> = ({
    isOpen,
    onDismiss,
    deckId
  }) => {

    const context = useContext(AuthContext);

    const handleCopyValue = async () => {
      await Clipboard.write({
        string: `${ENV.REACT_APP_URL}/dashboard/deck/${deckId}`
      });
      context?.incrementSharedPlaylists();
      onDismiss();
    }
    
    return (
      <IonModal isOpen={isOpen} id="share-via-copy-modal" onDidDismiss={onDismiss}>
        <IonHeader className="modal-header">
          <IonText
            color={"light"}
            className="ion-font-small"
          >
            Share
          </IonText>
          <IonButton
            className="ion-activatable"
            onClick={onDismiss}
          >
            <IonIcon icon={cross} style={{color:"white"}}/>
            <IonRippleEffect></IonRippleEffect>
          </IonButton>
          
        </IonHeader>
        <div className="modal-content">
          
            
          <div className="copy-container">
              <IonLabel className="copy-url" position="stacked">Copy URL :</IonLabel>
              <IonInput className="url-input" color={"medium"} type="text" value={`${ENV.REACT_APP_URL}/dashboard/deck/${deckId}`}></IonInput>
          </div>
          
          <IonButton style={{display: "flex",padding:"0",fontSize:"0.9em", color:"#7f5ae5"}} onClick={handleCopyValue}>
            Copy
          </IonButton>
        </div>
      </IonModal>
    );
  };
  
  export default ShareViaCopy;
  