import {
  IonThumbnail,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import {
  chevronForwardOutline,
  arrowBackOutline,
  chevronDownOutline,
} from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SpotifyContext } from "../../context/SpotifyContext";
import "./Settings.css";

type PlaylistWithTracks = SpotifyApi.PlaylistObjectSimplified & {
  tracks: SpotifyApi.PagingObject<SpotifyApi.PlaylistTrackObject>;
};


const SpotifyFC: React.FC = () => {
  const spotifyContext = useContext(SpotifyContext);
  const history = useHistory();
  const [userPlaylists, setUserPlaylists] = useState<PlaylistWithTracks[] | null>(null);
  const [openPlaylists, setOpenPlaylists] = useState<string[]>([]);
  const [userProfile, setUserProfile] = useState<{
    display_name?: string;
    email?: string;
    imageUrl?: string;
  }>({});

  // Function to toggle the playlist id in openPlaylists
  const togglePlaylist = (id: string) => {
    setOpenPlaylists(prevState =>
      prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id]
    );
  };
  const handleUnlinkSpotify = () => {
    // Call the SpotifyContext logout function
    spotifyContext?.logout();

    // Navigate back to the previous page
    history.goBack();
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await spotifyContext?.getUserProfile();
        const imageUrl = response?.images?.[0]?.url;
        console.log("user email: ", response?.email);
        setUserProfile({
          display_name: response?.display_name,
          email: response?.email,
          imageUrl: imageUrl,
        });

        // After getting the user profile, now we get the user playlists.
        const playlists = await spotifyContext?.getUserPlaylists();
        if (playlists?.items) {
          const playlistsWithTracksPromises = playlists.items.map(async (playlist) => {
            const response = await spotifyContext?.getPlaylistTracks(playlist.id);
            const playlistWithTracks: PlaylistWithTracks = {
              ...playlist,
              tracks: response ?? {
                href: "",
                items: [],
                total: 0,
                limit: 0,
                next: "", // changed from null
                offset: 0,
                previous: "", // changed from null
              },
            };
            return playlistWithTracks;
          });
          Promise.all(playlistsWithTracksPromises).then(setUserPlaylists);
        }
      } catch (error) {
        console.log(`Error fetching user profile: ${error}`);
      }
    };

    fetchUserProfile();
  }, [spotifyContext]);
  

    return (
        <IonPage className="app-root">
          <IonHeader>
            <IonToolbar>
              <div className='header-wrapper'>
                <div className='back-button' onClick={() => history.goBack()}>
                  <IonIcon style={{color:"white"}} icon={arrowBackOutline} />
                </div>
                <div className='spotify-settings'>Spotify Settings</div>
                <div className='back-button'>
                  <IonIcon style={{color:"black"}} icon={arrowBackOutline} />
                </div>
              </div>
            </IonToolbar>
          </IonHeader>

          <IonContent  >
            <div className="content-container">
              <div className='info-container'>
                <div className='display-name-container'>
                  <IonLabel style={{color:"#fff",fontSize:"1.2em"}} > Display Name </IonLabel>
                  <IonLabel style={{color:"#fff",fontSize:"1.1em"}} className="smaller-label">{userProfile?.display_name}</IonLabel>
                </div>
                <div className='display-name-container'>
                  <IonLabel style={{color:"#fff",fontSize:"1.2em"}} > Associated Email </IonLabel>
                  <IonLabel style={{color:"#fff",fontSize:"1.1em"}} className="smaller-label">{userProfile?.email|| "no email id"}</IonLabel>
                </div>
              </div>
              
              
              <div className='spotify-label'>
                <IonLabel style={{color:"#fff"}}>Your Spotify Playlists</IonLabel>
              </div>
                    
                    {userPlaylists?.map((playlist) => (
                      <div  key={playlist.id}>
                        <div className="playlist-header">
                          <IonThumbnail slot="start" className="thumbnail">
                            <img src={playlist.images[0]?.url} alt="Playlist cover art" />
                          </IonThumbnail>
                          <IonLabel className="song-info">
                            <h2>{playlist.name}</h2>
                          </IonLabel>
                          <IonButtons slot="end">
                            <IonButton onClick={() => togglePlaylist(playlist.id)}>
                              <IonIcon icon= {openPlaylists.includes(playlist.id) ? chevronDownOutline : chevronForwardOutline} />
                            </IonButton>
                          </IonButtons>
                        </div>

                        {openPlaylists.includes(playlist.id) && (
                          <IonList className="MyIonList">
                            {playlist.tracks.items.map((trackItem) => {
                              const track = trackItem.track;
                              if ("album" in track) {
                                // check if the item is a TrackObjectFull
                                return (
                                  <IonItem lines="none" key={track.id}>
                                    <IonThumbnail slot="start" className="thumbnail-size">
                                      <img src={track.album.images[0].url} alt="Cover art" />
                                    </IonThumbnail>
                                    <IonLabel className="song-info">
                                      <h2>{track.name}</h2>
                                      <h3>{track.artists[0].name}</h3>
                                    </IonLabel>
                                  </IonItem>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </IonList>
                        )}
                      </div>
                    ))}
                    <div className="unlink-spotify" onClick={handleUnlinkSpotify}>
                      Unlink Spotify
                    </div>
                    <div className="spacer">footer</div>
                  </div>
          </IonContent>
        </IonPage>
      );
    };
    
    export default SpotifyFC;