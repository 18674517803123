import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, useIonRouter } from '@ionic/react';
import { logoYoutube, notificationsOutline, cogSharp, musicalNotesSharp, menu } from 'ionicons/icons';
import glimpzLogo from './glmps.svg';
import './Header.css';
import { useHistory } from 'react-router';

const GlimpzHeader = () => {

    const history = useHistory();

    const handleSettings = () => {
      history.push('/about');
    };

  return (
    <IonHeader className="header-app">
      <IonToolbar className="toolbar">
        <div className="flex-container">
          <div className="left-section">
            <div className='header-logo'>
              <img src={glimpzLogo}  alt="Profile"/>
            </div>
            <div className="title">Glimpz</div>
          </div>
          <div className="right-section">
          <IonButton className="header-icon" onClick={handleSettings}>
            <IonIcon slot="icon-only" icon={menu} />
          </IonButton>
          </div>
          
        </div>
      </IonToolbar>
    </IonHeader>

  );
};

export default GlimpzHeader;
