import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonImg,
  IonIcon,
  IonButton,
  IonRippleEffect,
} from "@ionic/react";
import React from "react";
import { Link, useHistory } from "react-router-dom";

const SwipeUp: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage className="ion-container">
      <IonContent>
        <IonGrid>
          <IonRow className="ion-align-items-center ion-margin-vertical ion-margin-top-medium">
            <IonCol size="2" className="margin-left">
              <IonButton
                onClick={() => history.goBack()}
                color={"back"}
                className="ion-activatable ripple-parent"
                fill="clear"
              >
                BACK
                <IonRippleEffect></IonRippleEffect>
              </IonButton>
            </IonCol>
            <IonCol size="8" className="ion-text-center">
              <IonText className="ion-sub-heading ion-font-small">
                Gestures
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <IonCol size="12" className="ion-text-center ion-margin-top-small">
              <IonText color={"light"} className="ion-heading ion-font-medium">
                Swipe Up
              </IonText>
              <IonImg src={"assets/swipeUp.svg"} />
            </IonCol>
            <IonCol size={"12"} className="ion-text-center">
              <IonIcon src="assets/icon/album.svg" className="icon-large" />
            </IonCol>
            <IonCol size={"12"} className="ion-text-center">
              <IonText
                color={"light"}
                className="ion-heading ion-font-extra-large"
              >
                Listen More
              </IonText>
            </IonCol>
            <IonCol
              size={"12"}
              className="ion-margin-top-medium ion-padding-horizontal-medium"
            >
              <IonText color={"light"} className="ion-para">
                User swipes up to listen to the full song or a snippet with
                longer duration
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <Link to={"/swipe-right"} className="link">
          <IonButton
            color={"back"}
            className="ion-activatable ion-activatable"
            fill="clear"
          >
            Next
            <IonRippleEffect></IonRippleEffect>
          </IonButton>
        </Link>
      </IonContent>
    </IonPage>
  );
};

export default SwipeUp;
