/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "region": "us-east-1",
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://zg24xep535dj5disybynhvlfme.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rxh25oine5dgxlu6c43ix2a6ee",
    "aws_cognito_identity_pool_id": "us-east-1:2db745b3-05c6-4f16-be66-a3472179cb83",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GPfkWru2M",
    "aws_user_pools_web_client_id": "7pcjbb30bhtnavue5c878k21as",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "55c6afeefa1a46b186d664b0d39b12c2",
    "aws_mobile_analytics_app_region": "us-east-1",

    "aws_user_files_s3_bucket": "glimpz-c",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
