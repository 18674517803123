import { IonSelect, IonSelectOption,InputChangeEventDetail, IonThumbnail,IonBackButton, IonButton,IonCol, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import {  personCircleOutline, chevronForwardOutline, radioButtonOn, shareOutline,trashOutline, arrowBackOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { SpotifyContext } from '../../context/SpotifyContext';
import {Card, Deck} from './liked_storage';
import "./settings.css";
import { AuthContext } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import profileLogo from '../../assets/icon/profile.svg'




const SpotifyPlaylistSelectionPage: React.FC = () => {

    const spotifyContext = useContext(SpotifyContext);
    const [playlistName, setPlaylistName] = useState('glimpz_list');
    const history = useHistory();
    const [userPlaylists, setUserPlaylists] = useState<SpotifyApi.PlaylistObjectSimplified[] | null>(null);
    const context = useContext(AuthContext);
    const location = useLocation();
    const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(null);
    const [selectedPlaylistName, setSelectedPlaylistName] = useState<string | null>(null);
    const [newPlaylistName, setNewPlaylistName] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [decks, setDecks] = useState<Deck[]>([]);
    const [deck, setDeck] = useState<Deck | null>(null);
    const [songStatuses, setSongStatuses] = useState<Record<string, 'pending' | 'success' | 'failed'>>({});
    const [totalSongs, setTotalSongs] = useState<number>(0);
    const [successfulSongs, setSuccessfulSongs] = useState<number>(0);
    const [isAddToPlaylistTriggered, setIsAddToPlaylistTriggered] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);





    useEffect(() => {
        async function fetchData() {
          try {
            const fetchedDecks = await context?.likedStorage.getDeckFromPlaylist(context?.userId??"empty");
            const inMemDeck = context?.likedStorage.getDeckJsonFromMemory();
            let newDecks: Deck[] = []; // Declare the type of the variable explicitly
            if( fetchedDecks && inMemDeck == undefined ) {
              console.log("setting deck after fetching data - size", fetchedDecks.length);
              newDecks = fetchedDecks;
            } else if( fetchedDecks && inMemDeck ) {
              newDecks = [inMemDeck, ...fetchedDecks];
            } else if( inMemDeck ) {
              newDecks = [inMemDeck, ...decks];
            }
            setDecks(newDecks);
    
            // If the exported deck ID is in the list of decks, use that as the current deck
            const exportDeck = newDecks.find(d => d.deck_id === context?.exportDeckId);
            if (exportDeck) {
              setDeck(exportDeck);
            }
          } catch (error) {
            console.error(error);
          }
        }
        fetchData();
      }, []); // Run this when component mounts
    

    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };

    const handleCreateNewPlaylist = async () => {
        if (!newPlaylistName) return;
        try {
          const newPlaylist = await spotifyContext?.createPlaylist(newPlaylistName);
          if (newPlaylist) {
            handlePlaylistSelection(newPlaylist.id,newPlaylist.name);
            setUserPlaylists((prev) => [...(prev || []), newPlaylist]);
            context?.incrementCreatedPlaylists();
          }
        } catch (error) {
          console.log("Error creating new playlist:", error);
        }
      };
      

    const handlePlaylistSelection = (playlistId:string,playlistName:string) => {
        setSelectedPlaylistId(playlistId);
        setSelectedPlaylistName(playlistName);
    }

    const [userProfile, setUserProfile] = useState<{
      display_name?: string;
      email?: string;
      imageUrl?: string;
    }>({});

  useEffect(() => {
    const fetchPlaylists = async () => {
      const response = await spotifyContext?.getUserPlaylists();
      if (response) {
        setUserPlaylists(response.items);
      }
    };
  
    fetchPlaylists();
  }, []);
    useEffect(() => {
        return () => {
        setSongStatuses({});
        setTotalSongs(0);
        setSuccessfulSongs(0);
        setIsAddToPlaylistTriggered(false);
        }
    }, [location]);
    const handleAddToPlaylist = async () => {
        if (!selectedPlaylistId || !deck) return;
        setIsAddToPlaylistTriggered(true);
        setIsDisabled(true);

        setTotalSongs(deck.cards.length);

        const trackUris = await Promise.all(deck.cards.map(async (cardItem) => {
            try {
                const trackUri = await spotifyContext?.searchTracks(cardItem.title, cardItem.sartists);
                if (trackUri) {
                    setSongStatuses((prev) => ({
                        ...prev,
                        [cardItem.title]: 'success',
                    }));
                    setSuccessfulSongs((prev) => prev + 1);
                    context?.incrementLikedSpotifySongs();
                    return trackUri;
                } else {
                    setSongStatuses((prev) => ({
                        ...prev,
                        [cardItem.title]: 'failed',
                    }));
                    console.log(`No track found for title: ${cardItem.title} artist: ${cardItem.sartists}`);
                    return null;
                }
            } catch (error) {
                console.error(`Error searching for track title: ${cardItem.title} artist: ${cardItem.sartists}`, error);
                setSongStatuses((prev) => ({
                    ...prev,
                    [cardItem.title]: 'failed',
                }));
                return null;
            }
        }));

    // Remove null items (tracks not found on Spotify)
    const validTrackUris = trackUris.filter(uri => uri !== null) as string[];
    console.log("trackUris: ",validTrackUris);
    // add tracks to the selected playlist
    try {
        await spotifyContext?.addTracksToPlaylist(selectedPlaylistId, validTrackUris);
    } catch (error) {
        console.error(`Error adding tracks to playlist ID: ${selectedPlaylistId}`, error);
    }

    //  might want to handle errors or confirm success here
}



    return (
        <IonPage className="app-root">
            <IonHeader >
                <IonToolbar>
                <div className='header-container' >
                    <div className='header-wrapper'>
                    <div className='back-button' onClick={() => history.goBack()}>
                        <IonIcon style={{color:"white"}} icon={arrowBackOutline} />
                    </div>
                    <div className='add-to-spotify'></div>
                    </div>
                    
                </div>

                </IonToolbar>
            </IonHeader>
            
            <IonContent >
            {
                spotifyContext?.isLoggedOut
                ?
                <div className="connect-spotify-container">
                    <IonLabel style={{color:"#fff", margin:"1em", fontSize:"1.2em"}}>Please connect your Spotify account</IonLabel>
                    <div style={{ padding: "1em", color:"white", display: "flex", alignItems: "center",fontSize:"1.1em" }}>
                        <IonIcon icon={profileLogo} /> {/* Replace with the icon you want for profile */}
                        <IonIcon style={{ margin: "0 0.5em" }} icon={chevronForwardOutline} />
                        <span style={{color:"#F46E6E"}}>Edit Profile</span>
                        <IonIcon style={{ margin: "0 0.5em" }} icon={chevronForwardOutline} />
                        <span style={{color:"#1DB954"}}>Connect Spotify</span>
                    </div>
                </div>
                :
                <IonList lines="none" >
                    <div className=" add-to-playlist-container">
                        <IonLabel style={{color:"#fff", margin:"1em", fontSize:"1.2em"}}> Add to your Spotify Playlists</IonLabel>
                    <div className="spotify-playlist" onClick={toggleDropdown}>
                    
                        <div className="dropdown-select-value">{selectedPlaylistName || 'Select a Playlist'}</div>
                        {dropdownOpen && 
                            <ul>
                                {userPlaylists?.map((playlist, index) => (
                                <li 
                                    key={index} 
                                    onClick={() => handlePlaylistSelection(playlist.id,playlist.name)}
                                    className="playlist-item"
                                >
                                    {playlist.name}
                                </li>
                                ))}
                            </ul>
                            }
                        </div>
                    </div>

                    <div className='or'> - or - </div>

                    <div className='add-to-playlist-container' >
                        <div>
                            <IonLabel style={{color:"#fff", margin:"1em", fontSize:"1.2em"}}>Create New Playlist on Spotify</IonLabel>
                            <div className='create-playlist-container'>
                            
                            <IonInput
                                value={newPlaylistName}
                                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => setNewPlaylistName(e.detail.value!)}
                                onFocus={() => { setSelectedPlaylistId(null); setSelectedPlaylistName(null); }}
                                placeholder="Enter Name"
                                className="playlist-name-input" 
                                />

                            <IonButton className='create-playlist-button' style={{color:"#fff", backgroundColor:"#7F5AEF"}} onClick={handleCreateNewPlaylist}>Create</IonButton>
                            </div>
                        </div>
                    </div>
                    
                    <IonLabel style={{color:"#fff", margin:"1em", fontSize:"1.2em"}}>Songs to add</IonLabel>
                    <IonList className="MyIonList" style={{marginTop:"1em"}}  key={deck?.deck_id}>
                        {deck?.cards.map((cardItem) => (
                            <IonItem lines="none" key={cardItem.id}>
                                <IonThumbnail slot="start" className="thumbnail-size">
                                    <img src={cardItem.coverart_a} alt="Cover art" />
                                </IonThumbnail>
                                <IonLabel className="song-info">
                                    <h2>{cardItem.title}</h2>
                                    <h3>{cardItem.sartists}</h3>
                                </IonLabel>
                                <div className="liked-song-buttons">
                                <IonIcon
                                    icon={radioButtonOn}
                                    className="liked-song-icon"
                                    style={{color: songStatuses[cardItem.title] === 'success' ? '#1DB954' : songStatuses[cardItem.title] === 'failed' ? '#F46E6E' : 'white'}}
                                />
                                    <IonIcon icon={trashOutline} className="liked-song-icon" style={{color:'white'}}/>
                                </div>
                            </IonItem>
                        ))}
                    </IonList>
                    {!selectedPlaylistId ? (
                        <div className='confirm-button' style={{backgroundColor:'#7e7e7e'}}>
                            Select or create a Playlist
                        </div>
                    ):(
                        <div 
                            className='confirm-button' 
                            onClick={isDisabled ? undefined : handleAddToPlaylist}
                            style={{
                                backgroundColor: 
                                    !selectedPlaylistId ? '#7e7e7e' : 
                                    !isAddToPlaylistTriggered ? '#7F5AEF' :
                                    successfulSongs === totalSongs ? '#1DB954' : 
                                    successfulSongs < totalSongs ? 'orange' : 
                                    '#7e7e7e'
                            }}
                        >
                            {isAddToPlaylistTriggered ? (successfulSongs === totalSongs ? 'All songs added to playlist' : `${successfulSongs}/${totalSongs} songs successfully added`) : `Add ${deck?.cards.length} songs to Playlist`}
                        </div>
                    )}
                </IonList>
            }
            </IonContent>
            </IonPage>
        );
        };
    
    export default SpotifyPlaylistSelectionPage;