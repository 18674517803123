import React, { useEffect, useRef, useState, useContext } from 'react';
import { useIonToast, IonIcon } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectCards, Virtual, Navigation } from 'swiper';
import { Swiper as SwiperType } from 'swiper';
import { Howl } from 'howler';
import { AppAnalytics } from '../../utility/app-analytics';

import { heart } from 'ionicons/icons';

import NewCard from "../NewCard/NewCard";

// swiper css rules
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-cards";
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css/navigation';

// card content css rules and images
import './swiperCards.scss'
import spotifyLogo from "../../assets/spotify-icons-logos/icons/02_PNG/Spotify_Icon_RGB_White.png";
import playIcon from '../../assets/icon/playIcon.svg'
import { AuthContext } from '../../context/AuthContext';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import LikedStorage, { LikedDeck, LikedCard } from "../../pages/LikesShares/liked_storage"

SwiperCore.use([Virtual, Navigation, Pagination]);

interface ICard {
    card: {
        id: string;
        albumgenres: string;
        albumhref: string;
        albumid: string;
        albumlabel: string;
        albumname: string;
        albumpopularity: number;
        albumtype: string;
        coverart_a: string;
        coverart_b: string;
        coverart_c: string;
        explicit: boolean;
        external_urls: string;
        genres: string;
        jsonartists: string;
        min_date: string;
        popularity: number;
        sartists: string;
        totaltracks: number;
        title: string;
        spotifyid: string;
        trackurl: string;
        snippeturl: string;
        uri: string;
    }
}

const colors = ['#FBD1A2', '#FBE5C8', '#FAE7CB', '#E5F5C5', '#FFD2C5']; // list of five summer colors

type SwiperCardsProps = {
    data: any;
    pauseCallback?: () => void;
    forcePlay?: boolean | null;
  };
  
  
  const SwiperCards: React.FC<SwiperCardsProps> = ({ data, pauseCallback, forcePlay }) => {

    const [color, setColor] = useState(colors[Math.floor(Math.random() * colors.length)]); // choose a random color from the list
    const context = useContext(AuthContext);

    const [presentToast] = useIonToast();

    const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
    const [cards, setCards] = useState<ICard[]>([])
    const [currentCard, setCurrentCard] = useState<ICard>();
    const [lastTap, setLastTap] = useState<number | null>(null); //for double tap
    const [isSwiping, setIsSwiping] = useState(false); //for double tap
    const audioRef = useRef(null);
    const [showHeart, setShowHeart] = useState(false);
    const heartRef = useRef<HTMLIonIconElement>(null);

    /**
     * This is invoked to force play a song from Dashboard after user has acted on unmute prompt.
     */
    useEffect(() => {
        // Logic to control the playing state of the first card in SwiperCards
        console.log("sk: forced play in swipercard", forcePlay)
        if (forcePlay) {
          setPlaying(true);
          
        } else {
          // Code to invoke pause on the first card
        }
      }, [forcePlay]);

    const appAnalytics = new AppAnalytics;

    const handlePauseCallback = () => {

        /**If playing is true and yet the player paused, then it is
         * because system is blocking autoplay until user clicks the screen
         * Provide the same feedback to user. This feedback should be only once.
         */
        if( playing == true )
        {
            /** Push the event back to Dashboard - If the pause is imposed by system on first load, then 
             * dashboard will prompt user to click on screen.
             */
            if( pauseCallback )
                pauseCallback();

            //console.log("sk - Abnormal Pause event");
            setPlaying(false);
        }
        // else 
        // {
        //     console.log("sk - Normal Pause event occurred in audio player - no action");
        // }
      };


    const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer(audioRef, handlePauseCallback);

    async function setDeckToLikedStore(title: string, deck_id: number, version_id: string) {

        const prevLiked = context?.likedStorage?.getCards();
        const prevLikedLength = Array.isArray(prevLiked) ? prevLiked.length : 0;
        if (prevLikedLength > 0) {
            /* add deck/cards to liked s3 folder for the user */
            const updtd = context?.likedStorage?.saveToS3(context?.userId ?? "error");
            if (updtd)
                context?.handleLikesUpdated();
                context?.incrementLikedDecks();
        }
        const likedDeck: LikedDeck = {
            deckId: deck_id,
            title: title,
            version_id: version_id
        }
        context?.likedStorage?.updatelkDeck(likedDeck)
        


    };

    async function addCardToLikedStore(likedCard: LikedCard) {
        context?.likedStorage?.addCard(likedCard);
        setColor(colors[Math.floor(Math.random() * colors.length)]); // choose a new random color when the toast is shown
        presentToast({
            message: 'Track added to your Library',
            duration: 1100,
            icon: heart,
            cssClass: 'random-color-toast', // set a CSS class for the toast container
            layout: "stacked",
            position: "top", // Set the position to top
        });
    }
    


    useEffect(() => {
        let stackCards = context?.currentDeckCards;
        if (stackCards){
            stackCards = stackCards?.filter((card: ICard) => card?.card?.snippeturl !== null);
            setCards(stackCards);
            if (stackCards && stackCards[0]?.card?.snippeturl) {
                setCurrentCard(stackCards[0]);
                setPlaying(true);
            }
        }
    }, [context?.currentDeckCards]);

    useEffect(() => {
        const title = context?.title ?? "";
        const deckJson = context?.deckId ?? { 0: false };
        const deck_id = context?.deckId ?? 0;
        const version_id = context?.deck_version ?? "";

        setDeckToLikedStore(title, deck_id, version_id);
    }, [context?.deckId]);

    useEffect(() => {
        if (swiperRef) {
            swiperRef.update();
        }
    }, [swiperRef]);

    const handleSongPlayEnded = () => {
        swiperRef?.slideNext();
    }

    const handleSwipeStart = () => {
        setIsSwiping(true);
    };

    const handleSwipeEnd = () => {
        setIsSwiping(false);
    };

    /**
     * 
     * @param cardData 
     * Record analytics
     * Update Liked Card in local storage
     * Update Spotity Liked/Favorite
     */
    function handleDoubleClick(topCard: any) {
        const { id, title, spotifyid, snippeturl, coverart_a, albumid, albumname, sartists, jsonartists } = topCard;
        const likedCard = { id, title, spotifyid, snippeturl, coverart_a, albumid, albumname, sartists, jsonartists };
        addCardToLikedStore(likedCard);
        console.log('Double click event triggered for card:', likedCard);
    }

    const handleTap = () => {
        const now = Date.now();
        const diff = now - (lastTap || now);
        if (!isSwiping && lastTap && diff < 300 && diff > 100) {
            // double tap detected
            console.log('Double tap!', diff);
        } else {
            setLastTap(now);
        }
    };

    /** callback from NewCard */
    /*const onLikedCard = (likedCard: any) => {
        addCardToLikedStore(likedCard);
        appAnalytics.card_like_event(context?.deckId ?? 0, likedCard.id, Number(context?.userId) ?? 0, 0)
    };


    /**
     * Use this to synch audio player and autoplay the song in the new card
     */
    const onLikedCard = (likedCard: any) => {
        addCardToLikedStore(likedCard);
        appAnalytics.card_like_event(context?.deckId ?? 0, likedCard.id, Number(context?.userId) ?? 0, 0)
        context?.incrementLikedSongs();
    };

    const handleSlideChange = (swiper: SwiperType) => {
        const previousSlideIndex = swiperRef?.previousIndex ?? 0;
        const currentSlideIndex = swiperRef?.activeIndex ?? 0;
        const currentSlideData = cards[currentSlideIndex ? currentSlideIndex : 0];

        const card = currentSlideData.card;

        if (currentSlideIndex > previousSlideIndex) {
            //console.log(`Swiped left from slide ${previousSlideIndex} to slide ${currentSlideIndex}`);
            appAnalytics.card_swipe_left_event(context?.deckId ?? 0, Number(card.id), context?.userId ?? "0", "");
        } else if (currentSlideIndex < previousSlideIndex) {
            //console.log(`Swiped right from slide ${previousSlideIndex} to slide ${currentSlideIndex}`);
            appAnalytics.card_swipe_right_event(context?.deckId ?? 0, Number(card.id), context?.userId ?? "0", "");
        }
        else {
            console.log("Error: in swiper slide change transition - indexes are 0")
        }

        const index = swiper.activeIndex;
        const indexedCard = cards[index]
        setCurrentCard(indexedCard);
        if (indexedCard.card.snippeturl == null) {
            console.log("snipper null")
            setPlaying(false);
        } else {
            setPlaying(true);
        }
    };
    return (
        <>
            {context?.isLoading ? (
                <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards, Virtual]}
                    className="mySwiper"
                    virtual={{ addSlidesAfter: 4, addSlidesBefore: 4 }}
                    onSlideChange={(swiper: SwiperType) => { handleSlideChange(swiper) }}
                    longSwipes={false}
                >
                    <SwiperSlide
                        key={1}
                        className={'card-swiper-slide'}
                    >
                        <div className='loading-cards'>
                            Hang tight! We're getting your music ready to play.
                        </div>
                    </SwiperSlide>
                    <SwiperSlide
                        key={2}
                        className={'card-swiper-slide'}
                    >
                        Hang tight! We're getting your music ready to play.
                    </SwiperSlide>
                    <SwiperSlide
                        key={3}
                        className={'card-swiper-slide'}
                    >
                        Hang tight! We're getting your music ready to play."
                    </SwiperSlide>
                </Swiper>
            ) : (
                <>
                    <Swiper
                        onSwiper={setSwiperRef}
                        effect={"cards"}
                        grabCursor={true}
                        modules={[EffectCards, Virtual]}
                        className="mySwiper"
                        virtual={{ addSlidesAfter: 4, addSlidesBefore: 4 }}
                        onSlideChange={(swiper: SwiperType) => { handleSlideChange(swiper) }}
                        longSwipes={false}
                    // cssMode={true}
                    >
                        {cards?.map((cardData: any, index: number) => (
                            // cardData?.card?.snippeturl ? (
                            /*<SwiperSlide
                                key={index}
                                virtualIndex={index}
                                className={'card-swiper-slide'}
                                onDoubleClick={() => handleDoubleClick(cardData.card)} //for like, spotify add and analytics
                                onTouchEnd={handleTap} onTouchStart={handleTap}
                                onTouchMove={handleSwipeStart}
                                onTouchCancel={handleSwipeEnd}
                                onTouchEndCapture={handleSwipeEnd}
                            >
                                <NewCard cardData={cardData} index={index} playing={playing} setPlaying={setPlaying} onLikeCard={onLikedCard} />
                            </SwiperSlide>*/
                            
                            // ) : (<div></div>)
                            <SwiperSlide
                                key={index}
                                virtualIndex={index}
                                className={'card-swiper-slide'}
                                onTouchMove={handleSwipeStart}
                                onTouchCancel={handleSwipeEnd}
                                onTouchEndCapture={handleSwipeEnd}
                            >
                                <NewCard cardData={cardData} 
                                         index={index} 
                                         playing={playing} 
                                         setPlaying={setPlaying} 
                                         onLikeCard={onLikedCard} 
                                />
                            </SwiperSlide>
                        ))}

                    </Swiper>
                    <audio
                        onEnded={handleSongPlayEnded}
                        id="audio"
                        src={currentCard?.card.snippeturl}
                        ref={audioRef}
                        autoPlay
                    >
                        Your device/browser does not support the <code>audio</code> element.
                    </audio>

                    <style>{`
                        .random-color-toast {
                            --background: linear-gradient(200deg, purple, #7f5aef);
                        }
                        `}      
                    </style>

                </>
            )}

        </>

    );
}

export default SwiperCards;

