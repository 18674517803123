import React, { useEffect, useRef, useState, useContext } from 'react';
import { IonContent, IonHeader, IonPage, IonImg, IonButton } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectCards, Virtual, Navigation } from 'swiper';
import { Swiper as SwiperType } from 'swiper';
import { Howl } from 'howler';

import spotifyLogo from "../../assets/spotify-icons-logos/icons/02_PNG/Spotify_Icon_RGB_White.png";
import playIcon from '../../assets/icon/playIcon.svg'
import pauseIcon from '../../assets/icon/pause.svg'
import heartIcon from '../../assets/icon/heart.svg'
import blueheartIcon from '../../assets/icon/blueHeart.svg'
import './NewCard.scss'

import {Card} from '../../pages/LikesShares/liked_storage'
import { SpotifyContext } from '../../context/SpotifyContext';

const NewCard: React.FC<{ cardData: any, index: any, playing: boolean, setPlaying: (bool:boolean) => void, onLikeCard: (card:any) => void  }> = ({ cardData, index, playing, setPlaying, onLikeCard }) => {

    const [clicked, setClicked] = useState(false);
    const spContext = useContext(SpotifyContext)

    /*const handleLikedClick = () => {
        
        setClicked(!clicked);

        if(clicked === true) {
            const { id, title, snippeturl, coverart_a, albumid, albumname, sartists } = cardData.card;
            const likedCard:Card = { id, title, snippeturl, coverart_a, albumid, albumname, sartists };
            onLikeCard(likedCard);
        }
        console.log('spotifyID', cardData?.card?.spotifyid)
        spContext?.addToSavedTracks(cardData?.card?.spotifyid);
        // Perform additional actions here
        
    };*/
    const handleLikedClick = () => {
        setClicked(!clicked);

        // Moved the condition check here to like the card only when clicked is false (i.e., going to be true)
        if (!clicked) {
            const { id, title, snippeturl, coverart_a, albumid, albumname, sartists } = cardData.card;
            const likedCard:Card = { id, title, snippeturl, coverart_a, albumid, albumname, sartists };
            onLikeCard(likedCard);
        }
        spContext?.addToSavedTracks(cardData?.card?.spotifyid);
    };

    return (
        <>
            <div className="logos">
            <IonImg
                src={spotifyLogo}
                alt="SpotifyLogo"
                draggable={false}
                className="spotify-logo"
            />

            
            <IonImg
                src={clicked ? blueheartIcon : heartIcon}
                alt="heartIcon"
                draggable={false}
                className="heart-icon"
                // style={imageStyle}
                onClick={handleLikedClick}
                
            />
            </div>
            

            <IonImg
                src={cardData?.card.coverart_a}
                alt={cardData?.card.title}
                draggable={false}
                className="cover-art"
            />

            {/* track title */}

            {/* TODO: logic to check whether title is longer.
                Enable this in later iteration for title, album, artist
                <div className='marquee-wrapper'> 
            */} 
                <span key={cardData?.card?.spotifyid} className="card-title">
                    <a href={`https://open.spotify.com/track/${cardData?.card?.spotifyid}`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                        target="_blank" rel="noopener noreferrer">
                        {cardData?.card.title}
                    </a>
                </span>
            {/* </div> */}

            {/* artists */}
            <div className="card-album">
                {JSON.parse(cardData?.card?.jsonartists).map((artist: any, index: number) => (
                    <span key={artist.artistID}>
                        <a href={`https://open.spotify.com/artist/${artist.artistID}`}
                            style={{ color: "inherit", textDecoration: "inherit" }}
                            target="_blank" rel="noopener noreferrer">
                            {artist.artistName}
                        </a>
                        {index < JSON.parse(cardData?.card?.jsonartists).length - 1 ? ", " : ""}
                    </span>
                ))}
            </div>

            {/* album */}
            {/* <span key={cardData?.card.albumid} className="card-artist">
                <a href={`https://open.spotify.com/album/${cardData?.card.albumid}`}
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    target="_blank" rel="noopener noreferrer">
                    {cardData?.card.albumname}
                </a>
            </span> */}
            {playing == true ? <IonImg
                className="icon-container"
                src={pauseIcon}
                alt="pause"
                onClick={() => setPlaying(false)}
            /> : <IonImg
                className="icon-container"
                src={playIcon}
                alt="play"
                onClick={() => setPlaying(true)}
            />}
        </>
    )
}

export default NewCard;